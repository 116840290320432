import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, Observable, of, Subject, switchMap, Subscription } from 'rxjs';
import { buffer, catchError, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsOffer } from '@fcom/core-api';
import { GlobalBookingTravelClass, LocationRouteCffService } from '@fcom/core';
import {
  ElementActions,
  ElementTypes,
  GaContext,
  PageMetaService,
  PromotionItem,
  PromotionType,
  RecommendationService,
  TripType,
} from '@fcom/common';
import { TrendingDestinationLocation } from '@fcom/common/interfaces/recommendation.interface';
import { GtmService } from '@fcom/common/gtm';
import { finShare } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';
import { unsubscribe } from '@fcom/core/utils';
import { AmDestinationService } from '@fcom/destination-search/services/am-destination.service';
import { getOfferWithAmDestination } from '@fcom/common/utils';

import { BlockOffer, BlockOfferData, MarketingOffer, MarketingOfferPrices } from '../../interfaces';
import { CheapestPriceForAllDestinationsService, toPromoBlock } from '../../services';

const TRENDING_BADGE_LABEL = 'popularNow';

@Component({
  selector: 'fin-trending-destinations-grid',
  template: `
    <fin-offer-grid
      [title]="content.teaserTitle"
      [offers$]="offers$"
      [cmsTags]="content.subjectTaxonomyTags || []"
      [tripType]="tripType"
      (destinationClicked)="destinationClick($event)"
      (scrolledPastDestination)="scrollEvents$.next($event)"
      (flightHotelClicked)="openAmContinueModal($event)"
      [fetchImagePriority]="'low'"
    ></fin-offer-grid>
    <fin-am-destination-continue-modal
      [open]="isAmContinueModalOpen"
      (closeModal)="closeAmContinueModal()"
      [bookUrl]="flightHotelUrl"
    ></fin-am-destination-continue-modal>
  `,
})
export class TrendingDestinationsComponent implements OnInit, OnDestroy {
  @Input()
  content: CmsCollection;

  offers$: Observable<BlockOfferData[]>;
  scrollEvents$: Subject<[BlockOffer, number]> = new Subject<[BlockOffer, number]>();
  flightHotelUrl: string;
  tripType: TripType;

  isAmContinueModalOpen = false;

  readonly scrollDebounceTime = 1000;
  readonly popularTrendLimit = 2;
  readonly maxNumberOfOffers = 8;
  readonly minNumberOfOffers = 4;

  private subscriptions = new Subscription();

  constructor(
    private recommendationService: RecommendationService,
    private pageMetaService: PageMetaService,
    private gtmService: GtmService,
    private languageService: LanguageService,
    private cheapestPriceForAllDestinationsService: CheapestPriceForAllDestinationsService,
    private amDestinationService: AmDestinationService,
    private locationRouteCffService: LocationRouteCffService
  ) {}

  ngOnInit(): void {
    this.tripType = this.getTripType();
    this.offers$ = this.pageMetaService.defaultOriginLocationCode$.pipe(
      filter(Boolean),
      switchMap((origin) =>
        combineLatest([
          this.recommendationService.getTrendingDestinations(origin).pipe(catchError(() => of([]))),
          this.cheapestPriceForAllDestinationsService.bothWayOffers(),
        ]).pipe(
          // filter out current pageMeta location if found and convert first n items to BlockOfferData[]
          map(([locations, offers]: [TrendingDestinationLocation[], MarketingOffer[]]) => {
            const filteredDestination = locations
              .filter((item: TrendingDestinationLocation) => item.locationCode !== origin)
              .map((location: TrendingDestinationLocation) =>
                this.trendingToOffer(location, ...this.getBadgeLabelAndIcon(location))
              )
              .filter((item: BlockOfferData) => this.flightPriceFilter(item, offers, this.tripType));
            return filteredDestination.slice(0, this.getAmountOfOffers(filteredDestination));
          }),
          // fallback to CMS content if the recommendation api fails to deliver
          switchMap((locations: BlockOfferData[]) =>
            locations?.length > 0
              ? of(locations)
              : this.languageService.lang.pipe(
                  map((lang) => this.content.items.map((offer) => toPromoBlock(offer as CmsOffer, lang))),
                  map((offers) => offers.slice(0, this.getAmountOfOffers(offers)))
                )
          ),
          switchMap((offers) =>
            this.languageService.localeValue.includes('FI')
              ? getOfferWithAmDestination(
                  offers,
                  this.amDestinationService.amDestinationList(),
                  this.locationRouteCffService
                )
              : of(offers)
          ),
          distinctUntilChanged(),
          finShare()
        )
      )
    );

    const debounceScroll$ = this.scrollEvents$.pipe(debounceTime(this.scrollDebounceTime));

    this.subscriptions.add(
      this.scrollEvents$.pipe(buffer(debounceScroll$)).subscribe((events) => {
        const items = events.map(([offer, index]: [BlockOffer, number]) => this.toPromotionItem(offer, index));
        this.gtmService.internalPromotion(items, PromotionType.VIEW);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  destinationClick([destinationItem, index]: [BlockOffer, number]): void {
    this.gtmService.internalPromotion([this.toPromotionItem(destinationItem, index)], PromotionType.CLICK);
  }

  openAmContinueModal({ amLink, destination }: { amLink: string; destination: string }): void {
    this.gtmService.trackElement(
      `tile-${destination.toLowerCase().replace(/ /g, '_')}-flight-hotel`,
      GaContext.CONTENT,
      ElementTypes.LIST_ITEM,
      undefined,
      ElementActions.CLICK
    );
    this.isAmContinueModalOpen = true;
    this.flightHotelUrl = amLink;
  }

  closeAmContinueModal(): void {
    this.isAmContinueModalOpen = false;
  }

  getTripType(): TripType {
    const tripType = TripType[this.content.localSettings?.tripType?.toUpperCase()] ?? TripType.RETURN;
    return this.languageService.localeValue.includes('FI') ? TripType.ONEWAY : tripType;
  }

  // filter out the destination where oneway price is more expensive than return
  private flightPriceFilter(location: BlockOfferData, offers: MarketingOffer[], tripType: TripType): BlockOfferData {
    const priceOffer = this.getPriceOffer(offers, location);
    //skip checking if the destination doesn't have price available

    if (!priceOffer?.find((i) => i.price.tripType === tripType)?.price?.amount) {
      return null;
    }
    // do not check price if trending destination type is return
    if (tripType === TripType.RETURN) {
      return location;
    }

    if (
      Number(priceOffer?.find((i) => i.price.tripType === TripType.ONEWAY)?.price.amount) <
      Number(priceOffer?.find((i) => i.price.tripType === TripType.RETURN)?.price.amount)
    ) {
      return location;
    }
  }

  private getPriceOffer(offers: MarketingOffer[], location: BlockOfferData): MarketingOfferPrices[] {
    return offers
      .find((offer) => offer.destination === location.destination)
      ?.prices.filter((priceOffer) => priceOffer.travelClass === GlobalBookingTravelClass.ECONOMY);
  }

  private getAmountOfOffers(destination: BlockOfferData[]): number {
    const amountOfOfferWithPrice = destination.length;
    // If amount offer with price < 3, then no offer will be shown
    if (amountOfOfferWithPrice < this.minNumberOfOffers) {
      return 0;
    }
    // If amount offer with price < 8 and >=4, then 4 offers will be returned
    if (amountOfOfferWithPrice < this.maxNumberOfOffers) {
      return this.minNumberOfOffers;
    }
    // others return 9 offers
    return this.maxNumberOfOffers;
  }

  private getBadgeLabelAndIcon(location: TrendingDestinationLocation): [string, SvgLibraryIcon] {
    return !location.isIncludedInCampaign && location.trend >= this.popularTrendLimit
      ? [TRENDING_BADGE_LABEL, SvgLibraryIcon.TRENDING_ARROW_UP]
      : [null, null];
  }

  private trendingToOffer(
    location: TrendingDestinationLocation,
    badgeLabel: string,
    badgeIcon: SvgLibraryIcon
  ): BlockOfferData {
    return {
      title: location.cityName || location.title,
      price: null,
      subtitle: null,
      callToAction: null,
      link: location.destinationUrl,
      flag: location.countryCode,
      imageData: location.picture,
      destination: location.locationCityCode ?? location.locationCode, //destination in marketing offer is locationCityCode if that is available (e.g. REK)
      badgeLabel: location.badgeLabel ?? badgeLabel,
      badgeIcon,
      enableBlackTeaser: location.enableBlackTeaser,
    } as BlockOfferData;
  }

  private toPromotionItem(offer: BlockOfferData, index: number): PromotionItem {
    return {
      position: `${index}`,
      id: 'trending-destinations',
      product: offer.destination,
      type: 'dynamic',
    };
  }
}
