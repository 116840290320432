import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Observable } from 'rxjs';

import { LoaderType, MenuItem, NavigationMenuService } from '@fcom/common';
import { CmsContentType, CmsFormType, CmsTemplate, CmsViewType, PageViewType } from '@fcom/core-api';

@Component({
  selector: 'cms-editorial-main-page',
  templateUrl: './cms-editorial-main-page.component.html',
  styleUrls: ['./cms-editorial-main-page.component.scss'],
  animations: [
    trigger('fadeIn', [state('void', style({ opacity: 0 })), transition(':enter', [animate('0.5s ease-in')])]),
  ],
})
export class CmsEditorialMainPageComponent {
  @Input()
  template$: Observable<CmsTemplate>;

  @Input()
  hasContent = false;

  activeNaviSection$: Observable<MenuItem>;

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;
  public readonly PAGETYPE: typeof PageViewType = PageViewType;

  constructor(private navigationMenuService: NavigationMenuService) {
    this.activeNaviSection$ = this.navigationMenuService.activeNaviSection;
  }

  protected readonly LoaderType = LoaderType;
}
