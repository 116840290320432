import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { Observable, of } from 'rxjs';

import { AspectRatios, FetchPriorityEnum, IMAGE_WIDTHS } from '@fcom/ui-components';
import { CmsImageData } from '@fcom/core-api';

@Component({
  selector: 'fin-content-image',
  templateUrl: './content-image.component.html',
  styleUrls: ['./content-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentImageComponent {
  @Input()
  imageData$: Observable<CmsImageData>;

  /**
   * The preferred aspect ratio of the image. Defaults to 16x9
   */
  @Input()
  aspectRatio$: Observable<keyof typeof AspectRatios> = of('16x9');

  /**
   * Stretches image to fill the container.
   */
  @Input()
  fillParent = false;

  @Input()
  maxImageWidth = IMAGE_WIDTHS[0];

  @Input()
  scale = true;

  @Input()
  fetchPriority?: keyof typeof FetchPriorityEnum;

  @Input()
  isLazyLoad = true;
}
