import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  isDevMode,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { filter, Observable, of, Subscription } from 'rxjs';

import { SentryLogger, unsubscribe } from '@fcom/core/index';

import { ButtonMode, ButtonSize, ButtonTheme, ButtonType } from './enums';
import { IconPosition } from '../../icons';
import { ButtonIcon } from './interfaces';
import { LoaderTheme } from '../../loader';
import { AriaOptions } from '../../interfaces';

@Component({
  selector: 'fcom-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss', '../buttons-shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges, AfterViewInit, OnDestroy {
  @HostBinding('class.fill-mode') fillMode = false;
  @HostBinding('class.fill-mode-mobile') fillModeMobile = false;

  /**
   * The size of the button, currently 2 static sizes
   */
  @Input() size: ButtonSize = ButtonSize.MEDIUM;

  /**
   * There are 8 themes available right now that renders different backgrounds, font colors and borders
   */
  @Input() theme: ButtonTheme = ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE;

  /**
   * The pre-translated text to show
   */
  @Input({ required: true }) text: string;

  /**
   * The aria tags that must be added to a button or link if it has a loading or disabled state
   */
  @Input() aria?: AriaOptions = {};

  /**
   * An icon to include, with its category, name and position
   */
  @Input() icon?: ButtonIcon;

  /**
   * Whether the button should be disabled or not
   */
  @Input() disabled = false;

  /**
   * Whether the button should appear as disabled but be enabled
   */
  @Input() appearDisabled = false;

  /**
   * Disables the button and either replaces the current icon with the loading spinner. Or will add an icon to the right of text.
   */
  @Input() loading = false;

  /**
   * The type of button, for example "button" or "submit"
   */
  @Input() type: ButtonType = ButtonType.BUTTON;

  /**
   * The pre-translated hover text to display
   */
  @Input() title: string;

  /**
   * If button should render as an a tag, then pass in the url
   */
  @Input() href?: string;

  /**
   * If link, should it open in a new tab
   */
  @Input() openInNewTab?: boolean;

  /**
   * If link, is it external
   */
  @Input() externalLink?: boolean;

  /**
   * Do we fill the whole container or work as inline-block
   */
  @Input() mode: ButtonMode = ButtonMode.INLINE;

  /**
   * Can the button be focused by tab key
   */
  @Input() removeFromNavigation = false;

  /**
   * Focus to button after some action for accessibility
   */
  @Input() focus$: Observable<boolean> = of(false);

  @Input() id?: string = null;

  @ViewChild('buttonElement') buttonElement: ElementRef;

  /**
   * Event emitter for blur event
   */
  @Output() blurBtn = new EventEmitter<void>();

  @Output() clickBtn = new EventEmitter<Event>();

  public readonly IconPosition = IconPosition;
  public readonly ButtonSize = ButtonSize;
  public readonly ButtonTheme = ButtonTheme;
  public readonly ButtonMode = ButtonMode;
  public readonly LoaderTheme = LoaderTheme;

  ariaLabel: string;

  constructor(@Optional() private logger?: SentryLogger) {}

  private subscriptions: Subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges): void {
    const mode = 'mode';

    if (changes[mode]) {
      this.fillMode = changes[mode].currentValue === ButtonMode.FILL;
      this.fillModeMobile = changes[mode].currentValue === ButtonMode.FILL_MOBILE;
    }

    if (this.loading) {
      if (isDevMode() && !this.aria.loadingLabel) {
        this.logger?.warn('Missing aria label for loading button');
      }

      this.ariaLabel = this.aria.loadingLabel;
    } else {
      this.ariaLabel = this.aria.label;
    }
  }

  onClick(event: Event): void {
    if (this.disabled || this.loading || this.aria?.disabled) {
      event.preventDefault();
      return;
    }

    this.clickBtn.emit(event);
  }

  focus(): void {
    this.buttonElement?.nativeElement?.focus();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.focus$.pipe(filter(Boolean)).subscribe(() => this.focus()));
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  get ariaRole(): string | undefined {
    return (!this.href && this.aria?.role !== 'button') || Boolean(this.href) ? this.aria?.role : undefined;
  }
}
