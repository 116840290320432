import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  AfterViewInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import {
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  take,
  filter,
  withLatestFrom,
  observeOn,
  asyncScheduler,
  skip,
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import { GlobalBookingFlight, LocationPair, getViewedNotifications, NotificationActions } from '@fcom/common/store';
import {
  GlobalBookingTravelClass,
  unsubscribe,
  isEmptyObjectOrHasEmptyValues,
  WindowRef,
  isPresent,
  AppState,
  RootPaths,
  AppPreloadingStrategyService,
} from '@fcom/core';
import { ButtonTheme, ButtonSize, ButtonMode, NotificationTheme } from '@fcom/ui-components';
import { TripType, GlobalBookingTripDates, HotjarCaptureEvent, HotjarService } from '@fcom/common';
import { Amount, PaxAmount } from '@fcom/dapi';
import { LoginStatus, Profile } from '@fcom/core-api/login';
import { AirCalendarList } from '@fcom/dapi/api/models';

import {
  DatePickerPrices,
  PaxUpdateEvent,
  WidgetTab,
  WidgetLayout,
  ExpanderStatus,
  WidgetTheme,
  SelectionType,
  LocationParam,
  LocationType,
} from '../../interfaces';
import { BookingWidgetService } from '../../services/booking-widget.service';
import { BookingWidgetFlightService } from '../../services/booking-widget-flight.service';
import { BookingWidgetTripService } from '../../services/booking-widget-trip.service';

@Component({
  selector: 'fin-booking-widget-common',
  templateUrl: './booking-widget-common.component.html',
  styleUrls: ['./booking-widget-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingWidgetCommonComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly TripType = TripType;
  readonly WidgetTab = WidgetTab;
  readonly LoginStatus = LoginStatus;
  readonly WidgetLayout = WidgetLayout;
  readonly WidgetTheme = WidgetTheme;
  readonly SelectionType = SelectionType;
  readonly LocationType = LocationType;
  readonly NotificationTheme = NotificationTheme;
  readonly NEW_MULTI_CITY_NOTIFICATION_ID = 87731561;

  @Input()
  compactMode = false;

  @Input()
  defaultLocations$: Observable<LocationPair[]> = of([]);

  @Input()
  layout: WidgetLayout = WidgetLayout.DEFAULT;

  @Input()
  isGlobalBookingWidget = false;

  @Input()
  usePopoverSelectors = false;

  @Input()
  identifier: string;

  @Input()
  isFrontPage = false;

  @Input()
  enablePromoCode = true;

  @Output()
  changeGlobalBookingWidgetExpanderStatus = new EventEmitter<ExpanderStatus>();

  @ViewChild('footer', { static: false, read: ElementRef })
  footer: ElementRef<HTMLElement>;

  tripType$: Observable<TripType>;
  travelClass$: Observable<GlobalBookingTravelClass>;
  availableTravelClasses$: Observable<GlobalBookingTravelClass[]>;
  paxAmount$: Observable<PaxAmount>;
  locations$: Observable<LocationPair>;
  flights$: Observable<GlobalBookingFlight[]>;
  travelDates$: Observable<GlobalBookingTripDates>;
  startingPrice$: Observable<Amount> = of(null);
  continueEnabled$: Observable<boolean>;
  prices$: Observable<DatePickerPrices>;
  airCalendarPrices$: Observable<AirCalendarList>;
  discountCode$: Observable<string>;
  profile$: Observable<Profile>;
  loginStatus$: Observable<LoginStatus>;
  loading$: Subject<boolean> = new Subject<boolean>();
  isNewSearchEnabled$ = of(false);
  showCompact$ = of(true);
  subscription = new Subscription();
  globalBookingWidgetSelectionChanges$ = of({});
  showDiscountCode$: Observable<boolean>;
  enableMultiCity$: Observable<boolean>;
  bookingFlowType$: Observable<WidgetTab> = of(WidgetTab.FLIGHT);
  shouldFocusWidgetContent$: Subject<void> = new Subject<void>();
  isNewMultiCityNotificationVisible$: Observable<boolean>;

  constructor(
    private bookingWidgetService: BookingWidgetService,
    private bookingWidgetFlightService: BookingWidgetFlightService,
    private bookingWidgetTripService: BookingWidgetTripService,
    private windowRef: WindowRef,
    private store$: Store<AppState>,
    private hotjarService: HotjarService,
    private preloadStrategyService: AppPreloadingStrategyService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.enableMultiCity$ = this.bookingWidgetService.isFullMultiCity$();
    this.bookingWidgetService.setUsePopoverSelectors(this.usePopoverSelectors && this.isGlobalBookingWidget);

    // Selections
    this.paxAmount$ = this.bookingWidgetTripService.paxAmount$;
    this.tripType$ = this.bookingWidgetTripService.selectedTripType$;
    this.travelDates$ = this.bookingWidgetService.travelDates$;
    this.travelClass$ = this.bookingWidgetTripService.selectedTravelClass$;
    this.locations$ = this.bookingWidgetFlightService.locations$;
    this.flights$ = this.bookingWidgetFlightService.flights$;
    this.discountCode$ = this.bookingWidgetService.discountCode$;
    this.showCompact$ = this.bookingWidgetService.showCompact$.pipe(
      map((showCompact) => showCompact && this.compactMode)
    );
    // Utility
    this.profile$ = this.bookingWidgetService.profile$;
    this.availableTravelClasses$ = this.bookingWidgetTripService.availableTravelClasses$;
    this.loginStatus$ = this.bookingWidgetService.loginStatus$;

    this.continueEnabled$ = this.bookingWidgetService.continueEnabled$;
    this.bookingFlowType$ = this.bookingWidgetTripService.activeTab$;

    // Prices
    this.airCalendarPrices$ = this.bookingWidgetService.airCalendarPrices$;
    this.prices$ = this.bookingWidgetService.prices$;
    this.startingPrice$ = this.bookingWidgetService.startingPrice$;
    //set default location
    this.bookingWidgetFlightService.setDefaultLocations(this.defaultLocations$);

    //reset the change status of booking widget
    this.bookingWidgetService.resetLocalOriginalBookingSelection();

    this.showDiscountCode$ = combineLatest([this.bookingFlowType$, this.tripType$, this.enableMultiCity$]).pipe(
      map(([bookingFlowType, tripType, enableMultiCity]) => {
        if (!this.enablePromoCode) {
          return false;
        }

        if (bookingFlowType !== WidgetTab.FLIGHT) {
          return false;
        }

        if (!enableMultiCity) {
          return true;
        }

        return enableMultiCity && tripType !== TripType.MULTICITY;
      })
    );

    if (this.isGlobalBookingWidget) {
      this.globalBookingWidgetSelectionChanges$ = this.bookingWidgetService.globalBookingWidgetSelectionChanges$;
      this.isNewSearchEnabled$ = combineLatest([this.continueEnabled$, this.globalBookingWidgetSelectionChanges$]).pipe(
        map(([continueEnabled, isValueChanged]) => continueEnabled && !isEmptyObjectOrHasEmptyValues(isValueChanged))
      );
      this.bookingWidgetService.setLocalOriginalBookingSelection();
      this.bookingWidgetService.setPreviousSearchChange();
      //changed status of booking widget

      this.subscription.add(
        this.bookingWidgetService.notificationWarning$
          .pipe(
            map((notification) =>
              Object.keys(notification)
                .filter((notificationType) => notificationType !== 'seasonalRoute')
                .reduce((filteredNotification, key) => {
                  filteredNotification[key] = notification[key];
                  return filteredNotification;
                }, {})
            ),
            filter((notification) => !isEmptyObjectOrHasEmptyValues(notification))
          )
          .subscribe(() => {
            this.changeGlobalBookingWidgetExpanderStatus.emit(ExpanderStatus.OPEN);
          })
      );
    }

    this.isNewMultiCityNotificationVisible$ = this.store$.pipe(
      select(getViewedNotifications),
      map((viewedNotifications) => {
        return !viewedNotifications.includes(this.NEW_MULTI_CITY_NOTIFICATION_ID);
      })
    );

    this.subscription.add(
      // on client interaction with the widget (2nd emission of booking selections), trigger preloading booking module
      this.bookingWidgetService.bookingSelections$
        .pipe(skip(1), take(1))
        .subscribe(() => this.preloadStrategyService.triggerPreload(RootPaths.BOOKING_ROOT))
    );
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform) && !this.isGlobalBookingWidget) {
      this.subscription.add(
        this.shouldFocusWidgetContent$
          .pipe(
            withLatestFrom(this.showCompact$),
            filter(([, showCompact]) => !showCompact),
            observeOn(asyncScheduler),
            filter(() => this.footerIsNotInView())
          )
          .subscribe(() => {
            this.footer.nativeElement.scrollIntoView({
              block: 'end',
              inline: 'nearest',
              behavior: 'smooth',
            });
          })
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
    if (this.isGlobalBookingWidget) {
      this.changeGlobalBookingWidgetExpanderStatus.emit(ExpanderStatus.CLOSE);
    }
  }

  setTripType(tripType: TripType): void {
    if (this.isGlobalBookingWidget) {
      this.bookingWidgetService.setSelectionChangeType(SelectionType.TRIP_TYPE);
      this.bookingWidgetService.setSelectionChangeType(SelectionType.TRAVEL_DATES);
    }
    this.bookingWidgetTripService.setTripType(tripType);

    if (tripType !== TripType.MULTICITY) {
      return;
    }

    this.subscription.add(
      this.enableMultiCity$.pipe(take(1)).subscribe((enableMultiCity) => {
        if (!enableMultiCity) {
          return;
        }

        this.hotjarService.startCapture(HotjarCaptureEvent.MULTI_CITY_SOFT_LAUNCH);
      })
    );
  }

  setPaxAmount({ paxType, amount, updateType }: PaxUpdateEvent): void {
    if (this.isGlobalBookingWidget) {
      this.bookingWidgetService.setSelectionChangeType(SelectionType.PAX);
    }
    this.bookingWidgetTripService.setPaxAmount({ paxType, amount, updateType });
  }

  setLocations({ locations, locationType }: LocationParam, index = 0): void {
    if (this.isGlobalBookingWidget) {
      locationType.forEach((type) => {
        this.bookingWidgetService.setSelectionChangeType(type as unknown as SelectionType);
      });
    }
    this.bookingWidgetFlightService.setLocations(locations, index);
    this.shouldFocusWidgetContent$.next();
  }

  setTravelDates(dates: GlobalBookingTripDates, isAirCalendar = false): void {
    this.bookingWidgetService.setTravelDates(dates, 0, isAirCalendar, this.isGlobalBookingWidget);
  }

  setFlexibleDates(isFlexibleDates: boolean): void {
    this.bookingWidgetService.setFlexibleDates(isFlexibleDates);
  }

  onAddReturnClick(): void {
    if (this.isGlobalBookingWidget) {
      this.bookingWidgetService.setSelectionChangeType(SelectionType.TRIP_TYPE);
    }
    this.bookingWidgetTripService.setReturnTripType();
  }

  startNewSearch(): void {
    this.bookingWidgetService.startNewSearch();
    this.changeGlobalBookingWidgetExpanderStatus.emit(ExpanderStatus.CLOSE);
  }

  navigateToBookingFlow(fromMatrix = false, airCalendarPrice: string | undefined = undefined): void {
    this.subscription.add(
      combineLatest([this.bookingWidgetService.isMultiCity$, this.flights$, this.tripType$])
        .pipe(take(1))
        .subscribe(([isMultiCity, flights, tripType]) => {
          if (isMultiCity) {
            this.bookingWidgetService.navigateToMultiCityBookingFlow(this.loading$);
            return;
          }
          //check if there are only two flights and the 1st origin as same as 2nd destination, then set triptype to return
          if (
            tripType !== TripType.RETURN &&
            flights.length === 2 &&
            flights[0].origin.locationCode === flights[1].destination.locationCode &&
            flights[0].destination.locationCode === flights[1].origin.locationCode
          ) {
            this.bookingWidgetTripService.setTripType(TripType.RETURN);
            this.bookingWidgetService.setTravelDates(
              { departureDate: flights[0].departureDate, returnDate: flights[1].departureDate },
              0
            );
          }
          this.bookingWidgetService.navigateToBookingFlow(fromMatrix, airCalendarPrice, this.loading$);
        })
    );
  }

  startSearch(): void {
    this.isGlobalBookingWidget ? this.startNewSearch() : this.navigateToBookingFlow();
  }

  private footerIsNotInView(): boolean {
    if (!isPresent(this.footer?.nativeElement)) {
      return false;
    }

    const footerRect = this.footer.nativeElement.getBoundingClientRect();
    const screenBottom = this.windowRef.nativeWindow.innerHeight;
    return footerRect.y + footerRect.height > screenBottom;
  }

  hideNewMultiCityNotification(): void {
    this.store$.dispatch(
      NotificationActions.setViewedNotification({
        id: this.NEW_MULTI_CITY_NOTIFICATION_ID,
      })
    );
  }
}
