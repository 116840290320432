import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { isPresent, LocalDate, unsubscribe } from '@fcom/core/utils';
import { ButtonMode, ButtonSize, ButtonTheme, DateRange, LoaderTheme } from '@fcom/ui-components';
import { Amount } from '@fcom/dapi';
import { finShare } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';
import { LocationRouteCffService } from '@fcom/core';
import { Location } from '@fcom/core-api/interfaces';
import { FlightSegment, TripType } from '@fcom/common/interfaces';

import {
  CalendarPrices,
  DatePickerPrices,
  GlobalBookingTripDates,
  HistogramBar,
  PriceCalendarCTAParams,
  PriceCalendarParams,
} from '../../interfaces';
import { getStartingFromPrice, priceCalendarParamsAreValid } from '../../utils';
import { PriceCalendarService } from '../../services';
import { GlobalBookingFlight } from '../../store';
import { GtmService } from '../../gtm';

@Component({
  selector: 'fin-price-calendar',
  templateUrl: 'price-calendar.component.html',
  styleUrls: ['./price-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceCalendarComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly TripType = TripType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly LoaderTheme = LoaderTheme;
  readonly CMS_STYLE_OVERRIDE = {
    'margin-top': '0px',
  };

  @Input()
  showAddReturn = false;

  @Input()
  identifier: string;

  @Input()
  openWithParams$: Observable<PriceCalendarParams | null>;

  @Input()
  showSubtitle = false;

  @Output()
  modalClose = new EventEmitter<boolean>();

  @Output()
  ctaClicked = new EventEmitter<PriceCalendarCTAParams>();

  subscription = new Subscription();

  calendarRange: DateRange = [LocalDate.now(), LocalDate.now().plusDays(360)];
  pricePerAdult$: Observable<Amount> = of(undefined);
  datesSelected$: Observable<boolean>;
  datePickerTitleLabel$: Observable<string>;
  selectedHistogramMonth$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  scrollToHistogramMonth$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  subTitle$: Observable<{ origin: string; destination: string }>;
  fullYearPrices$: BehaviorSubject<DatePickerPrices> = new BehaviorSubject({
    calendar: undefined,
    histogram: undefined,
  });
  followingMonthPrices$: BehaviorSubject<CalendarPrices> = new BehaviorSubject({});
  calendarPrices$: Observable<DatePickerPrices>;
  params$: BehaviorSubject<PriceCalendarParams> = new BehaviorSubject(undefined);

  travelDates$ = new BehaviorSubject<GlobalBookingTripDates>({ departureDate: undefined, returnDate: undefined });

  ctaDisabled$: Observable<boolean> = of(false);

  isLoading$: Observable<boolean> = of(true);

  @ViewChild('calendarHeader')
  calendarHeader: TemplateRef<unknown>;

  constructor(
    private priceCalendarService: PriceCalendarService,
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.openWithParams$
        .pipe(filter((params) => isPresent(params) && priceCalendarParamsAreValid(params)))
        .subscribe((params) => {
          this.followingMonthPrices$.next(undefined);
          this.fullYearPrices$.next({ calendar: undefined, histogram: undefined });
          this.travelDates$.next({ departureDate: undefined, returnDate: undefined });
          this.selectedHistogramMonth$.next(undefined);

          this.params$.next(params);
        })
    );

    const validatedParams$ = this.params$.pipe(
      filter((params) => isPresent(params) && priceCalendarParamsAreValid(params)),
      finShare()
    );

    if (this.showSubtitle) {
      this.subTitle$ = validatedParams$.pipe(
        switchMap(({ origin, destination }) => {
          return forkJoin({
            origin: this.locationRouteCffService.bestGuessFor(origin, this.languageService.localeValue),
            destination: this.locationRouteCffService.bestGuessFor(destination, this.languageService.localeValue),
          });
        }),
        map(({ origin, destination }) => {
          const originCity = origin ? origin.cityName : '';
          const destinationCity = destination ? destination.cityName : '';
          return { origin: originCity, destination: destinationCity };
        })
      );
    }

    this.subscription.add(
      validatedParams$
        .pipe(
          switchMap((params) => this.priceCalendarService.getPricesForFullYear(params)),
          map(({ fullYear, histogram }) => ({ calendar: fullYear, histogram })),
          finShare()
        )
        .subscribe((prices) => this.fullYearPrices$.next(prices))
    );

    this.subscription.add(
      combineLatest([validatedParams$, this.travelDates$])
        .pipe(
          filter(([, travelDates]) => isPresent(travelDates.departureDate?.id)),
          distinctUntilChanged(([prevParams, prevTravelDates], [nextParams, nextTravelDates]) => {
            const departureDateNotChanged = nextTravelDates.departureDate?.id === prevTravelDates.departureDate?.id;
            const locationsNotChanged =
              nextParams.origin === prevParams.origin && nextParams.destination === prevParams.destination;
            return departureDateNotChanged && locationsNotChanged;
          }),
          switchMap(([params, travelDates]) =>
            this.priceCalendarService.getPricesForFollowingMonth(params, travelDates.departureDate)
          ),
          finShare()
        )
        .subscribe((prices) => this.followingMonthPrices$.next(prices))
    );

    this.calendarPrices$ = combineLatest([
      validatedParams$,
      this.fullYearPrices$,
      this.followingMonthPrices$,
      this.travelDates$,
    ]).pipe(
      map(([{ tripType }, fullYearPrices, followingMonth, { departureDate, returnDate }]) => {
        const oneway = tripType === TripType.ONEWAY;
        const onlyDepartureSelectedAndNotOneway = isPresent(departureDate) && !oneway && !isPresent(returnDate);
        const prices = onlyDepartureSelectedAndNotOneway ? followingMonth : fullYearPrices?.calendar;

        const pricesWithSelections =
          departureDate && returnDate ? { ...prices, [returnDate?.id]: followingMonth?.[returnDate?.id] } : prices;
        return { calendar: pricesWithSelections, histogram: fullYearPrices?.histogram };
      }),
      finShare()
    );

    this.pricePerAdult$ = combineLatest([this.calendarPrices$, validatedParams$, this.travelDates$]).pipe(
      map(([prices, { tripType }, travelDates]) => getStartingFromPrice(prices, tripType, travelDates))
    );

    this.datesSelected$ = combineLatest([validatedParams$, this.travelDates$]).pipe(
      map(
        ([params, { departureDate, returnDate }]) =>
          isPresent(departureDate) && (params.tripType === TripType.ONEWAY || isPresent(returnDate))
      )
    );

    this.subscription.add(
      this.datesSelected$.pipe(filter((datesSelected) => datesSelected)).subscribe(() => {
        const { tripType, origin, destination, paxAmount, travelClass } = this.params$.getValue();
        const { returnDate, departureDate } = this.travelDates$.getValue();
        const departureFlight: FlightSegment = {
          origin,
          destination,
          departureDate,
        };

        const returnFlight: FlightSegment = {
          origin: destination,
          destination: origin,
          departureDate: returnDate,
        };

        const flights: FlightSegment[] =
          tripType === TripType.RETURN ? [departureFlight, returnFlight] : [departureFlight];

        this.gtmService.preFlightSearch({
          flights,
          paxAmount,
          travelClass,
          isAward: false,
        });
      })
    );

    this.datePickerTitleLabel$ = combineLatest([validatedParams$, this.travelDates$]).pipe(
      map(([{ tripType }, { departureDate }]) => {
        if (tripType === TripType.ONEWAY) {
          return 'bookingWidget.priceCalendar.selectDeparture.oneWay';
        }
        return isPresent(departureDate)
          ? 'bookingWidget.priceCalendar.selectReturn'
          : 'bookingWidget.priceCalendar.selectDeparture.roundTrip';
      })
    );

    this.ctaDisabled$ = combineLatest([validatedParams$, this.travelDates$]).pipe(
      map(([{ tripType }, travelDates]) => {
        if (tripType === TripType.RETURN) {
          return !isPresent(travelDates.departureDate) || !isPresent(travelDates.returnDate);
        }
        return !isPresent(travelDates.departureDate);
      })
    );

    this.isLoading$ = this.fullYearPrices$.pipe(
      map(({ calendar, histogram }) => !isPresent(calendar) && !isPresent(histogram)),
      finShare()
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  closeModal(): void {
    this.modalClose.emit(false);
  }

  addReturn(): void {
    this.fullYearPrices$.next({ calendar: undefined, histogram: undefined });

    const currentParams = this.params$.getValue();
    this.params$.next({
      ...currentParams,
      tripType: TripType.RETURN,
    });
  }

  updateCalendarDates(calendarDates: [string] | [string, string]): void {
    const [nextDep, nextRet] = calendarDates;
    const prevDep = this.travelDates$.getValue().departureDate?.id;
    const prevRet = this.travelDates$.getValue().returnDate?.id;

    if (!(prevDep === nextDep && prevRet === nextRet)) {
      const nextDates = {
        departureDate: nextDep ? new LocalDate(nextDep) : undefined,
        returnDate: nextRet ? new LocalDate(nextRet) : undefined,
      };
      this.travelDates$.next(nextDates);
    }
  }

  setHistogramMonth(selectedMonthIndex = 0): void {
    this.selectedHistogramMonth$.next(selectedMonthIndex);
  }

  handleHistogramClick(selectedBar: HistogramBar): void {
    this.selectedHistogramMonth$.next(selectedBar.index);
    this.scrollToHistogramMonth$.next(selectedBar.index);
  }

  emitCTAClicked(): void {
    const { tripType, origin, destination, paxAmount, travelClass } = this.params$.getValue();
    const { departureDate, returnDate } = this.travelDates$.getValue();
    const departureFlight: GlobalBookingFlight = {
      origin: { locationCode: origin } as Location,
      destination: { locationCode: destination } as Location,
      departureDate,
    };
    const returnFlight: GlobalBookingFlight = {
      origin: { locationCode: destination } as Location,
      destination: { locationCode: origin } as Location,
      departureDate: returnDate,
    };

    const flights: GlobalBookingFlight[] =
      tripType === TripType.RETURN ? [departureFlight, returnFlight] : [departureFlight];
    this.ctaClicked.emit({
      flights,
      tripType,
      paxAmount,
      travelClass,
    });
  }
}
