import { FinnairServiceItemWithUpsell, ServiceStatus } from '@fcom/dapi/interfaces';
import {
  Category,
  FinnairBoundItem,
  FinnairItineraryItemFlight,
  FinnairPassengerCode,
  FinnairPassengerItem,
  FinnairPassengerServiceSelectionItem,
} from '@fcom/dapi/api/models';

import { getServiceStatus, isFlight, isNotIncludedService } from './common-booking.utils';

const isInfant = (passenger: FinnairPassengerItem) => passenger.passengerTypeCode === FinnairPassengerCode.INF;

export enum ServiceType {
  INCLUDED = 'included',
  NON_INCLUDED = 'noneIncluded',
  PAID = 'paid',
}

export interface ServiceSummaryTranslationKey {
  quantity?: number;
  description: string;
  text?: string;
  type?: ServiceType;
}

export interface FlightWithSeats {
  flight: string;
  seats: string[];
}

export const getSeatsByFlight = (
  passengers: FinnairPassengerItem[],
  currentBound: FinnairBoundItem,
  serviceItem: FinnairServiceItemWithUpsell
): FlightWithSeats[] => {
  return currentBound.itinerary
    .filter(isFlight)
    .map((flight) => {
      const seats = passengers
        .filter((passenger) => !isInfant(passenger))
        .map((passenger) => {
          return (
            serviceItem.bounds
              .filter((bound) => bound.id === currentBound.id)
              .flatMap((bound) => bound.segments)
              .filter((serviceSegment) => serviceSegment.id === flight.id)
              .flatMap((serviceSegment) => serviceSegment.passengers)
              .filter((servicePax) => servicePax.id === passenger.id)
              .flatMap((servicePax) => servicePax.services)
              .flatMap((servicePaxService) => servicePaxService.seatNumber)
              .filter((seatNumber) => seatNumber !== null && seatNumber.length > 0)?.[0] ?? '--'
          );
        });
      return {
        flight: `${flight.departure.locationCode}-${flight.arrival.locationCode}`,
        seats,
      };
    })
    .filter((seatInfo) => seatInfo.seats.length > 0);
};

const EXCLUDE_FROM_SUMMARY_VARIANTS = ['SH_ECO_MEAL_DEFAULT_1', 'SH_ECO_MEAL_DEFAULT_2', 'SH_ECO_DRINK_DEFAULT_1'];

export const getSummary = (
  currentBound: FinnairBoundItem,
  service: FinnairServiceItemWithUpsell
): ServiceSummaryTranslationKey => {
  if (service.category === Category.OTHER) {
    return { description: 'MMB.services.other' };
  }
  const { anyIncluded, totalQuantity } = calculateIsIncludedAndQuantity(service, currentBound);

  const serviceCategory = resolveCategory(service.category);

  if (totalQuantity > 0) {
    return { quantity: totalQuantity, description: `MMB.services.${serviceCategory}.paid`, type: ServiceType.PAID };
  } else if (anyIncluded) {
    if ([Category.SPORT, Category.LOUNGE, Category.WIFI].includes(service.category) && totalQuantity === 0) {
      return { quantity: 1, description: `MMB.services.${serviceCategory}.included`, type: ServiceType.INCLUDED };
    }

    return { description: `MMB.services.${serviceCategory}.included`, type: ServiceType.INCLUDED };
  }
  return { description: `MMB.services.${serviceCategory}.noneIncluded`, type: ServiceType.NON_INCLUDED };
};

const getMealSummary = (
  currentBound: FinnairBoundItem,
  service: FinnairServiceItemWithUpsell,
  isShortHaulFlight: boolean
): ServiceSummaryTranslationKey => {
  const { anyIncluded, totalQuantity } = calculateIsIncludedAndQuantity(service, currentBound);

  const serviceCategory = resolveCategory(service.category);

  if (totalQuantity > 0) {
    return { quantity: totalQuantity, description: `MMB.services.${serviceCategory}.paid`, type: ServiceType.PAID };
  }

  if (anyIncluded && !isShortHaulFlight) {
    return { description: `MMB.services.${serviceCategory}.included`, type: ServiceType.INCLUDED };
  }
  return { description: `MMB.services.${serviceCategory}.noneIncluded`, type: ServiceType.NON_INCLUDED };
};

function calculateIsIncludedAndQuantity(
  service: FinnairServiceItemWithUpsell,
  currentBound: FinnairBoundItem
): { anyIncluded: boolean; totalQuantity: number } {
  const allSelectedItems = service.bounds
    .filter((bound) => bound.id === currentBound.id)
    .flatMap((bound) => bound.segments)
    .flatMap((flight) => flight.passengers)
    .flatMap((pax) => pax.services)
    .filter(({ variant }) => !EXCLUDE_FROM_SUMMARY_VARIANTS.includes(variant));

  const totalQuantity = allSelectedItems
    .filter((paxService) => isNotIncludedService(paxService))
    .flatMap((paxService) => paxService.quantity)
    .reduce((total, count) => {
      total += count;
      return total;
    }, 0);

  const anyIncluded = allSelectedItems.some(
    (paxService) => paxService.includedInTicketType || paxService.includedInTierBenefit
  );

  return { anyIncluded, totalQuantity };
}

export const getSummaryForCategory = (
  category: Category,
  bound: FinnairBoundItem,
  service: FinnairServiceItemWithUpsell,
  isShortHaulFlight: boolean
): ServiceSummaryTranslationKey[] => {
  switch (category) {
    case Category.CABIN_BAGGAGE:
      return getCabinBaggageSummary(bound, service);
    case Category.BAGGAGE:
      return getCheckedBaggageSummary(bound, service);
    case Category.MEAL:
      return [getMealSummary(bound, service, isShortHaulFlight)];
    case Category.WIFI:
    case Category.LOUNGE:
    case Category.SPORT:
    case Category.PET:
    case Category.PRIORITY:
    case Category.SPECIAL_NEED:
    case Category.COVER:
    case Category.SAF:
    case Category.OTHER:
      return [getSummary(bound, service)];
    default:
      return [];
  }
};

const EXCLUDE_FROM_SUMMARY_CABIN_BAGGAGE_VARIANTS = [
  'CABIN_WEIGHT',
  'CABIN_WEIGHT_COMBINED_OLD',
  'CABIN_WEIGHT_COMBINED',
];

const getCabinBaggageSummary = (
  currentBound: FinnairBoundItem,
  service: FinnairServiceItemWithUpsell
): ServiceSummaryTranslationKey[] => {
  if (
    currentBound.itinerary
      .filter(isFlight)
      .some((flight: FinnairItineraryItemFlight) => flight.operatingAirline.code !== 'AY')
  ) {
    return [{ description: 'MMB.services.baggage.summary-title' }];
  }

  const segments = service.bounds.find((b) => b.id === currentBound.id)?.segments.length ?? 1;
  return service.bounds
    .filter((bound) => bound.id === currentBound.id)
    .flatMap((bound) => bound.segments)
    .flatMap((flight) => flight.passengers)
    .flatMap((pax) => pax.services)
    .filter(
      (s) =>
        !EXCLUDE_FROM_SUMMARY_CABIN_BAGGAGE_VARIANTS.includes(s.variant) &&
        getServiceStatus(s) === ServiceStatus.CONFIRMED
    )
    .reduce((all, s) => {
      const current = all.find((oldService) => oldService.variant === s.variant);

      if (current) {
        current.quantity = current.quantity + (s.quantity || 0);
      } else {
        all.push({ ...s });
      }

      return all;
    }, [])
    .map((s) => {
      const quantity = Math.ceil(s.quantity / segments);

      if (quantity === 0) {
        return { description: `MMB.services.cabinBaggage.${s.variant.toLowerCase()}` };
      }

      return {
        quantity,
        description: `MMB.services.cabinBaggage.amount.${s.variant.toLowerCase()}`,
      };
    });
};

const getPaxServiceDisplayName = (paxService: FinnairPassengerServiceSelectionItem): string => {
  return paxService.parameters?.baggageWeight > 0
    ? paxService.displayName.replace('{weight}', '' + paxService.parameters.baggageWeight)
    : paxService.displayName;
};

const normalizeDisplayNames = (service: FinnairPassengerServiceSelectionItem): FinnairPassengerServiceSelectionItem => {
  if (!service.includedInTierBenefit) {
    return {
      ...service,
      displayName: getPaxServiceDisplayName(service),
    };
  }

  const startIndex = service.displayName.indexOf('x') <= 2 ? 3 : 0;
  const endIndex = service.displayName.indexOf(',', startIndex);

  const trimmedDisplayName = service.displayName
    .slice(startIndex, endIndex !== -1 ? endIndex : service.displayName.length)
    .trim();

  return {
    ...service,
    displayName: trimmedDisplayName.charAt(0).toUpperCase() + trimmedDisplayName.slice(1),
  };
};

const getCheckedBaggageSummary = (
  currentBound: FinnairBoundItem,
  service: FinnairServiceItemWithUpsell
): ServiceSummaryTranslationKey[] => {
  if (
    currentBound.itinerary
      .filter(isFlight)
      .some((flight: FinnairItineraryItemFlight) => flight.operatingAirline.code !== 'AY')
  ) {
    return [{ description: 'MMB.services.baggage.summary-title' }];
  }

  const segments = service.bounds.find((b) => b.id === currentBound.id)?.segments.length ?? 1;
  const services = service.bounds
    .filter((bound) => bound.id === currentBound.id)
    .flatMap((bound) => bound.segments)
    .flatMap((flight) => flight.passengers)
    .flatMap((pax) => pax.services)
    .filter((s) => getServiceStatus(s) === ServiceStatus.CONFIRMED)
    .map(normalizeDisplayNames)
    .reduce((all, s) => {
      const current = all.find((oldService) => oldService.displayName === s.displayName);

      if (current) {
        current.quantity = current.quantity + (s.quantity || 0);
      } else {
        all.push({ ...s });
      }

      return all;
    }, [])
    .map((s) => ({
      quantity: Math.ceil(s.quantity / segments),
      description: '',
      text: s.displayName,
    }));

  if (services.length === 0) {
    return [{ description: 'MMB.services.baggage.noneIncluded', type: ServiceType.NON_INCLUDED }];
  }

  return services;
};

export const resolveCategory = (category: Category): string => {
  switch (category) {
    case Category.MEAL:
      return 'meals';
    case Category.BAGGAGE:
      return 'bags';
    default:
      return category;
  }
};
