<div class="pr-xsmall-b" data-testid="offer-grid">
  <ng-container *ngIf="title">
    <ng-container [ngSwitch]="headerLevel">
      <h1 *ngSwitchCase="1" class="pr-xsmall-y">{{ title }}</h1>
      <h2 *ngSwitchCase="2" class="pr-xsmall-y">{{ title }}</h2>
      <h3 *ngSwitchCase="3" class="pr-xsmall-y">{{ title }}</h3>
      <h4 *ngSwitchCase="4" class="pr-xsmall-y">{{ title }}</h4>
      <h3 *ngSwitchDefault class="pr-xsmall-y">{{ title }}</h3>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="selectedOriginLocationName$ | async as selectedOriginLocationName">
    <p
      *ngIf="tripType"
      class="flex--self-center ms-medium-y font-body-1 regular-type"
      [innerHTML]="'destinationSearch.flightsFrom' | finLocalization: { origin: selectedOriginLocationName }"
      data-testid="offer-grid-trip-type-origin-label"
    ></p>
  </ng-container>
  <div class="offer-grid-offers fill grid-col-12 no-gutters">
    <fin-block-offer
      *ngFor="let offer of offers$ | async; let i = index"
      [tripType]="tripType"
      [offer]="offer"
      (finScrolledPast)="scrolledPastDestination.emit([offer, i])"
      (destinationClicked)="destinationClick($event, i)"
      (flightHotelClicked)="flightHotelClicked.emit($event)"
      [fetchImagePriority]="fetchImagePriority"
      class="offer-grid-offer fill block flex slim"
    ></fin-block-offer>
  </div>
</div>
