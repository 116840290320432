import { Category } from '@fcom/dapi/api/models';

export const BOUND_BASED_CATEGORIES = [
  Category.BAGGAGE,
  Category.SPORT,
  Category.PET,
  Category.CABIN_BAGGAGE,
  Category.SAF,
];
export const isBoundBasedCategory = (category: Category): boolean => BOUND_BASED_CATEGORIES.includes(category);
export const isJourneyBasedCategory = (category: Category): boolean => [Category.COVER].includes(category);
