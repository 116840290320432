import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AppState, NativeBridgeService } from '@fcom/core';
import { loginStatus, profileName } from '@fcom/core/selectors';
import { LoginStatus } from '@fcom/core-api/login';
import { finShare } from '@fcom/rx';
import { ButtonTheme, IconPosition, ButtonMode, ButtonSize } from '@fcom/ui-components';

import { ElementTypes } from '../../interfaces';
import { MediaQueryService } from '../../services';

const SHOW_BUTTON_STATES = [
  LoginStatus.LOGGED_IN,
  LoginStatus.NOT_LOGGED_IN,
  LoginStatus.ERROR,
  LoginStatus.PENDING,
  LoginStatus.INITIAL,
];

@Component({
  selector: 'fin-login-button',
  templateUrl: 'login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginButtonComponent implements OnInit {
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly ElementTypes = ElementTypes;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly LoginStatus = LoginStatus;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  showButton$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  profileName$: Observable<string>;
  loginStatus$: Observable<LoginStatus>;

  @Input()
  showName = false;

  @Input()
  nameStyle = 'font-body-1';

  @Input()
  trackingContext: string;

  constructor(
    private store$: Store<AppState>,
    private nativeBridgeService: NativeBridgeService,
    private mediaQueryService: MediaQueryService
  ) {}

  ngOnInit(): void {
    this.profileName$ = this.store$.pipe(profileName());
    this.loginStatus$ = this.store$.pipe(loginStatus(), finShare());
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    this.showButton$ = this.nativeBridgeService.isInsideNativeWebView
      ? of(false)
      : this.loginStatus$.pipe(
          map((s) => SHOW_BUTTON_STATES.indexOf(s) !== -1),
          finShare()
        );
  }
}
