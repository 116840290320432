import { CartPassengersRequest, LocationInfoMap, PaxDetailsFormData } from '@fcom/dapi/interfaces';
import { isTruthy } from '@fcom/core/utils';
import {
  Category,
  FinnairAirBoundsResponse,
  FinnairBoundGroup,
  FinnairBoundItem,
  FinnairItineraryItemFlight,
  FinnairPhoneType,
  FinnairTravelEndpoint,
  SubCategory,
} from '@fcom/dapi/api/models';
import { FinnairBoundItemWithLocation, FinnairTravelLocation } from '@fcom/common-booking';
import { formatDateOfBirth } from '@fcom/common-booking/utils/date-utils';
import { isFlight } from '@fcom/common-booking/utils/common-booking.utils';
import { PaxDetailsState } from '@fcom/common/interfaces/booking';
import { FinnairAirBounds, FinnairBoundGroupWithLocation } from '@fcom/common/interfaces/booking/offers.interface';

export const mapTravelersAndCorporateCode = (paxDetailsState: PaxDetailsState): CartPassengersRequest => {
  const getAccompanyingTravelerId = (tid: string) => {
    if (!tid) {
      return null;
    }
    const traveler = paxDetailsState.passengers.find((t) => t.id === tid);
    return traveler ? traveler.travelerId : null;
  };

  return {
    passengers: paxDetailsState.passengers.map((passenger) => ({
      id: passenger.travelerId,
      passengerTypeCode: passenger.type,
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      title: passenger.gender,
      dateOfBirth: formatDateOfBirth(passenger.birthDate),
      email: passenger.email as any,
      phoneNumbers: isTruthy(passenger.phone?.phoneNumber)
        ? [
            {
              countryPrefix: passenger.phone.phonePrefix.split('|')[1],
              countryCode: passenger.phone.phonePrefix.split('|')[0],
              number: passenger.phone.phoneNumber,
              type: FinnairPhoneType.MOBILE,
            },
          ]
        : [],
      frequentFlyerCards: isTruthy(passenger.frequentFlyerCard?.cardNumber && passenger.frequentFlyerCard?.companyCode)
        ? [
            {
              companyCode: passenger.frequentFlyerCard.companyCode,
              cardNumber: passenger.frequentFlyerCard.cardNumber,
            },
          ]
        : [],
      accompanyingTravelerId: getAccompanyingTravelerId(passenger.travelling),
    })),
    joinFinnairPlus: paxDetailsState.passengers.reduce(
      (joinFinnairPlus: boolean, formData: PaxDetailsFormData) => joinFinnairPlus || !!formData.joinFinnairPlus,
      false
    ),
    corporateCode: paxDetailsState.corporateCode,
    additionalEmails: paxDetailsState.additionalEmails,
    corporateUserInformation: {
      clientId: paxDetailsState.corporateCode,
    },
  };
};

export const getUpdateId = (category: Category | SubCategory, fragmentId: string): string => {
  return `${fragmentId}-${category}`;
};

const asLegEndpointWithCityNameAndAirport = (
  legEndpoint: FinnairTravelEndpoint,
  locations: LocationInfoMap
): FinnairTravelLocation => {
  return {
    ...legEndpoint,
    cityName: locations[legEndpoint.locationCode].cityName,
    name: locations[legEndpoint.locationCode].name,
    countryCode: locations[legEndpoint.locationCode].countryCode,
  };
};

const asBoundInfoWithCityNameAndAirport = (
  boundInfo: FinnairBoundItem,
  locations: LocationInfoMap
): FinnairBoundItemWithLocation => ({
  ...boundInfo,
  departure: asLegEndpointWithCityNameAndAirport(boundInfo.departure, locations),
  arrival: asLegEndpointWithCityNameAndAirport(boundInfo.arrival, locations),
});

export const mapCityNameAndAirportToBounds = (bounds: FinnairAirBoundsResponse): FinnairAirBounds => {
  const { boundGroups, locations } = bounds;
  const enhancedBoundGroups: FinnairBoundGroupWithLocation[] = boundGroups.map((bound) => ({
    ...bound,
    details: asBoundInfoWithCityNameAndAirport(bound.details, locations),
  }));

  return { ...bounds, boundGroups: enhancedBoundGroups };
};

const getBoundGroupFromBoundsByFlightNumber = (
  bounds: FinnairAirBoundsResponse,
  flightNumberQueryParam: string
): FinnairBoundGroup | undefined => {
  const flightNumbers = flightNumberQueryParam?.split(',');
  if (!flightNumbers) {
    return undefined;
  }

  return bounds.boundGroups.find((boundGroup) => {
    const foundFlightNumbers = boundGroup.details.itinerary
      .filter(isFlight)
      .map((flight: FinnairItineraryItemFlight) => flight.flightNumber);

    return (
      flightNumbers.length === foundFlightNumbers.length &&
      foundFlightNumbers.every((item) => flightNumbers.includes(item))
    );
  });
};

const getFareFamilyFromBoundGroup = (boundGroup: FinnairBoundGroup, fareFamily: string | undefined): string => {
  const { fareFamilies, cheapestPrice } = boundGroup;
  return (
    fareFamilies.find((item) => item.fareFamilyCode === fareFamily)?.id ??
    fareFamilies.find((item) => item.price === cheapestPrice).id
  );
};

export const mapAirBoundIdToBound = (
  bounds: FinnairAirBoundsResponse,
  flightNumber: string,
  fareFamily?: string | undefined
): string | undefined => {
  const boundGroup = getBoundGroupFromBoundsByFlightNumber(bounds, flightNumber);
  return boundGroup ? getFareFamilyFromBoundGroup(boundGroup, fareFamily) : undefined;
};

export const NON_SCHENGEN_LOUNGES = ['LOUC', 'LOUN'];
export const SCHENGEN_LOUNGES = ['LOUS'];
