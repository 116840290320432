import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectiveModule } from '@fcom/common/directives';

import { IconsModule } from '../icons';
import { QuickLinksComponent } from './quick-links.component';

@NgModule({
  declarations: [QuickLinksComponent],
  imports: [CommonModule, IconsModule, DirectiveModule],
  exports: [QuickLinksComponent],
})
export class QuickLinksModule {}
