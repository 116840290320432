import {
  Bound,
  Category,
  FinnairAmount,
  FinnairBoundItem,
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  FinnairOrder,
  FinnairPassengerCode,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairPrice,
  FinnairServiceItem,
  FinnairTotalPricesSplit,
  PaymentTransaction,
  SeatType,
  SubCategory,
  FareInformation,
  FinnairItineraryItemType,
} from '@fcom/dapi/api/models';
import { BoundType, PaxAmount } from '@fcom/dapi/interfaces';
import { SEAT_TYPE_EXIT, SeatTypeCabinClass } from '@fcom/common/interfaces/seat-map.interface';
import { ExtendedServiceSelectionItem } from '@fcom/common-booking/modules/ancillaries/interfaces';
import { CartOrOrder, SmpProduct } from '@fcom/common-booking';
import { BoundFareFamily, FareFamilyMap, OffersPerBound } from '@fcom/common/interfaces/booking';
import { CombinedDateString, diff, TimeUnit, TzDate, unique } from '@fcom/core/utils';
import { isBoundBasedCategory } from '@fcom/common-booking/modules/ancillaries/utils/category.utils';
import { isAdult } from '@fcom/dapi/utils';
import { Profile, ProfileTier } from '@fcom/core-api/login';
import { snapshot } from '@fcom/rx';
import { isFlight, isNotIncludedService } from '@fcom/common-booking/utils/common-booking.utils';
import { getLastPaidServices } from '@fcom/common-booking/utils/order.utils';

import {
  FINNAIR,
  GA4Affiliation,
  GA4Category,
  GA4Product,
  GA4TripType,
  GtmCustomDimensions,
  GtmCustomMetrics,
  GtmEcommerceImpressionProduct,
  GtmEcommerceList,
  GtmEcommerceOrderData,
  GtmEcommerceProduct,
  GtmEcommerceProductSeatId,
  GtmFareFamilyProductCustomDefinitions,
  GtmFlightInformation,
  GtmFlightInformationData,
  GtmFlightProductCustomDefinitions,
  GtmFlightSelectionData,
  GtmHaulType,
  GtmMulticityOfferSelectionData,
  GtmPurchaseFlow,
  GtmTripType,
  GtmTripTypeLegacy,
  NOT_SET,
  UNSET,
} from '../interfaces/gtm.interface';
import { FlightSegment } from '../interfaces';

export const toGtmString = (str: string): string => str.replace(/\s/g, '-').toLowerCase();

export const getGtmFlightNumber = (itinerary: FinnairItineraryItem[]): string => {
  return itinerary
    .filter(isFlight)
    .map((f: FinnairItineraryItemFlight) => f.flightNumber)
    .join('-');
};

export const getGtmAircraft = (itinerary: FinnairItineraryItem[]): string => {
  const airCraft = itinerary
    .filter(isFlight)
    .map((f: FinnairItineraryItemFlight) => f.aircraft?.name)
    .join('-');

  return toGtmString(airCraft);
};

const getGtmTravelClass = (fareInformation: FareInformation[]): string => {
  const fareInformationString = fareInformation
    .map((f: FareInformation) => `${f.cabinClass}-${f.bookingClass}`)
    .join('-');

  return toGtmString(fareInformationString);
};

const getGtmRoute = ({ departure, arrival }: FinnairBoundItem | Bound): string =>
  `${departure.locationCode}-${arrival.locationCode}`;

const getAllFlights = (cartData: CartOrOrder): FinnairItineraryItemFlight[] =>
  cartData.bounds.reduce((all, bound) => all.concat(bound.itinerary.filter(isFlight)), []);

/**
 * Get comma separated string of flight ids belonging to flight list
 * @param flightIds
 * @param flights
 */
export const getGtmFlightNumbersFromFlightIds = (flightIds: string[], flights: FinnairItineraryItemFlight[]): string =>
  flightIds
    .map((id) => flights.find((f) => f.id === id)?.flightNumber)
    .filter(Boolean)
    .join(',');

/**
 * Get dash separated route from flightsIds found from dapi dictionary
 * @param flightIds
 * @param flights
 */
export const getGtmRouteFromFlightIds = (flightIds: string[], flights: FinnairItineraryItemFlight[]): string => {
  const locations = flightIds.reduce(
    (locs, id) => {
      const flight = flights.find((f) => f.id === id);
      if (!locs.departure) {
        locs.departure = flight?.departure?.locationCode;
      }
      if (locs.departure !== flight?.arrival?.locationCode) {
        locs.arrival = flight?.arrival?.locationCode;
      }
      return locs;
    },
    { departure: undefined, arrival: undefined }
  );

  return locations.departure && locations.arrival ? `${locations.departure}-${locations.arrival}` : undefined;
};

const getGtmPaxAmount = (paxAmount: PaxAmount): string => {
  const totalAmount = Object.entries(paxAmount)
    .map((entry: [keyof PaxAmount, number]) => entry[1])
    .reduce((cur, acc) => cur + acc, 0);
  return String(totalAmount);
};

export const getGtmFlightSelectionDataForFareFamily = (
  bound: FinnairBoundItem | Bound,
  boundType: BoundType,
  itinerary: FinnairItineraryItem[] = [],
  fareFamilyName: string | undefined,
  price: string,
  points: string | undefined,
  fareInformation: FareInformation[] = [],
  currencyCode: string,
  paxAmount: PaxAmount,
  purchaseFlow: GtmPurchaseFlow
): GtmFlightSelectionData => ({
  aircraft: getGtmAircraft(itinerary),
  flightNumber: getGtmFlightNumber(itinerary),
  fareFamilyName: fareFamilyName ?? 'unknown',
  price,
  points: points ?? NOT_SET,
  currencyCode,
  travelClass: getGtmTravelClass(fareInformation),
  boundType,
  route: getGtmRoute(bound),
  paxAmount: getGtmPaxAmount(paxAmount),
  purchaseFlow,
});

export const getGtmMulticityOffertSelectionData = (
  offer: OffersPerBound,
  boundNumber: number,
  currencyCode: string,
  paxAmount: PaxAmount
): GtmMulticityOfferSelectionData => ({
  aircraft: getGtmAircraft(offer.boundInfo.itinerary.filter(isFlight)),
  flightNumber: getGtmFlightNumber(offer.boundInfo.itinerary.filter(isFlight)),
  fareFamilyName: offer.fareFamily.brandName,
  price: offer.fareFamily.totalPrice,
  points: offer.fareFamily.points ?? NOT_SET,
  currencyCode,
  travelClass: offer.boundInfo.cabinClass,
  boundType: BoundType.multi,
  route: getGtmRoute(offer.boundInfo.bound),
  paxAmount: getGtmPaxAmount(paxAmount),
  purchaseFlow: GtmPurchaseFlow.BOOKING,
  boundNumber,
  stage: 'boundSelection',
});

export const getGtmServiceProduct = (
  category: Category,
  currentCartData: CartOrOrder,
  service: ExtendedServiceSelectionItem,
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceProduct => ({
  name: category === Category.BAGGAGE ? 'BAG' : service.variant,
  id: resolveGtmServiceId(category, currentCartData, service),
  price: service.unitPrice.amount,
  quantity: service.quantity,
  brand: FINNAIR, // Useless, could be used for something else in the future
  category: resolveGtmCategory(category)[1],
  [GtmCustomDimensions.PNR]: currentCartData.id,
  [GtmCustomDimensions.FLOW_TYPE]: purchaseFlow,
});

export const getGtmServiceProductForSeat = (
  category: Category,
  currentCartData: CartOrOrder,
  service: ExtendedServiceSelectionItem,
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceProduct => {
  const route = getGtmRouteFromFlightIds(service.flightIds, getAllFlights(currentCartData));
  const tripType = getTripTypeFromBounds(currentCartData.bounds);
  const cabin = getCabinFromSeatType(service.seatType);
  const name = getNameFromSeatType(service.seatType);
  return {
    name,
    id: getGtmProductSeatId(service.seatType, service.seatNumber, route, tripType),
    variant: cabin,
    price: service.unitPrice.amount,
    quantity: 1,
    brand: FINNAIR,
    category: `${resolveGtmCategory(category)[1]}/${cabin}/${name}`,
    [GtmCustomDimensions.PNR]: currentCartData.id,
    [GtmCustomDimensions.FLOW_TYPE]: purchaseFlow,
  };
};

export const getNameFromSeatType = (seatType: SeatType): string => {
  const cabinAndName = seatType?.split('_').slice(1);
  const isExit = cabinAndName?.includes(SEAT_TYPE_EXIT);
  const name = cabinAndName?.filter((item) => !SeatTypeCabinClass[item]).join('_');
  return isExit ? SEAT_TYPE_EXIT : name ? name : UNSET;
};

export const getCabinFromSeatType = (seatType: SeatType): SeatTypeCabinClass =>
  SeatTypeCabinClass[seatType?.split('_')[1]] ?? SeatTypeCabinClass.ECONOMY;

export const getGtmProductSeatId = (
  seatType: SeatType,
  seatNumber: string,
  route?: string,
  tripType?: string
): GtmEcommerceProductSeatId =>
  `SEAT:${seatNumber} ${getAircraftFromSeatType(seatType) ?? 'undefined'}_${route ?? UNSET}_${
    tripType ?? UNSET
  }` as GtmEcommerceProductSeatId;

const getAircraftFromSeatType = (seatType: SeatType): string => seatType?.split('_')[0];

export const resolveGtmServiceId = (
  category: Category,
  currentCartData: CartOrOrder,
  service: ExtendedServiceSelectionItem
): string => {
  const route =
    getGtmRouteFromFlightIds(service.flightIds, getAllFlights(currentCartData)) ??
    getGtmRoute(currentCartData.bounds[0]);
  const allFlights = currentCartData.bounds.reduce((all, bound) => all.concat(bound.itinerary.filter(isFlight)), []);
  const flightNumbers = getGtmFlightNumbersFromFlightIds(service.flightIds, allFlights);
  const tripType = getTripTypeFromBounds(currentCartData.bounds);
  if (category === Category.COVER) {
    return `INSURANCE:${service.variant}_${route}_${tripType}`;
  }
  return `${resolveGtmCategory(category)[0]}:${service.variant}_${route}_${flightNumbers}_${tripType}`;
};

export const resolveGtmCategory = (category: Category): [string, string] => {
  const getPrefixedCategory = (service: string, cat?: string): [string, string] => [
    service,
    `Ancillaries/${cat ?? service}`,
  ];
  return getPrefixedCategory(getGtmCategoryName(category));
};

const getGtmCategoryName = (category: Category): string => {
  switch (category) {
    case Category.BAGGAGE:
      return 'EXCESSBAG';
    case Category.MEAL:
      return 'MEAL';
    case Category.COVER:
      return 'INSURANCE';
    case Category.TRAVEL_COMFORT:
      return 'TRAVEL_COMFORT';
    case Category.WIFI:
      return 'WIFI';
    case Category.LOUNGE:
      return 'LOUNGE';
    case Category.SEAT:
      return 'SEAT';
    case Category.SPORT:
      return 'SPORT';
    case Category.PET:
      return 'PET';
    default:
      return 'NOT_DEFINED';
  }
};

export const getGA4Category = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const getGA4Product = (
  service: ExtendedServiceSelectionItem,
  purchaseFlow: GtmPurchaseFlow,
  bound: FinnairBoundItem
): GA4Product => {
  const category = getGA4Category(service.category);
  const id = category + '_' + (service.seatType || service.variant);
  return {
    item_id: id,
    item_name: id,
    price:
      service.category === Category.COVER
        ? parseFloat(service.totalPrice.amount) || 0
        : parseFloat(service.unitPrice.amount) || 0,
    quantity: service.quantity,
    currency: service.totalPrice?.currencyCode ?? NOT_SET,
    affiliation: getAffiliation(purchaseFlow),
    item_category: GA4Category.ANCILLARIES,
    item_category2: category,
    item_category3: service.cabins,
    item_variant: service.seatType ?? service.variant,
    item_haul_type: containsLongHaulFlight([bound]) ? 'long' : 'short',
  };
};

export const mapServicesToGA4ProductList = (
  cartOrOrder: CartOrOrder,
  categories: FinnairServiceItem[] = [],
  purchaseFlow: GtmPurchaseFlow
): GA4Product[] => {
  const servicesPerSegment: ExtendedServiceSelectionItem[] = categories.flatMap((serviceCategory) =>
    serviceCategory.bounds.flatMap((bound) =>
      bound.segments.flatMap((segment) =>
        segment.passengers.flatMap((passenger) =>
          passenger.services.filter(isNotIncludedService).map((service): ExtendedServiceSelectionItem => {
            const flightIds = isBoundBasedCategory(serviceCategory.category)
              ? bound.segments.map((segment) => segment.id)
              : [segment.id];
            const cabins = cartOrOrder.bounds
              .flatMap((b) => b.itinerary)
              .filter(isFlight)
              .filter((i: FinnairItineraryItemFlight) => flightIds.includes(i.id))
              .map((i: FinnairItineraryItemFlight) => i.cabinClass)
              .filter(unique)
              .join('-');

            return {
              ...service,
              category: serviceCategory.category,
              flightIds,
              passengerId: passenger.id,
              fragmentId: segment.id,
              cabins,
              totalPrice: getPriceForSubCategory(passenger, service),
            } as ExtendedServiceSelectionItem;
          })
        )
      )
    )
  );
  return servicesPerSegment
    .reduce((acc: ExtendedServiceSelectionItem[], current) => {
      const hasBeenAddedInPreviousSegment = acc.find(
        (item) => item.id === current.id && item.passengerId === current.passengerId
      );
      return hasBeenAddedInPreviousSegment ? acc : acc.concat([current]);
    }, [])
    .map(
      (service): GA4Product =>
        getGA4Product(
          service,
          purchaseFlow,
          cartOrOrder.bounds.find((bound) => bound.id.includes(service.fragmentId))
        )
    );
};

export const mapServicesToGtmProductList = (
  order: FinnairOrder,
  categories: FinnairServiceItem[] = [],
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceProduct[] => {
  const servicesPerSegment: ExtendedServiceSelectionItem[] = categories.flatMap((serviceCategory) =>
    serviceCategory.bounds.flatMap((bound) =>
      bound.segments.flatMap((segment) =>
        segment.passengers.flatMap((passenger) =>
          passenger.services.filter(isNotIncludedService).map((service): ExtendedServiceSelectionItem => {
            const isPerBound = isBoundBasedCategory(serviceCategory.category);
            return {
              ...service,
              category: serviceCategory.category,
              flightIds: isPerBound ? bound.segments.map((segment) => segment.id) : [segment.id],
              passengerId: passenger.id,
              totalPrice: getPriceForSubCategory(passenger, service),
            };
          })
        )
      )
    )
  );
  return servicesPerSegment
    .reduce((acc: ExtendedServiceSelectionItem[], current) => {
      const hasBeenAddedInPreviousSegment = acc.find(
        (item) => item.id === current.id && item.passengerId === current.passengerId
      );
      return hasBeenAddedInPreviousSegment ? acc : acc.concat([current]);
    }, [])
    .map((service) => {
      if (service.category === Category.SEAT) {
        return getGtmServiceProductForSeat(service.category, order, service, purchaseFlow);
      }
      return getGtmServiceProduct(service.category, order, service, purchaseFlow);
    });
};

export const getGtmFlightProductFromOrder = (
  order: FinnairOrder,
  totalPrices: FinnairTotalPricesSplit,
  purchaseFlow: GtmPurchaseFlow
): Array<GtmEcommerceProduct & GtmFlightProductCustomDefinitions> => {
  const bounds = order.bounds;
  const totalAmount =
    purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE
      ? (totalPrices?.total.balance?.amount ?? '0')
      : totalPrices?.total.totalAmount.amount;
  const tripType = getProductTripTypeFromBounds(bounds);
  const paxAmountFromPrices =
    Object.keys(totalPrices?.totalPerPax ?? []).length > 0 ? Object.keys(totalPrices?.totalPerPax ?? []).length : 1;
  const price = Math.round((parseFloat(totalAmount) / paxAmountFromPrices) * 100) / 100;
  const originLocation = bounds[0].departure.locationCode;
  const destinationLocation = bounds[0].arrival.locationCode;
  const points = Math.round((parseFloat(totalPrices?.total.totalPoints?.amount) / paxAmountFromPrices) * 100) / 100;
  return [
    {
      name: getGtmFlightProductName(bounds, tripType, purchaseFlow),
      id: `${bounds.map((bound) => getGtmFlightNumber(bound.itinerary)).join('::')}_${tripType}${
        purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? '_Change' : ''
      }`,
      price: price.toFixed(2).toString(),
      quantity: paxAmountFromPrices,
      brand: getGtmFareTypes(bounds),
      category: `new-finnair/Flights${
        purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? 'Change' : ''
      }/${tripType}/${getGtmFareTypes(bounds)}`,
      [GtmCustomDimensions.PNR]: order.id,
      [GtmCustomDimensions.FLOW_TYPE]: purchaseFlow,
      [GtmCustomDimensions.FLOW_JOURNEY_LENGTH]: getGtmStayDuration(order.bounds),
      [GtmCustomDimensions.FLOW_DEPARTURE_COUNTRY]: order.locations[originLocation].countryCode,
      [GtmCustomDimensions.FLOW_ARRIVAL_COUNTRY]: order.locations[destinationLocation].countryCode,
      ...(points && { [GtmCustomMetrics.POINTS_SPENT]: points }),
    },
  ];
};

export const getAffiliation = (purchaseFlow: GtmPurchaseFlow): GA4Affiliation =>
  [GtmPurchaseFlow.MANAGE_BOOKING, GtmPurchaseFlow.VOLUNTARY_CHANGE].includes(purchaseFlow)
    ? GA4Affiliation.MMB
    : GA4Affiliation.BOOKING;

export const getGA4FlightProductFromOrder = (
  cartOrOrder: CartOrOrder,
  totalPrices: FinnairTotalPricesSplit,
  purchaseFlow: GtmPurchaseFlow
): Array<GA4Product> => {
  const category = purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? GA4Category.FLIGHT_CHANGE : GA4Category.FLIGHTS;
  const totalAmount =
    purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE
      ? (totalPrices?.total.balance?.amount ?? '0')
      : totalPrices?.total.totalAmount.amount;
  const paxAmountFromPrices =
    Object.keys(totalPrices?.totalPerPax ?? []).length > 0 ? Object.keys(totalPrices?.totalPerPax ?? []).length : 1;
  const price = Math.round((parseFloat(totalAmount) / paxAmountFromPrices) * 100) / 100;
  const tripType = getProductTripTypeFromBoundsForGA4(cartOrOrder.bounds);
  const id = `${tripType}_${cartOrOrder.bounds
    .flatMap((b) => [b.departure.locationCode, b.arrival.locationCode])
    .filter(unique)
    .join('-')}`;

  return [
    {
      item_id: id,
      item_name: id,
      price,
      quantity: paxAmountFromPrices,
      currency: totalPrices?.total.totalAmount.currencyCode ?? NOT_SET,
      affiliation: getAffiliation(purchaseFlow),
      item_category: category,
      item_category2: tripType,
      item_category3: cartOrOrder.bounds.map((bound) => bound.cabinClass).join('-'),
      item_variant: cartOrOrder.bounds.map((bound) => bound.fareFamily.code).join('-'),
      item_haul_type: getHaulTypeFromBounds(cartOrOrder.bounds),
    },
  ];
};

export const getHaulTypeFromBounds = (bounds: FinnairBoundItem[]): string => {
  enum HaulType {
    SHORT = 'short',
    LONG = 'long',
  }

  if (bounds.length > 2) {
    return bounds
      .reduce(
        (haulType: string, bound: FinnairBoundItem) =>
          bound.itinerary
            .filter((item) => item.type === FinnairItineraryItemType.FLIGHT)
            .every((flight: FinnairItineraryItemFlight) => flight.isShortHaul)
            ? `${haulType}_${HaulType.SHORT}`
            : `${haulType}_${HaulType.LONG}`,
        ''
      )
      .substring(1);
  }

  return containsLongHaulFlight(bounds) ? 'long' : 'short';
};

const getGtmStayDurationCategory = (days: number): string => {
  switch (true) {
    case days < 4:
      return `${days} day(s)`;
    case days < 8:
      return '4 to 7 days';
    case days < 15:
      return '7 to 14 days';
    case days < 22:
      return '15 to 21 days';
    case days < 31:
      return '22 to 30 days';
    case days < 181:
      for (let i = 0; i <= 151; i = i + 30) {
        const j = i + 30;
        if (days > i && days <= j) {
          return `${i + 1} to ${j} days`;
        }
      }
      return '31 to 179 days';
    case days > 180:
    default:
      return 'more than 180 days';
  }
};

const getGtmStayDuration = (bounds: FinnairBoundItem[]): string => {
  const departureDate = new TzDate(bounds[0].departure.dateTime);
  const arrivalDate =
    bounds.length > 1 ? new TzDate(bounds[bounds.length - 1].arrival.dateTime) : new TzDate(bounds[0].arrival.dateTime);
  return getGtmStayDurationCategory(diff(departureDate.toDate(), arrivalDate.toDate(), TimeUnit.day));
};

const getGtmFlightProductName = (
  bounds: FinnairBoundItem[],
  tripType: GtmTripTypeLegacy,
  purchaseFlow: GtmPurchaseFlow
): string => {
  const locationCodes = [bounds[0].departure.locationCode, bounds[0].arrival.locationCode];
  if (purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE) {
    const routeWithReturn = [
      ...locationCodes,
      ...(tripType === GtmTripTypeLegacy.RETURN ? [bounds[1].arrival.locationCode] : []),
    ].join('-');
    return [routeWithReturn, tripType, 'Change'].filter(Boolean).join('_');
  }
  return [locationCodes.join('-'), tripType].filter(Boolean).join('_');
};

export const mapToGtmFareFamilyProduct = (
  bound: FinnairBoundItem,
  product: BoundFareFamily,
  boundType: BoundType,
  currencyCode: string,
  fareFamilyBenefitsMap: FareFamilyMap,
  paxAmount: number,
  purchaseFlow: GtmPurchaseFlow,
  list?: GtmEcommerceList
): (GtmEcommerceImpressionProduct | GtmEcommerceProduct) & GtmFareFamilyProductCustomDefinitions => {
  const fareFamilyName = fareFamilyBenefitsMap[product.fareFamilyCode]?.brandName ?? UNSET;
  const price = purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? product.balancePrice : product.totalPrice;
  return {
    name: getGtmRoute(bound),
    id: getGtmFareFamilyProductId(bound, product, paxAmount, boundType, currencyCode, fareFamilyName, purchaseFlow),
    price: (parseFloat(price) / paxAmount).toFixed(2).toString(),
    quantity: paxAmount,
    brand: fareFamilyName,
    category: boundType,
    ...(product.points && { [GtmCustomMetrics.POINTS_SPENT]: parseFloat(product.points) }),
    ...(list && { list }),
    [GtmCustomDimensions.FLOW_TYPE]: purchaseFlow,
  };
};

const getGtmFareFamilyProductId = (
  bound: FinnairBoundItem,
  product: BoundFareFamily,
  paxAmount: number,
  boundType: BoundType,
  currencyCode: string,
  fareFamilyName: string,
  purchaseFlow: GtmPurchaseFlow
) =>
  [
    boundType,
    'fare-family-select',
    `route-${getGtmRoute(bound)}`,
    `flight-${getGtmFlightNumber(bound.itinerary)}`,
    `aircraft-${getGtmAircraft(bound.itinerary)}`,
    `ffname-${fareFamilyName}`,
    `ffcode-${product.fareFamilyCode}`,
    `travelclass-${getGtmTravelClass(product.fareInformation)}`,
    `currency-${currencyCode}`,
    `totalprice-${product.totalPrice}`,
    ...(purchaseFlow === GtmPurchaseFlow.AWARD ? [`points-${product.points ?? UNSET}`] : []),
    ...(purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE
      ? [`balanceprice-${product.balancePrice}`, `penalty-${product.penaltyPrice}`]
      : []),
    `paxamount-${paxAmount.toString()}`,
  ].join('::');

export const containsLongHaulFlight = (bounds: FinnairBoundItem[]): boolean => {
  const isShortHaul = bounds
    .flatMap((bound) => bound.itinerary)
    .map((flight: FinnairItineraryItemFlight) => flight.isShortHaul);
  return isShortHaul.indexOf(false) > -1;
};

export const getGtmActionFieldId = (order: FinnairOrder): string => {
  const endTime = order.bounds[1] ? order.bounds[1].arrival.dateTime.substring(0, 10) : 'oneway';
  return `${order.bounds[0].departure.locationCode}-${
    order.bounds[0].arrival.locationCode
  }_det:${order.bounds[0].departure.dateTime.substring(0, 10)}_ret:${endTime}${
    order.otherInformation.isGroupBooking ? '_group' : ''
  }_${order.id}`;
};

export const getGtmFareTypes = (bounds: FinnairBoundItem[]): string =>
  bounds.map((bound) => bound.fareFamily.code).join(' - ');

export const getTripTypeFromBounds = (bounds: FinnairBoundItem[]): GtmTripType => {
  if (bounds.length === 1) {
    return GtmTripType.ONEWAY;
  }

  if (
    bounds.length === 2 &&
    bounds[0].departure.locationCode === bounds[1].arrival.locationCode &&
    bounds[0].arrival.locationCode === bounds[1].departure.locationCode
  ) {
    return GtmTripType.RETURN;
  }

  if (
    bounds.length === 2 &&
    bounds[0].departure.locationCode === bounds[1].arrival.locationCode &&
    bounds[0].arrival.locationCode !== bounds[1].departure.locationCode
  ) {
    return GtmTripType.OPENJAW;
  }

  return GtmTripType.MULTICITY;
};

export const getTripTypeForFlightSearchParams = (flights: FlightSegment[]): GtmTripType => {
  if (flights.length === 1) {
    return GtmTripType.ONEWAY;
  }

  if (
    flights.length === 2 &&
    flights[0]?.origin === flights[1]?.destination &&
    flights[0]?.destination === flights[1]?.origin
  ) {
    return GtmTripType.RETURN;
  }

  if (flights.length === 2) {
    return GtmTripType.OPENJAW;
  }

  return GtmTripType.MULTICITY;
};

export const combineFlightDates = (data: GtmFlightInformation): CombinedDateString => {
  return getFlightInformationData(data).reduce((result, flight, index) => {
    if (index > 0) {
      result += '_';
    }

    result += flight.date;
    return result;
  }, '') as CombinedDateString;
};

export const combineOriginDestination = (data: GtmFlightInformation): string => {
  return getFlightInformationData(data)
    .map((flight) => `${flight.origin}-${flight.destination}`)
    .join('_');
};

const getFlightInformationData = (flightInformation: GtmFlightInformation): GtmFlightInformationData[] => {
  return flightInformation.map((item) => ({
    origin: item.origin || item.departure.locationCode,
    destination: item.destination || item.arrival.locationCode,
    date: item.departureDate || item.departure.dateTime || item.departureDateTime,
  }));
};

export const getProductTripTypeFromBounds = (bounds: FinnairBoundItem[]): GtmTripTypeLegacy => {
  const map = {
    [GtmTripType.ONEWAY]: GtmTripTypeLegacy.ONEWAY,
    [GtmTripType.RETURN]: GtmTripTypeLegacy.RETURN,
    [GtmTripType.OPENJAW]: GtmTripTypeLegacy.OPENJAW,
    [GtmTripType.MULTICITY]: GtmTripTypeLegacy.MULTICITY,
  };

  return map[getTripTypeFromBounds(bounds)];
};

export const getProductTripTypeFromBoundsForGA4 = (bounds: FinnairBoundItem[]): GA4TripType => {
  const map = {
    [GtmTripType.ONEWAY]: GA4TripType.ONEWAY,
    [GtmTripType.RETURN]: GA4TripType.RETURN,
    [GtmTripType.OPENJAW]: GA4TripType.OPENJAW,
    [GtmTripType.MULTICITY]: GA4TripType.MULTICITY,
  };

  return map[getTripTypeFromBounds(bounds)];
};

const getGtmTotalPaxAmountFromOrder = (order: FinnairOrder): number => {
  const paxAmount = getGtmPaxAmountFromOrder(order);
  return Object.entries(paxAmount)
    .map(([, value]) => value)
    .reduce((acc, val) => acc + val);
};

export const getGtmPaxAmountFromOrder = (order: FinnairOrder): { main: number; children: number; infants: number } => {
  return {
    main: order.passengers.filter(isAdult).length,
    children: order.passengers.filter(
      (pax) => pax.passengerTypeCode === FinnairPassengerCode.CHD || pax.passengerTypeCode === FinnairPassengerCode.C_15
    ).length,
    infants: order.passengers.filter((pax) => pax.passengerTypeCode === FinnairPassengerCode.INF).length,
  };
};

export const mapToGtmOrderData = (
  order: FinnairOrder,
  products: GtmEcommerceProduct[],
  paymentMethods: PaymentTransaction[],
  totalAmount: FinnairPrice,
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceOrderData => {
  const originLocation = order.bounds[0].departure.locationCode;
  const destinationLocation = order.bounds[0].arrival.locationCode;
  const price =
    purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE
      ? (totalAmount?.balance?.amount ?? '0')
      : totalAmount?.totalAmount.amount;

  return {
    custom: {
      locationOrigin: originLocation,
      locationDestination: destinationLocation,
      locationOriginCountry: order.locations[originLocation].countryCode,
      locationDestinationCountry: order.locations[destinationLocation].countryCode,
      longOrShortHaul: containsLongHaulFlight(order.bounds) ? GtmHaulType.LONG_HAUL : GtmHaulType.SHORT_HAUL,
      PNRrecordLocator: order.id,
      fareTypes: getGtmFareTypes(order.bounds),
      tripType: getTripTypeFromBounds(order.bounds),
      totalPaxAmount: getGtmTotalPaxAmountFromOrder(order),
      totalPriceAmount: price || UNSET,
      totalPointsAmount: parseFloat(totalAmount?.totalPoints?.amount) || UNSET,
      paymentType: paymentMethods.map((p) => p.mopType ?? UNSET).join('-') || UNSET,
      paymentSubType: paymentMethods.map((p) => p.mopSubType ?? UNSET).join('-') || UNSET,
      currencyCode: totalAmount?.totalAmount?.currencyCode || UNSET,
      stayDurationCategory: getGtmStayDuration(order.bounds),
      purchaseFlow,
    },
    actionField: {
      id: getGtmActionFieldId(order),
      affiliation: FINNAIR,
      revenue: price,
      shipping: purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? '0' : totalAmount?.totalFees?.amount,
      tax: purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? '0' : totalAmount?.totalTax?.amount,
    },
    products,
  };
};

export const getGA4Items = (
  cartOrOrder: CartOrOrder,
  purchaseFlow: GtmPurchaseFlow,
  isBeforePayment = false
): GA4Product[] => {
  const prices = isBeforePayment ? cartOrOrder.prices?.unpaid : cartOrOrder.prices?.included;

  switch (purchaseFlow) {
    case GtmPurchaseFlow.VOLUNTARY_CHANGE:
      return getGA4FlightProductFromOrder(cartOrOrder, prices?.total, purchaseFlow);
    case GtmPurchaseFlow.MANAGE_BOOKING: {
      const services = isBeforePayment ? cartOrOrder.services.unpaid : getLastPaidServices(cartOrOrder as FinnairOrder);
      return mapServicesToGA4ProductList(cartOrOrder, services, purchaseFlow);
    }
    default: {
      const services = isBeforePayment ? cartOrOrder.services.unpaid : cartOrOrder.services.included;
      return [
        ...getGA4FlightProductFromOrder(cartOrOrder, prices?.flight, purchaseFlow),
        ...mapServicesToGA4ProductList(cartOrOrder, services, purchaseFlow),
      ];
    }
  }
};

export const resolvePurchaseFlow = (profile: Profile | undefined, isAward: boolean): GtmPurchaseFlow => {
  if (profile?.tier === ProfileTier.CORPORATE) {
    return GtmPurchaseFlow.CORPORATE;
  } else if (isAward) {
    return GtmPurchaseFlow.AWARD;
  }

  return GtmPurchaseFlow.BOOKING;
};

export const getGA4ProductsFromSmpProducts = (
  products: SmpProduct[],
  purchaseFlow: GtmPurchaseFlow,
  bounds: FinnairBoundItem[]
): GA4Product[] => {
  const affiliationMap = {
    [GA4Affiliation.BOOKING]: 'booking',
    [GA4Affiliation.MMB]: 'mmb',
  };
  const affiliation = getAffiliation(purchaseFlow);

  return products.map((product) => {
    const notAvailable = snapshot(product.notAvailable$);
    const price = snapshot(product.price$);
    const list_id = `${affiliationMap[affiliation]}-ancillaries-${notAvailable ? 'inactive' : 'master'}`;
    const name = product.category.charAt(0).toUpperCase() + product.category.slice(1);

    return {
      item_id: name,
      item_name: name,
      price: 0,
      quantity: 1,
      currency: price?.currencyCode,
      affiliation: affiliation,
      item_category: GA4Category.ANCILLARIES,
      item_category2: name,
      item_category3: '',
      item_variant: undefined,
      item_list_id: list_id,
      item_list_name: list_id,
      item_haul_type: getHaulTypeFromBounds(bounds),
    };
  });
};

export const mapToGA4FareFamilyProduct = (
  bound: FinnairBoundItem,
  product: BoundFareFamily,
  currencyCode: string,
  fareFamilyBenefitsMap: FareFamilyMap,
  paxAmount: number,
  purchaseFlow: GtmPurchaseFlow
): GA4Product => {
  const category = purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? GA4Category.FLIGHT_CHANGE : GA4Category.FLIGHTS;
  const price = purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? product.balancePrice : product.totalPrice;
  const finalPrice = Math.round((parseFloat(price) / paxAmount) * 100) / 100;
  const fareFamilyName = fareFamilyBenefitsMap[product.fareFamilyCode]?.brandName ?? UNSET;
  const id = `${category}_${product.fareFamilyCode}`;

  return {
    item_id: id,
    item_name: id,
    price: finalPrice,
    quantity: paxAmount,
    currency: currencyCode,
    affiliation: getAffiliation(purchaseFlow),
    item_category: category,
    item_category2: fareFamilyName,
    item_category3: bound.cabinClass,
    item_variant: product.fareFamilyCode,
  };
};

export const getPriceForSubCategory = (
  passenger: FinnairPassengerServiceItem,
  services: FinnairPassengerServiceSelectionItem
): FinnairAmount => {
  return services.subCategory === SubCategory.COVER ? passenger.totalPrice : services.totalPrice;
};
