<ng-container *ngIf="fallbackSrc && thumbnailSrc">
  <picture *ngIf="isLazyLoad; else isPreload">
    <source
      *ngFor="let source of srcSet; trackBy: trackByFn"
      [attr.lazyLoad]="source.src"
      media="(min-width: {{ source.width }}px)"
      [attr.defaultImage]="thumbnailSrc"
    />
    <img
      [attr.fetchpriority]="fetchPriority"
      [attr.alt]="alt || ''"
      [class]="hasAspectRatio ? 'ratio-' + ratio : ''"
      [attr.src]="thumbnailSrc"
      [lazyLoad]="fallbackSrc"
      [ngClass]="{ fill: true, 'fill-parent': fillParent, scale: scale }"
      [style.aspect-ratio]="customAspectRatio"
    />
  </picture>
</ng-container>
<ng-template #isPreload>
  <img
    [attr.srcset]="(isDefaultImageLoaded$ | async) ? preloadImages : null"
    [attr.sizes]="(isDefaultImageLoaded$ | async) ? preloadImagesWidth : null"
    [attr.src]="thumbnailSrc"
    [attr.alt]="alt || ''"
    [attr.fetchpriority]="fetchPriority"
    [ngClass]="{ fill: true, 'fill-parent': fillParent, scale: scale, blur: (isDefaultImageLoaded$ | async) === false }"
    [style.aspect-ratio]="customAspectRatio"
    [class]="hasAspectRatio ? 'ratio-' + ratio : ''"
    (load)="onImageLoaded()"
    loading="eager"
  />
</ng-template>
