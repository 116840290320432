<form [formGroup]="searchForm" class="flex relative header-site-search grow" (ngSubmit)="onSubmit()">
  <fcom-button
    *ngIf="!searchButtonIconOnly"
    class="z0 site-search-expand flex--self-center"
    data-testid="site-search-button"
    [class.is-vishidden]="inputExpanded"
    [aria]="{
      controls: 'site-navigation-search-field-' + context,
      expanded: inputExpanded,
      hidden: inputExpanded,
    }"
    [icon]="{
      name: SvgLibraryIcon.SEARCH,
      category: IconLibrary.SVG_LIBRARY,
      position: IconPosition.LEFT,
    }"
    [text]="'siteSearch.search' | finLocalization"
    [theme]="ButtonTheme.DARK_TERTIARY_GHOST"
    (clickBtn)="expandSearch()"
  ></fcom-button>
  <input
    class="form-control ps-xxsmall-l font-body-1 flex-1 light-type ps-medium-r flex--self-center"
    id="site-navigation-search-field-{{ context }}"
    [class.expanded]="inputExpanded"
    [class.narrow]="enableNewLanguageSelector"
    [attr.tabindex]="!inputExpanded ? -1 : null"
    [attr.aria-label]="'siteSearch.search' | finLocalization"
    [attr.aria-hidden]="inputExpanded ? false : true"
    (blur)="onBlur()"
    [placeholder]="'siteSearch.search' | finLocalization"
    #headerQueryField
    formControlName="searchKey"
    data-testid="site-search-input"
  />
  <fcom-button
    *ngIf="!searchButtonIconOnly"
    class="z0 absolute flex--self-center site-search-submit"
    data-testid="site-search-button-submit"
    [class.is-vishidden]="!inputExpanded"
    [aria]="{
      controls: 'site-navigation-search-field-' + context,
      expanded: inputExpanded,
      hidden: !inputExpanded,
    }"
    [icon]="{
      name: SvgLibraryIcon.SEARCH,
      category: IconLibrary.SVG_LIBRARY,
      position: IconPosition.LEFT,
    }"
    [theme]="ButtonTheme.DARK_TERTIARY_GHOST"
    [type]="ButtonType.SUBMIT"
    [text]="'siteSearch.search' | finLocalization"
  ></fcom-button>

  <fcom-icon-button
    class="z0 absolute flex--self-center site-search-submit"
    data-testid="site-search-button-submit"
    *ngIf="searchButtonIconOnly"
    [icon]="{
      name: SvgLibraryIcon.SEARCH,
      category: IconLibrary.SVG_LIBRARY,
    }"
    [aria]="{
      controls: 'site-navigation-search-field-' + context,
      expanded: inputExpanded,
      hidden: !inputExpanded,
      label: 'siteSearch.search' | finLocalization,
    }"
    [size]="IconButtonSize.SMALL"
    [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
    [type]="ButtonType.SUBMIT"
    (clickBtn)="expandSearch()"
  ></fcom-icon-button>
</form>
