<ng-container *ngIf="imageData$ | async as imageData">
  <fcom-image
    *ngIf="!imageData.error"
    class="fill"
    [fillParent]="fillParent"
    [imageData]="imageData"
    [alt]="imageData?.alt"
    [ratio]="aspectRatio$ | async"
    [scale]="scale"
    [maxWidthImage]="maxImageWidth"
    [fetchPriority]="fetchPriority"
    [isLazyLoad]="isLazyLoad"
  >
  </fcom-image>
</ng-container>
