/* eslint-disable */

/**
 * List of ineligibility reasons for check-in.
 */
export enum FinnairCheckInEligibilityReason {
  NOT_ELIGIBLE_EMAIL_MISSING = 'NOT_ELIGIBLE_EMAIL_MISSING',
  NOT_ELIGIBLE_SSR = 'NOT_ELIGIBLE_SSR',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  NOT_ELIGIBLE_TICKET_RECONCILIATION_NEEDED = 'NOT_ELIGIBLE_TICKET_RECONCILIATION_NEEDED',
  NOT_ELIGIBLE_DISRUPTED_MANAGEABLE_ONLINE = 'NOT_ELIGIBLE_DISRUPTED_MANAGEABLE_ONLINE',
  NOT_ELIGIBLE_DISRUPTED_NOT_MANAGEABLE_ONLINE = 'NOT_ELIGIBLE_DISRUPTED_NOT_MANAGEABLE_ONLINE',
  NOT_ELIGIBLE_FIRST_FLIGHT_ON_OTHER_DEPARTURE_CONTROL_SYSTEM = 'NOT_ELIGIBLE_FIRST_FLIGHT_ON_OTHER_DEPARTURE_CONTROL_SYSTEM',
  NOT_ELIGIBLE_HIGH_PRIORITY_COMMENT = 'NOT_ELIGIBLE_HIGH_PRIORITY_COMMENT',
  NOT_ELIGIBLE_ARRIVAL_AIRPORT_INHIBITED = 'NOT_ELIGIBLE_ARRIVAL_AIRPORT_INHIBITED',
  NOT_ELIGIBLE_DEPARTURE_AIRPORT_INHIBITED = 'NOT_ELIGIBLE_DEPARTURE_AIRPORT_INHIBITED',
  NOT_ELIGIBLE_CHILD_AGE_TO_BE_CHECKED = 'NOT_ELIGIBLE_CHILD_AGE_TO_BE_CHECKED',
  NOT_ELIGIBLE_INFANT_AGE_TO_BE_CHECKED = 'NOT_ELIGIBLE_INFANT_AGE_TO_BE_CHECKED',
  NOT_ELIGIBLE_INFANT_STATUS_NEED_TO_BE_CHECKED = 'NOT_ELIGIBLE_INFANT_STATUS_NEED_TO_BE_CHECKED',
  NOT_ELIGIBLE_CUSTOMER_ELIGIBILITY_RULE = 'NOT_ELIGIBLE_CUSTOMER_ELIGIBILITY_RULE',
  NOT_ELIGIBLE_FIRST_FLIGHT_IS_INHIBITED = 'NOT_ELIGIBLE_FIRST_FLIGHT_IS_INHIBITED',
  NOT_ELIGIBLE_FIRST_FLIGHT_OTHER_AIRLINE = 'NOT_ELIGIBLE_FIRST_FLIGHT_OTHER_AIRLINE',
  NOT_ELIGIBLE_FLIGHT_ELIGIBILITY_DEFAULT_RULE = 'NOT_ELIGIBLE_FLIGHT_ELIGIBILITY_DEFAULT_RULE',
  NOT_ELIGIBLE_FLIGHT_ELIGIBILITY_RULE = 'NOT_ELIGIBLE_FLIGHT_ELIGIBILITY_RULE',
  NOT_ELIGIBLE_ITINERARY_SEQUENCE_CHECK = 'NOT_ELIGIBLE_ITINERARY_SEQUENCE_CHECK',
  NOT_ELIGIBLE_PASSIVE_SEGMENT = 'NOT_ELIGIBLE_PASSIVE_SEGMENT',
  NOT_ELIGIBLE_TICKET_PROBLEM = 'NOT_ELIGIBLE_TICKET_PROBLEM',
  NOT_ELIGIBLE_CHECK_IN_NOT_OPEN = 'NOT_ELIGIBLE_CHECK_IN_NOT_OPEN'
}

