import { Amount, BoundType, CartOffer, CartServices, CartServiceSelections, PaxAmount, TotalPrices } from '@fcom/dapi';
import { PaymentType } from '@fcom/common-booking/modules/purchase-success/interfaces';
import { Bound, FinnairBoundItem, Offer } from '@fcom/dapi/api/models';
import { CombinedDateString } from '@fcom/core/utils';
import { SegmentInfoV2 } from '@fcom/one-upgrade/api/models';
import { GlobalBookingTravelClass } from '@fcom/core';

import { FlightSegment } from './flight-search.interface';
import { ConsentData, DeepLinkSource } from '../interfaces';

export const NOT_SET = 'not set';
export const UNSET = 'UNSET';
export const FINNAIR = 'Finnair';

export enum PromotionType {
  VIEW = 'promoView',
  CLICK = 'promoClick',
}

export enum SearchType {
  BOOKING_FLOW = 'booking-flow',
  AWARD = 'award',
}

export enum GtmHaulType {
  LONG_HAUL = 'long-haul',
  SHORT_HAUL = 'short-haul',
}

export enum ContentType {
  FRONT_PAGE = 'front_page',
  SINGLE_DESTINATION = 'single_destination',
}

export enum GtmPurchaseFlow {
  BOOKING = 'NEW FINNAIR',
  MANAGE_BOOKING = 'MANAGE BOOKING',
  AWARD = 'AWARD',
  CORPORATE = 'CORPORATE',
  VOLUNTARY_CHANGE = 'VOLUNTARY CHANGE',
}

export enum GtmEcommerceMeasurement {
  IMPRESSIONS = 'impressions',
  CLICK = 'click',
  DETAIL = 'detail',
  CHECKOUT = 'checkout',
  ADD = 'add',
  REMOVE = 'remove',
}

export enum GtmEcommerceList {
  BOOKING_SMP = 'Booking - Service master page',
  MMB_VIEW_PNR = 'Manage booking - View PNR',
  VOLUNTARY_CHANGE_FLIGHT_SELECTION = 'Voluntary change - Flight selection',
}

export enum GtmCustomDimensions {
  PNR = 'dimension11', // PNR
  FLOW_TYPE = 'dimension12', // Booking flow - Flow Type
  FLOW_JOURNEY_LENGTH = 'dimension24', // Booking flow - journey length segment
  FLOW_DEPARTURE_COUNTRY = 'dimension47', // Booking flow - product - Departure Country
  FLOW_ARRIVAL_COUNTRY = 'dimension48', // Booking flow - product - Arrival Country
}

export enum GtmCustomMetrics {
  POINTS_SPENT = 'metric1', // Points spent
}

export interface PromotionItem {
  type: string;
  position: string;
  id: string;
  product: string;
}

export interface GtmInternalPromotionItem {
  creative: string;
  id: string;
  name: string;
  position: string;
}

export enum GtmClickOutboundLinkClass {
  BOOKING_WIDGET = 'booking-widget',
  DESTINATION_LISTING_CARD = 'destination-listing-card',
}

export interface GtmClickOutboundData {
  link_url: string;
  link_classes: GtmClickOutboundLinkClass;
  outbound: true;
}

export interface GtmCustomDimensionOrderData extends GtmCustomDimensionEcommerce {
  locationOrigin: string;
  locationDestination: string;
  locationOriginCountry: string;
  locationDestinationCountry: string;
  longOrShortHaul: GtmHaulType;
  PNRrecordLocator: string;
  fareTypes: string;
  tripType: string;
  totalPaxAmount: number;
  totalPriceAmount: string;
  totalPointsAmount: number | 'UNSET';
  paymentType: string;
  paymentSubType: string;
  currencyCode: string;
  stayDurationCategory: string;
}

export interface GtmCustomDimensionEcommerce {
  purchaseFlow: GtmPurchaseFlow;
}

export interface GtmDefaultEvent {
  action: ElementActions;
  label: string;
  category: string;
}

export interface GtmInternalPromotionEvent {
  ecommerce: GtmInternalPromotionView | GtmInternalPromotionClick;
}

export interface GtmInternalPromotionView {
  promoView: {
    promotions: GtmInternalPromotionItem[];
  };
}

export interface GtmInternalPromotionClick {
  promoClick: {
    promotions: GtmInternalPromotionItem[];
  };
}

export interface GtmFlightCheckoutData {
  tripType: GtmTripType;
  offer: CartOffer;
  total: Amount;
  points: string;
  purchaseFlow: GtmPurchaseFlow;
}

export interface GtmSiteSearchData {
  language: string;
  pointOfSale: string;
  event: SiteSearchEvents;
  query: string;
  results: number;
  pageNumber: number;
  clickUrl?: string;
}

export interface GtmPaxData {
  main: number;
  children: number;
  infants: number;
}

export interface SeatMapFlightInfo {
  origin?: string;
  destination?: string;
  departureTime?: string;
  acType?: string;
  flightNo?: string;
  ticketType?: string;
  price?: string;
  currency?: string;
}

export interface SeatMapSeatInfo {
  seatType?: string;
  seatNo?: string;
  price?: string;
  currency?: string;
}

export interface SeatMapData {
  flightInfo?: SeatMapFlightInfo;
  seatInfo?: SeatMapSeatInfo;
}

export type GtmEcommerceProductSeatId = `SEAT:${string} ${string}_${string}_${string}`;

export interface GtmEcommerceBase {
  name: string;
  id: string;
  brand: string;
  category: string;
  [GtmCustomDimensions.PNR]?: string;
  [GtmCustomDimensions.FLOW_TYPE]?: GtmPurchaseFlow;
}

export interface GtmFlightProductCustomDefinitions extends GtmFareFamilyProductCustomDefinitions {
  [GtmCustomDimensions.PNR]: string;
  [GtmCustomDimensions.FLOW_JOURNEY_LENGTH]: string;
  [GtmCustomDimensions.FLOW_DEPARTURE_COUNTRY]: string;
  [GtmCustomDimensions.FLOW_ARRIVAL_COUNTRY]: string;
}

export enum GA4Affiliation {
  BOOKING = 'Finnaircom-booking',
  MMB = 'Finnaircom-MMB',
}

export enum GA4Category {
  FLIGHTS = 'Flights',
  ANCILLARIES = 'Ancillaries',
  FLIGHT_CHANGE = 'FlightsChange',
}

export enum GA4TripType {
  ONEWAY = 'OneWay',
  RETURN = 'RoundTrip',
  OPENJAW = 'OpenJaw',
  MULTICITY = 'MultiCity',
}

export enum Ga4AncillaryType {
  UPGRADE = 'Upgrade',
}

export enum Ga4UpgradeWaitlist {
  WAITLISTED = 'waitlisted',
  NOT_WAITLISTED = 'not-waitlisted',
}

export enum GaContext {
  BOOKING_FLOW = 'booking-flow',
  MOBILE_MAIN_NAVIGATION = 'mobile-main-navigation',
  BLOCK_OFFER = 'block-offer',
  CANCEL_BOOKING = 'cancel-booking',
  CHANGE_FLOW_FLIGHT_SELECTION = 'change-flow-flight-selection',
  CHATTER_MESSAGE_FORM = 'chatter-message-form',
  CONTENT = 'content',
  DESTINATION_MAIN_PAGE = 'destination-main-page',
  LANDING_PAGE = 'landing-page',
  DESKTOP_MAIN_NAVIGATION = 'desktop-main-navigation',
  DISRUPTION = 'disruption',
  FINNAIR_PLUS = 'finnair-plus',
  FLIGHT_SELECTION = 'flight-selection',
  PAX_DETAILS_FLIGHT_REVIEW = 'pax-details-flight-review',
  MANAGE_BOOKING = 'manage-booking',
  CHECK_IN = 'check-in',
  CORPORATE_PORTAL = 'corporate-portal',
  QUALTRICS = 'qualtrics',
  REACCOMMODATION = 'reaccommodation',
  REFUNDS = 'refunds',
  RETRIEVE_CASE_FORM = 'retrieve-case-form',
  SEAT_MAP = 'booking-flow_seatmap',
  SERVICE_FORMS = 'service-forms',
  SECTION_NAVIGATION = 'section-navigation',
  TOP_NAVIGATION = 'top-navigation',
  THIRD_PARTY_ANCILLARY = 'third-party-ancillary',
  UPGRADE_AVAILABILITY_SEARCH = 'upgrade-availability-search',
  VOLUNTARY_CHANGE = 'voluntary-change',
  WIZARD_CASE_FORM = 'wizard-case-form',
  NO_CONTEXT = 'no-context',
  CHAT = 'content-chat',
}

export interface GA4Product {
  /**
   * The ID of the item. Same as the item_name.
   */
  item_id: string;
  /**
   * The name of the item.
   *
   * Flights: [TripType]_[locationCode 1]-[locationCode 2]-[locationCode n]
   * Upgrades: [TripType]_upg_[locationCode 1]-[locationCode 2]-[locationCode n]
   * Seats: [Category]_[Seat type]
   * Other services: [Category]_[variant]
   */
  item_name: string;
  price: number;
  quantity: number;
  currency: string;
  affiliation: GA4Affiliation;
  item_category: GA4Category;
  item_category2: GA4TripType | Ga4AncillaryType | string;
  /**
   * Cabins combined with dash
   * ECONOMY-ECONOMY
   */
  item_category3: string;
  /**
   * Ticket type (dash separated), Seat type or variant
   * ECLASSIC01-ECLASSIC01
   * A350_ECONOMY_PREFERRED
   * PDBG
   */
  item_category4?: string;
  item_category5?: Ga4UpgradeWaitlist | string;
  item_variant: string;
  item_list_id?: string;
  item_list_name?: string;
  item_haul_type?: string;
}

export interface GtmFareFamilyProductCustomDefinitions {
  [GtmCustomMetrics.POINTS_SPENT]?: number; // Points spent
}

export interface GtmEcommerceImpressionProduct extends GtmEcommerceBase {
  list: string;
  position?: number;
  variant?: string;
  price?: string;
}

export interface GtmEcommerceClickProduct extends GtmEcommerceBase {
  position?: number;
  variant?: string;
  price?: string;
}

export interface GtmEcommerceDetailProduct extends GtmEcommerceBase {
  variant?: string;
  price?: string;
}

export interface GtmEcommerceProduct extends GtmEcommerceBase {
  price?: string;
  variant?: string;
  quantity?: number;
}

export interface GtmEcommerceActionField {
  id: string;
  affiliation: string;
  revenue: string;
  shipping: string;
  tax: string;
}

export interface GtmTransactionData extends GtmFlightCheckoutData {
  seats?: CartServiceSelections;
  locations: GtmLocations;
  services: CartServices;
  id: string;
  prices: TotalPrices;
  paymentType: string;
  paymentSubType: string;
  paxAmount: GtmPaxData;
  purchaseFlow: GtmPurchaseFlow;
}

export interface GA4PurchaseEvent {
  event: GtmEvent.PURCHASE;
  dates?: CombinedDateString;
  points_used?: string;
  points?: number;
  ecomNew: GA4PurchaseData;
  all_locations: string;
}

export interface GA4PurchaseData {
  transaction_id: string;
  affiliation: GA4Affiliation;
  value: number;
  tax: number;
  shipping: number;
  currency: string;
  items: GA4Product[];
  coupon?: string;
}

export interface GtmLocations {
  originCountryCode: string;
  destinationCountryCode: string;
}

export interface GtmFlightSearchData {
  origin: string;
  destination: string;
  start: string | undefined;
  end: string | undefined;
  tripType: GtmTripType;
  travelClass: GlobalBookingTravelClass;
  passengers: Omit<PaxAmount, 'c15s'>;
  totalPax: number;
  purchaseFlow?: GtmPurchaseFlow;
  dates: CombinedDateString;
  all_locations: string;
  searchType: SearchType;
  amountOfBounds: number;
  haulType: string;
}

export interface GTtmMulticityTicketTypeOptions {
  economy: string;
  ecopremium: string;
  business: string;
}

export interface GtmPageViewData {
  path: string;
  language: string;
  pointOfSale: string;
}

export interface GtmUiEventData {
  id: string;
  language: string;
  pagePath: string;
}

export interface GtmFlowData {
  officeId: string;
  market: string;
  destinationCountryCode: string;
  destinationLocationCode: string;
  originCountryCode: string;
  originLocationCode: string;
  sellAncillariesPerFlight: { [id: string]: boolean };
  sellBags: boolean;
  purchaseFlow: GtmPurchaseFlow;
}

export interface GtmPaymentMethodData {
  methodOfPayment: string;
  joinFinnairPlus: boolean;
  rememberPaxDetails: boolean;
}

export interface GtmFlightOfferSelection extends Offer {
  preselectionUsed: boolean;
}

export interface GtmFlightSelectionData {
  boundType: BoundType;
  aircraft: string;
  flightNumber: string;
  fareFamilyName: string;
  travelClass: string;
  currencyCode: string;
  price: string;
  points: string;
  paxAmount: string;
  route: string;
  purchaseFlow: GtmPurchaseFlow;
}

export interface GtmMulticityOfferSelectionData extends GtmFlightSelectionData {
  boundNumber: number;
  stage: string;
}

export interface GtmMulticityFareFamilySelectionData {
  boundType: BoundType;
  fareFamilyName: string;
  travelClass: string;
  currencyCode: string;
  price: string;
  points: string;
  paxAmount: string;
  route: string;
  dates: string;
  purchaseFlow: GtmPurchaseFlow;
  stage: string;
}

export interface GtmUiEventWithContextData extends GtmUiEventData {
  context: string;
  triggerElement: string;
  state: string;
  action?: ElementActions;
}

export interface GtmUiEventWithIndexData extends GtmUiEventWithContextData {
  index: number;
}

export interface GtmErrorEvent {
  type: string;
  path: string;
  locations?: string;
  dates?: string;
}

export interface GtmMultivariateEvent {
  testId: string;
  segment: number;
  source: string;
  variant: string;
}

export interface GtmNativeAppSession {
  system: MobileOsType;
  isInsideNativeApp: boolean;
}

export interface GtmDestinationPromotion {
  element: string;
  destCity: string;
  destCountry: string;
}

export interface GtmLoginEvent {
  tier: string;
  corporateProfile?: string;
  isLogged: boolean;
}

export enum HaulType {
  SHORT = 'short-haul',
  LONG = 'long-haul',
}

export enum UpgradePaymentType {
  ECOPREMIUM_CASH = 'Ancillaries/Upgrade/ECOPREMIUM/Cash',
  BUSINESS_CASH = 'Ancillaries/Upgrade/BUSINESS/Cash',
  ECOPREMIUM_SHORT_HAUL_VOUCHER = 'Ancillaries/Upgrade/ECOPREMIUM/Voucher/short-haul',
  BUSINESS_SHORT_HAUL_VOUCHER = 'Ancillaries/Upgrade/BUSINESS/Voucher/short-haul',
  ECOPREMIUM_LONG_HAUL_VOUCHER = 'Ancillaries/Upgrade/ECOPREMIUM/Voucher/long-haul',
  BUSINESS_LONG_HAUL_VOUCHER = 'Ancillaries/Upgrade/BUSINESS/Voucher/long-haul',
  ECOPREMIUM_POINTS = 'Ancillaries/Upgrade/ECOPREMIUM/Points',
  BUSINESS_POINTS = 'Ancillaries/Upgrade/BUSINESS/Points',
  ECOPREMIUM_COMPLIMENTARY = 'Ancillaries/Upgrade/ECOPREMIUM/Complimentary',
  BUSINESS_COMPLIMENTARY = 'Ancillaries/Upgrade/BUSINESS/Complimentary',
}

export enum UpgradePaymentShortType {
  UNKNOWN = 'UNKNOWN',
  CASH = 'CASH',
  VOUCHER = 'VOUCHER',
  POINTS = 'POINTS',
  COMPLIMENTARY = 'COMPLIMENTARY',
}

export interface UpgradePurchaseDataBasketFlight {
  name: 'Upgrade';
  id: string;
  price: string;
  brand: 'Finnair';
  category: UpgradePaymentType;
  quantity: number;
  metric1: number;
}

export interface UpgradePurchaseData {
  upgradePurchase: {
    activeCurrency: string;
    purchaseSource: DeepLinkSource;
    totalPointsSpent: number;
    origin: string; // e.g. HEL
    destination: string; // e.g. HEL
    departure: string; // e.g. 2012-06-09
    return: string; // e.g. 2012-06-09
    paymentMethod?: PaymentType[] | null;
    haulType: HaulType;
  };
  ecommerce: {
    purchase: {
      actionField: {
        id: string; // E.g. MEL-HEL_dep:2019-08-21_ret:2019-08-24_VRHA7T_upgrade_waitlisted
        revenue: string | '0'; // e.g. 60.99
      };
      products: Array<UpgradePurchaseDataBasketFlight>;
    };
  };
}

export interface ReaccommodationBoundData {
  /**
   * IATA code for bound origin location, e.g. HEL
   */
  departureLocation: string;
  /**
   * IATA code for bound arrival location, e.g. AMS
   */
  arrivalLocation: string;
  /**
   * Datetime for departure, e.g. 2019-09-14T14:26:19.828Z
   */
  departureTime: string;
  /**
   * Datetime for arrival, e.g. 2019-09-15T14:26:19.828Z
   */
  arrivalTime: string;
  /**
   * Operating airlnecodes for bound, e.g. ['AY'];
   */
  operatingAirlineCodes: string[];
}

export interface ReaccommodationData {
  reaccommodation: {
    reaccommodationSource: DeepLinkSource;
    proposedFlightAccepted: boolean;
    offerToAcknowledge: ReaccommodationBoundData;
    selectedOffer: ReaccommodationBoundData;
  };
}

export interface GtmEcommerceOrderData {
  custom: GtmCustomDimensionOrderData;
  products: GtmEcommerceProduct[];
  actionField: GtmEcommerceActionField;
}

export interface GtmEcommerceAddToCart {
  currencyCode: string;
  add: {
    products: GtmEcommerceProduct[];
  };
}

export interface GtmEcommerceRemoveFromCart {
  remove: {
    products: GtmEcommerceProduct[];
  };
}

export interface GtmEcommerceImpression {
  currencyCode?: string;
  impressions: GtmEcommerceImpressionProduct[];
}

export interface GtmEcommerceClick {
  currencyCode?: string;
  click: {
    actionField: {
      list?: string;
    };
    products: GtmEcommerceClickProduct[];
  };
}

export interface GtmEcommerceDetail {
  currencyCode?: string;
  detail: {
    actionField: {
      list?: string;
    };
    products: GtmEcommerceDetailProduct[];
  };
}

export interface GtmPath {
  path: string;
  basePath: string;
  queryString: string;
}

export interface GtmFlightInformationData {
  origin: string;
  destination: string;
  date: string;
}

export interface ThirdPartyClickEvent {
  thirdParty: string;
}

export interface NewsletterFormSubmittedEvent {
  selected_country: string;
  selected_language: string;
}

export type GtmFlightInformation = FlightSegment[] | FinnairBoundItem[] | SegmentInfoV2[] | Bound[];

export type GtmEventData =
  | GtmDefaultEvent
  | GtmTransactionData
  | GtmFlightCheckoutData
  | GtmFlightSearchData
  | GtmFlightSelectionData[]
  | GtmPageViewData
  | GtmUiEventData
  | GtmUiEventWithContextData
  | GtmFlowData
  | GtmPaymentMethodData
  | GtmUiEventWithIndexData
  | GtmFlightOfferSelection
  | GtmErrorEvent
  | GtmDestinationPromotion
  | GtmNativeAppSession
  | GtmLoginEvent
  | GtmMultivariateEvent
  | ConsentData
  | UpgradePurchaseData
  | ReaccommodationData
  | GtmInternalPromotionEvent
  | GtmSiteSearchData
  | GtmEcommerceOrderData
  | GA4PurchaseData
  | ThirdPartyClickEvent
  | GtmClickOutboundData
  | NewsletterFormSubmittedEvent
  | GTtmMulticityTicketTypeOptions
  | GtmMulticityFareFamilySelectionData
  | GtmMulticityOfferSelectionData
  | string;

export enum GtmEvent {
  VISIBILITY_CHANGE = 'visibilityChange',
  PAGE_VIEW = 'pageView',
  ORIGINAL_LOCATION = 'originalLocation',
  PRE_FLIGHT_SEARCH = 'preFlightSearch',
  FLIGHT_SEARCH = 'flightSearch',
  FLIGHT_SELECTION_VIEW = 'flightSelectionView',
  FLIGHT_SELECTION_CLICK = 'flightSelectionSelect',
  FLIGHT = 'flight',
  FLIGHT_CART_CHECKOUT = 'flightCartCheckout',
  MULTICITY_TICKET_TYPE_OPTIONS = 'multicity-ticket-type-options',
  /**
   * @deprecated use ADD_TO_CART for GA4
   * This is for old analytics only
   */
  ADD_SERVICES = 'addServices',
  /**
   * @deprecated use REMOVE_FROM_CART for GA4
   * This is for old analytics only
   */
  REMOVE_SERVICES = 'removeServices',
  PRODUCT_IMPRESSIONS = 'productImpressions',
  PRODUCT_CLICK = 'productClick',
  PRODUCT_DETAIL = 'productDetail',
  /**
   * @deprecated use PURCHASE for GA4
   * This is for old analytics only
   */
  ORDER = 'order',
  TRANSACTION = 'transaction',
  TRANSACTION_MANAGE_BOOKING = 'transactionManageBooking',
  UI_EVENT = 'uiEvent',
  FLOW_DATA = 'flowData',
  TIMING = 'timing',
  ERROR = 'error',
  DESTINATION_PROMOTION = 'destinationPromotion',
  NATIVEAPP_SESSION = 'nativeAppSession',
  CONSENTS = 'consentsSet',
  LOGIN = 'login',
  MULTIVARIATE_TEST = 'multiVariateTest',
  UPGRADE_PURCHASE = 'upgradePurchase',
  REACCOMMODATION = 'reaccommodation',
  INTERNAL_PROMOTION_VIEW = 'internalPromotionView',
  INTERNAL_PROMOTION_CLICK = 'internalPromotionClick',
  SITE_SEARCH = 'siteSearch',
  SITE_SEARCH_CLICKED = 'siteSearchClicked',
  /**
   * @deprecated use PURCHASE for GA4
   * This is for old analytics only
   */
  ORDER_DATA = 'orderData',
  /**
   * GA4 analytics transaction
   */
  PURCHASE = 'purchase',
  /**
   * GA4 analytics add products to cart
   */
  ADD_TO_CART = 'add_to_cart',
  /**
   * GA4 analytics remove products from cart
   */
  REMOVE_FROM_CART = 'remove_from_cart',
  /**
   * GA4 analytics view cart/order before payment
   */
  VIEW_CART = 'view_cart',
  /**
   * GA4 analytics initialize checkout
   */
  BEGIN_CHECKOUT = 'begin_checkout',
  /**
   * GA4 analytics view available ancillaries list
   */
  VIEW_ITEM_LIST = 'view_item_list',

  /**
   * GA4 analytics ancillaries selected
   */
  SELECT_ITEM = 'select_item',

  THIRD_PARTY_CLICK = 'third_party',

  CLEAR_ECOM = 'clear-ecom',

  CLICK_OUTBOUND = 'click_outbound',

  NEWSLETTER_FORM_SUBMITTED = 'newsletter_form_submitted',

  NEWSLETTER_SUBSCRIPTION_COMPLETED = 'newsletter_subscription_completed',
}

export enum MobileOsType {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum GtmTripType {
  ONEWAY = 'ONEWAY',
  RETURN = 'RETURN',
  OPENJAW = 'OPENJAW',
  MULTICITY = 'MULTICITY',
}

export enum GtmTripTypeLegacy {
  ONEWAY = 'OneWay',
  RETURN = 'RoundTrip',
  OPENJAW = 'OpenJaw',
  MULTICITY = 'MultiCity',
}

// NOTE: To be expanded
export enum ElementTypes {
  MODAL = 'MODAL',
  ACCORDION = 'ACCORDION',
  BUTTON = 'BUTTON',
  TAB = 'TAB',
  SEARCH = 'SEARCH',
  LIST_ITEM = 'LIST_ITEM',
  INPUT = 'INPUT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  LINK = 'LINK',
  BANNER = 'BANNER',
  BOOKING_WIDGET = 'BOOKING_WIDGET',
  FORM = 'FORM',
  SELECT_BOX = 'SELECT_BOX',
  SURVEY = 'SURVEY',
  NOTIFICATION = 'NOTIFICATION',
}

// NOTE: To be expanded
// Mainly exist so we can send specific actions from component side also
// Needed to match the tracking convention of old finnair.com
export enum ElementActions {
  OPEN = 'open',
  CLOSE = 'close',
  CLICK = 'click',
  VIEW = 'view',
  SCROLL = 'scroll',
  SUBMIT = 'submit',
  ERROR = 'error',
  SEARCH = 'search',
}

export enum SiteSearchEvents {
  SEARCH = 'search',
  SUGGEST = 'suggest',
  RESULT_CLICK = 'resultClick',
  SUGGESTION_CLICK = 'suggestionClick',
}
