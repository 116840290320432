import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { GtmService } from '@fcom/common/gtm';
import {
  ConsentData,
  ElementActions,
  ElementTypes,
  GA4Product,
  GA4PurchaseData,
  GA4PurchaseEvent,
  GTtmMulticityTicketTypeOptions,
  GaContext,
  GtmClickOutboundData,
  GtmCustomDimensionEcommerce,
  GtmEcommerceAddToCart,
  GtmEcommerceClick,
  GtmEcommerceClickProduct,
  GtmEcommerceDetail,
  GtmEcommerceDetailProduct,
  GtmEcommerceImpression,
  GtmEcommerceImpressionProduct,
  GtmEcommerceList,
  GtmEcommerceMeasurement,
  GtmEcommerceProduct,
  GtmEcommerceRemoveFromCart,
  GtmEvent,
  GtmEventData,
  GtmFlightSearchData,
  GtmFlightSelectionData,
  GtmInternalPromotionEvent,
  GtmInternalPromotionItem,
  GtmLoginEvent,
  GtmMulticityFareFamilySelectionData,
  GtmMulticityOfferSelectionData,
  GtmPath,
  GtmPurchaseFlow,
  GtmTripType,
  MobileOsType,
  NOT_SET,
  OfferListFetchParams,
  PreFlightSearchParams,
  PromotionItem,
  PromotionType,
  SearchType,
  SeatMapData,
  SiteSearchEvents,
  UNSET,
  FlightSegment,
} from '@fcom/common/interfaces';
import { Links, PageMetaService } from '@fcom/common/services';
import { AppState, GlobalBookingTravelClass, NativeBridgeService, WindowRef } from '@fcom/core';
import { Profile } from '@fcom/core-api/login';
import { loginStatus, profile } from '@fcom/core/selectors';
import {
  Amount,
  CartOffer,
  CartServices,
  CustomServiceType,
  FeeAmount,
  PricesPerBound,
  TaxAmount,
  TotalPrices,
  UnitPriceBreakdown,
} from '@fcom/dapi';
import { CombinedDateString, entrySet, hyphenate, isPresent, mapValues, TzDate, unsubscribe } from '@fcom/core/utils';
import {
  filteredUtmParamsQueryString,
  pathIsAnyHardcodedPath,
  urlWithoutQueryString,
} from '@fcom/core/utils/app-url-utils';
import {
  Cabin,
  Category,
  ControlData,
  FinnairCart,
  FinnairOrder,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairPrice,
  FinnairServiceCategoryPriceItem,
  FinnairServiceItem,
  FinnairServiceSegmentItem,
  PaymentTransaction,
} from '@fcom/dapi/api/models';
import { isNotIncludedService, toAmount } from '@fcom/common-booking/utils/common-booking.utils';
import { BookingTripLocations } from '@fcom/common-booking';
import {
  combineFlightDates,
  combineOriginDestination,
  getAffiliation,
  getGA4Items,
  getGtmFlightProductFromOrder,
  getGtmPaxAmountFromOrder,
  getTripTypeForFlightSearchParams,
  getTripTypeFromBounds,
  mapServicesToGtmProductList,
  mapToGtmOrderData,
} from '@fcom/common/utils/gtm.utils';
import { finShare } from '@fcom/rx';
import { isBoundBasedCategory, isJourneyBasedCategory } from '@fcom/common-booking/modules/ancillaries/utils';
import { getLastPaidPrices, getLastPaidServices } from '@fcom/common-booking/utils/order.utils';
import { isShortHaulFlight } from '@fcom/common/utils';
import { LocationsMap } from '@fcom/core-api';

interface LocaleData {
  language: string;
  pointOfSale: string;
}

enum URLState {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

type GTMURLContext = Record<URLState, LocaleData & { path: string; normalizedPath: string }>;

// We assign these in a case of international english.
const DEFAULT_LANG = 'FI';
const DEFAULT_POS = 'INT';

const getGtmLangAndPointOfSale = (locale: string): LocaleData => {
  if (locale === 'en') {
    return { language: 'EN', pointOfSale: DEFAULT_POS };
  }
  if (!isPresent(locale) || typeof locale !== 'string' || locale.indexOf('-') < 0) {
    // Just to be sure as the using part isn't null safe
    return { language: DEFAULT_LANG, pointOfSale: DEFAULT_POS };
  }
  const splitted = locale.toUpperCase().split('-');
  return { language: splitted[1], pointOfSale: splitted[0] };
};

export const getGtmUrlWithoutLocale = (url: string, locale: string): string => {
  const langObj = getGtmLangAndPointOfSale(locale);
  const divider = url.split('/').length < 3 ? '' : '/';

  if (langObj.pointOfSale === DEFAULT_POS) {
    return url.replace(
      pathIsAnyHardcodedPath(url)
        ? `${divider}${langObj.language.toLowerCase()}`
        : `/${langObj.pointOfSale.toLowerCase()}-${langObj.language.toLowerCase()}`,
      ''
    );
  } else {
    return url.replace(`${divider}${locale}`, '');
  }
};

/**
 * Combine language and locale parameters with fi-en english page names
 * to create desired tracking url format.
 * NOTE: how used refLang is being fallbacked causes test cms to often return '' for page path, as the content is not found.
 */
const convertGtmUrl = (locales: LocaleData, links: Links): string => {
  const refLang: string =
    links[`${locales.pointOfSale.toLowerCase()}-en`] ||
    links[`${locales.pointOfSale.toLowerCase()}-${locales.language.toLowerCase()}`] ||
    links['en'] ||
    '';
  const path: string = refLang.split('/').slice(4).join('/');
  const originalLangLocale = `/${locales.pointOfSale.toLowerCase()}-${locales.language.toLowerCase()}`;
  return `${originalLangLocale}/${path}`;
};

export const mapCabinToTravelClass = (cabin: Cabin): GlobalBookingTravelClass => {
  return GlobalBookingTravelClass[Cabin[cabin] as keyof typeof GlobalBookingTravelClass];
};

export const mapToOldGtmOffer = (data: FinnairCart | FinnairOrder): CartOffer => {
  const prices = data.prices.included || data.prices.unpaid;

  const mapUnitPrices = (): UnitPriceBreakdown[] => {
    return entrySet(prices.total.totalPerPax).map((paxPrices: { key: string; value: FinnairPrice }) => ({
      total: paxPrices.value.totalAmount,
      tax: paxPrices.value.totalTax,
      baseFare: paxPrices.value.baseFare,
      fees: paxPrices.value.fees as FeeAmount[],
      taxes: paxPrices.value.taxes as TaxAmount[],
      surcharges: paxPrices.value.surcharges,
      travelerIds: [paxPrices.key],
      totalPerPTC: null,
    }));
  };

  // use prices.flight as it is basically the offer
  return {
    id: 'not-known',
    itemId: 'not-known',
    fareFamilyCode: data.bounds?.[0]?.fareFamily?.code,
    fareFamilyBrandName: data.bounds?.[0]?.fareFamily?.name,
    newBounds: data.bounds,
    prices: {
      totalPrice: {
        total: toAmount(prices.flight.total.totalAmount),
        tax: toAmount(prices.flight.total.totalTax),
        baseFare: toAmount(prices.flight.total.baseFare),
        fee: toAmount(prices.flight.total.totalFees),
      },
      unitPrices: mapUnitPrices(),
    },
  };
};

const getGtmFlightServices = (
  service: FinnairServiceItem | undefined,
  flightId: string
): FinnairPassengerServiceItem[] => {
  if (!service) {
    return [];
  }
  return [
    ...service.bounds.reduce(
      (allServices, b) => allServices.concat(...(b.segments.find((s) => s.id === flightId)?.passengers || [])),
      []
    ),
  ];
};

const loadGtmServicesFromOrder = (services: Array<FinnairServiceItem>, category: Category) => {
  const serviceItemForCategory = services?.find((s) => s.category === category);

  const toService = (
    s1: FinnairPassengerServiceItem,
    s2: FinnairPassengerServiceSelectionItem,
    currentSegment: FinnairServiceSegmentItem
  ) => ({
    category: s2.subCategory,
    variant: s2.variant,
    price: s2.unitPrice,
    flightIds: [currentSegment.id],
    quantity: s2.quantity,
    serviceId: s2.id,
    subCategory: s2.displayName,
    travelerId: s1.id,
    seatNumber: s2.seatNumber,
    seatType: s2.seatType,
    displayPrice: s2.unitPrice,
  });

  return (
    serviceItemForCategory?.bounds.reduce((s: any, currentBound) => {
      return currentBound.segments.reduce((s2: any, currentSegment) => {
        const servicesForFlight = getGtmFlightServices(serviceItemForCategory, currentSegment.id);
        const newServiceCatalogServices = servicesForFlight.reduce((allServices, service) => {
          return allServices.concat(
            ...service.services.filter(isNotIncludedService).map((serviceItem) => {
              return toService(service, serviceItem, currentSegment);
            })
          );
        }, []);

        const getFragmentId = () => {
          if (isJourneyBasedCategory(category)) {
            return CustomServiceType.JOURNEY;
          }
          if (isBoundBasedCategory(category)) {
            return currentBound.id;
          }
          return currentSegment.id;
        };

        s2[getFragmentId()] = newServiceCatalogServices;
        return s2;
      }, s);
    }, {}) || {}
  );
};

export const mapToOldGtmServices = (services: Array<FinnairServiceItem>): CartServices => {
  return {
    baggage: loadGtmServicesFromOrder(services, Category.BAGGAGE),
    seats: loadGtmServicesFromOrder(services, Category.SEAT),
    lounge: loadGtmServicesFromOrder(services, Category.LOUNGE),
    meals: loadGtmServicesFromOrder(services, Category.MEAL),
    wifi: loadGtmServicesFromOrder(services, Category.WIFI),
    cover: loadGtmServicesFromOrder(services, Category.COVER),
    travelComforts: loadGtmServicesFromOrder(services, Category.TRAVEL_COMFORT),
    pet: loadGtmServicesFromOrder(services, Category.PET),
    sport: loadGtmServicesFromOrder(services, Category.SPORT),
  };
};

export const mapToOldGtmPrices = (data: FinnairOrder): TotalPrices => {
  const prices = data.prices.included;

  const getGtmBasePrices = (
    service: FinnairServiceItem,
    totalPerCategory: FinnairServiceCategoryPriceItem | undefined
  ) => ({
    total: {
      numberOfItems: service?.quantity ?? 0,
      price: service?.totalPrice ?? null,
    },
    totalPerPax: mapValues(totalPerCategory?.totalPerPax ?? {}, (value) => {
      return {
        numberOfItems: value.quantity,
        price: value.price.totalAmount,
        ptc: '',
      };
    }),
  });

  const toGtmFlightServicePrices = (category: Category) => {
    const service = data.services.included.find((c) => c.category === category);
    const totalPerCategory = prices?.services?.totalPerCategory?.find((c) => c.category === category);

    return {
      ...getGtmBasePrices(service, totalPerCategory),
      totalPerFlight: service?.bounds.reduce((all, bound) => {
        return {
          ...all,
          ...bound.segments.reduce((all2, segment) => {
            all2[segment.id] = {
              numberOfItems: segment.quantity,
              price: segment.totalPrice,
            };
            return all2;
          }, {} as any),
        };
      }, {}),
    };
  };

  const toGtmBoundServicePrices = (category: Category) => {
    const service = data.services.included.find((c) => c.category === category);
    const totalPerCategory = prices?.services.totalPerCategory.find((c) => c.category === category);

    return {
      ...getGtmBasePrices(service, totalPerCategory),
      totalPerBound: service?.bounds.reduce((all, bound) => {
        all[bound.id] = {
          numberOfItems: bound.quantity,
          price: bound.totalPrice,
        };
        return all;
      }, {} as PricesPerBound),
    };
  };

  return {
    total: toAmount(prices.total.total.totalAmount),
    totalPerPax: mapValues(prices.total.totalPerPax, (value) => toAmount(value.totalAmount)),
    services: {
      seats: toGtmFlightServicePrices(Category.SEAT),
      meals: toGtmFlightServicePrices(Category.MEAL),
      wifi: toGtmFlightServicePrices(Category.WIFI),
      lounge: toGtmFlightServicePrices(Category.LOUNGE),
      baggage: toGtmBoundServicePrices(Category.BAGGAGE),
      pet: toGtmBoundServicePrices(Category.PET),
      sport: toGtmBoundServicePrices(Category.SPORT),
      travelComforts: toGtmFlightServicePrices(Category.TRAVEL_COMFORT),
      cover: toGtmBoundServicePrices(Category.COVER),
      saf: toGtmBoundServicePrices(Category.SAF),
      total: prices.services.total.totalAmount,
    },
    offers: {
      total: toAmount(prices.flight.total.totalAmount),
      totalPerPax: mapValues(prices.flight.totalPerPax, (value, key) => ({
        ptc: data.passengers.find((p) => p.id === key)?.passengerTypeCode?.toString(),
        numberOfItems: 1,
        price: toAmount(value.totalAmount),
      })),
      totalPerPtc: {}, // not possible to get anywhere?
    },
    taxes: {
      total: toAmount(prices.total.total.totalTax),
      offers: {
        total: toAmount(prices.flight.total.totalTax),
        totalPerPax: mapValues(prices.flight.totalPerPax, (value, key) => ({
          ptc: data.passengers.find((p) => p.id === key)?.passengerTypeCode?.toString(),
          numberOfItems: 1,
          price: toAmount(value.totalTax),
        })),
        totalPerPtc: {}, // not possible to get anywhere?
      },
    },
  };
};

/**
 * Interacts with Google Tag Manager through its dataLayer object.
 * Data pushed to GTM via dataLayer can be used to set up e.g. analytics.
 */
@Injectable()
export class ClientGtmService extends GtmService implements OnDestroy {
  dataLayer: Array<any>;
  urlContext: GTMURLContext;

  private subscriptions = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private windowRef: WindowRef,
    private languageService: LanguageService,
    private nativeBridgeService: NativeBridgeService,
    private pageMetaService: PageMetaService,
    private router: Router
  ) {
    super();
    this.dataLayer = this.windowRef.nativeWindow['dataLayer'] = this.windowRef.nativeWindow['dataLayer'] || [];
    if (this.nativeBridgeService.isInsideNativeWebView) {
      this.nativeAppSessionEvent(
        this.nativeBridgeService.isInsideNativeAndroidWebView ? MobileOsType.ANDROID : MobileOsType.IOS
      );

      this.subscriptions.add(
        this.nativeBridgeService.appHideStatus.pipe(distinctUntilChanged()).subscribe((appHideStatus: boolean) => {
          this.pushEventToDataLayer(GtmEvent.VISIBILITY_CHANGE, { appHidden: appHideStatus });
        })
      );
    }

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationStart),
          switchMap((event) => this.getGtmPath((event as NavigationStart).url))
        )
        .subscribe((gtmPath) => {
          const locale = this.languageService.langValue;
          const langObj = getGtmLangAndPointOfSale(locale);
          const basePathWithoutLocale = getGtmUrlWithoutLocale(gtmPath.basePath, locale);
          const normalizedPath = `${basePathWithoutLocale}${gtmPath.queryString}`;
          this.urlContext = {
            [URLState.CURRENT]: {
              ...langObj,
              path: gtmPath.path,
              normalizedPath,
            },
            [URLState.PREVIOUS]: this.urlContext?.current,
          };
        })
    );

    this.subscriptions.add(
      this.store$
        .pipe(
          loginStatus(),
          finShare(),
          filter((status) => status === 'LOGGED_IN'),
          switchMap(() => this.store$.pipe(profile(), take(1)))
        )
        .subscribe((memberProfile: Profile) => {
          const eventData: GtmLoginEvent = {
            tier: memberProfile.tier || 'not set',
            isLogged: true,
          };
          if (memberProfile.isCorporate) {
            eventData.corporateProfile = memberProfile.corporate?.userRole || 'not set';
          }
          this.pushEventToDataLayer(GtmEvent.LOGIN, eventData);
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  getGtmPath = (path: string): Observable<GtmPath> => {
    const langObj = getGtmLangAndPointOfSale(this.languageService.langValue);
    const noParamsPath = urlWithoutQueryString(path);
    const filteredQueryString = filteredUtmParamsQueryString(path);

    if (pathIsAnyHardcodedPath(noParamsPath)) {
      return of({
        path,
        basePath: noParamsPath,
        queryString: filteredQueryString,
        normalizedPath: `${noParamsPath}${filteredQueryString}`,
      });
    } else {
      return this.pageMetaService.linksForUrl(noParamsPath).pipe(
        take(1),
        map((links: Links) => ({
          path: convertGtmUrl(langObj, links),
          basePath: convertGtmUrl(langObj, links),
          queryString: filteredQueryString,
        }))
      );
    }
  };

  // Due to localized url paths outside of hard coded pages, we need to handle different paths differently
  pageView(path: string): void {
    const locale = this.languageService.langValue;
    const langObj = getGtmLangAndPointOfSale(locale);
    this.subscriptions.add(
      this.getGtmPath(path).subscribe((gtmPath: GtmPath) => {
        const basePathWithoutLocale = getGtmUrlWithoutLocale(gtmPath.basePath, locale);
        const normalizedPath = `${basePathWithoutLocale}${gtmPath.queryString}`;

        this.pushEventToDataLayer(
          GtmEvent.PAGE_VIEW,
          Object.assign(langObj, {
            path: gtmPath.path,
            normalizedPath,
          })
        );
      })
    );
  }

  originalPageView(path: string): void {
    this.subscriptions.add(
      this.getGtmPath(path).subscribe((gtmPath: GtmPath) =>
        this.pushEventToDataLayer(GtmEvent.ORIGINAL_LOCATION, `${gtmPath.basePath}${gtmPath.queryString}`)
      )
    );
  }

  internalPromotion(promotionItemArray: PromotionItem[], type: PromotionType): void {
    const promotionItems: GtmInternalPromotionItem[] = promotionItemArray.map((promotion: PromotionItem) => {
      return {
        creative: promotion.product,
        id: `${promotion.type}::${promotion.id}::${promotion.position}::${promotion.product}`,
        position: promotion.position,
        name: promotion.id,
      };
    });

    if (type === PromotionType.VIEW) {
      const promotionData: GtmInternalPromotionEvent = {
        ecommerce: {
          promoView: {
            promotions: promotionItems,
          },
        },
      };
      this.pushEventToDataLayer(GtmEvent.INTERNAL_PROMOTION_VIEW, promotionData);
    } else {
      const promotionData: GtmInternalPromotionEvent = {
        ecommerce: {
          promoClick: {
            promotions: promotionItems,
          },
        },
      };
      this.pushEventToDataLayer(GtmEvent.INTERNAL_PROMOTION_CLICK, promotionData);
    }
  }

  /**
   * This method is used to report UI related events (clicks, openings, closings) to GTM's data layer.
   *
   * @param elementName Will be hyphenated and truncated to max 100 chars before pushing to dataLayer.
   * @param context Will be hyphenated and truncated to max 40 chars before pushing to dataLayer.
   * @param elementType Won't be truncated.
   * @param state Will be truncated to max 100 chars before pushing to dataLayer.
   * @param action Won't be truncated.
   * @param token An optional param that used only by some squads. Will be truncated to max 100 chars before pushing to dataLayer.
   *
   * Some of the params are truncated because if they exceed the allowed amount, they don't show up in GTM statistics,
   * which skews the data in GTM.
   *
   * Note:
   * GTM has a logic to find last valid value for specific object keys with same name.
   * This brings the need to 'flush' optional values with undefined, so that the gtm does not use
   * previous event's values for more recently pushed events.
   */
  trackElement(
    elementName: string,
    context: string = undefined,
    elementType: ElementTypes,
    state: string | undefined = undefined,
    action: ElementActions | undefined = undefined,
    token?: string,
    searchType?: SearchType
  ): void {
    this.subscriptions.add(
      this.getGtmPath(this.router.url).subscribe((gtmPath: GtmPath) =>
        this.pushEventToDataLayer(GtmEvent.UI_EVENT, {
          context: hyphenate(context)?.slice(0, 40),
          id: hyphenate(elementName)?.slice(0, 100),
          language: this.languageService.langValue,
          pagePath: `${getGtmUrlWithoutLocale(gtmPath.basePath, this.languageService.langValue)}${gtmPath.queryString}`,
          // TODO: to be removed when removing old GA support, check with analysts before making changes
          triggerElement: ElementTypes[elementType],
          state: state?.slice(0, 100),
          // TODO: to be removed when removing old GA support, check with analysts before making changes
          ...(isPresent(action) && { action }),
          ...(token && { token: token.slice(0, 100) }),
          ...(isPresent(searchType) && { searchType }),
        })
      )
    );
  }

  flightSelection(event: GtmEvent, data: GtmFlightSelectionData[]): void {
    this.pushEventToDataLayer(event, data);
  }

  multicityFareFamilySelection(event: GtmEvent, data: GtmMulticityFareFamilySelectionData): void {
    this.pushEventToDataLayer(event, data);
  }

  multicityOfferSelection(event: GtmEvent, data: GtmMulticityOfferSelectionData): void {
    this.pushEventToDataLayer(event, data);
  }

  paxDetailsFlightReview(event: GtmEvent, data: GtmFlightSelectionData[]): void {
    this.pushEventToDataLayer(event, data);
  }

  flowData(data: ControlData, purchaseFlow: GtmPurchaseFlow): void {
    this.pushEventToDataLayer(GtmEvent.FLOW_DATA, {
      officeId: data.officeId,
      market: data.market,
      destinationCountryCode: data.destinationCountryCode,
      destinationLocationCode: data.destinationLocationCode,
      originCountryCode: data.originCountryCode,
      originLocationCode: data.originLocationCode,
      sellAncillariesPerFlight: data.sellAncillariesPerFlight,
      sellBags: data.sellBagsOutbound || data.sellBagsInbound,
      purchaseFlow,
    });
  }

  /**
   * @todo: When ancillaries get their own promotion elements, we need to either generalise this event type & function
   * or create a new function and types
   * @param element
   * @param destCity
   * @param destCountry
   */
  destinationPromotion(element: string, destCity: string, destCountry: string): void {
    this.pushEventToDataLayer(GtmEvent.DESTINATION_PROMOTION, {
      element,
      destCity,
      destCountry,
    });
  }

  setConsents(consentData: ConsentData): void {
    this.pushEventToDataLayer(GtmEvent.CONSENTS, consentData);
  }

  seatMapEvent(
    elementName: string,
    elementType: ElementTypes,
    state: string | undefined,
    action: string | undefined,
    seatMapData: SeatMapData
  ): void {
    this.subscriptions.add(
      this.getGtmPath(this.router.url)
        .pipe(take(1))
        .subscribe((gtmPath: GtmPath) =>
          this.dataLayer.push({
            event: GtmEvent.UI_EVENT,
            uiEvent: {
              context: GaContext.SEAT_MAP,
              id: hyphenate(elementName)?.slice(0, 100),
              language: this.languageService.langValue,
              pagePath: `${getGtmUrlWithoutLocale(gtmPath.basePath, this.languageService.langValue)}${gtmPath.queryString}`,
              triggerElement: ElementTypes[elementType],
              state: state?.slice(0, 100),
              action: action,
            },
            flowType: GaContext.BOOKING_FLOW,
            ...seatMapData,
          })
        )
    );
  }

  completePurchase(
    order: FinnairOrder,
    type: GtmTripType,
    total: Amount,
    storeLocations: BookingTripLocations,
    totalPoints: string | undefined,
    purchaseFlow: GtmPurchaseFlow.BOOKING | GtmPurchaseFlow.AWARD | GtmPurchaseFlow.CORPORATE,
    discountCode: string | undefined
  ): void {
    // @TODO Remove GtmEvent.ORDER data once all dependencies on GTM are removed
    // Only use GtmEvent.ORDER_DATA once the transition is done
    this.pushEventToDataLayer(GtmEvent.ORDER, {
      tripType: type,
      locations: {
        originCountryCode: storeLocations.origin ? storeLocations.origin.countryCode : undefined,
        destinationCountryCode: storeLocations.destination ? storeLocations.destination.countryCode : undefined,
      },
      offer: mapToOldGtmOffer(order),
      services: mapToOldGtmServices(order?.services?.included),
      total: total,
      id: order.id,
      prices: mapToOldGtmPrices(order),
      paymentType: order.payments.map((p) => p.mopType ?? NOT_SET).join('-') || NOT_SET,
      paymentSubType: order.payments.map((p) => p.mopSubType ?? NOT_SET).join('-') || NOT_SET,
      paxAmount: getGtmPaxAmountFromOrder(order),
      points: totalPoints ?? NOT_SET,
      purchaseFlow,
    });

    const products = [
      ...getGtmFlightProductFromOrder(order, order.prices?.included?.flight, purchaseFlow),
      ...mapServicesToGtmProductList(order, order.services.included, purchaseFlow),
    ];

    const pricesTotal = order.prices.included?.total?.total;
    this.pushEventToDataLayer(
      GtmEvent.ORDER_DATA,
      mapToGtmOrderData(order, products, order.payments, pricesTotal, purchaseFlow)
    );
    this.pushEventToDataLayer(GtmEvent.TRANSACTION);
    this.purchaseTransactionForGA4(order, purchaseFlow, discountCode);
  }

  completeManageBookingPurchase(
    order: FinnairOrder,
    paymentMethods: PaymentTransaction[],
    purchaseFlow: GtmPurchaseFlow.MANAGE_BOOKING | GtmPurchaseFlow.VOLUNTARY_CHANGE
  ): void {
    const products =
      purchaseFlow === GtmPurchaseFlow.MANAGE_BOOKING
        ? mapServicesToGtmProductList(order, getLastPaidServices(order), purchaseFlow)
        : getGtmFlightProductFromOrder(order, order.prices?.included?.total, purchaseFlow);
    const pricesTotal =
      purchaseFlow === GtmPurchaseFlow.MANAGE_BOOKING
        ? getLastPaidPrices(order)?.total.total
        : order.prices.included?.total?.total;
    this.pushEventToDataLayer(
      GtmEvent.ORDER_DATA,
      mapToGtmOrderData(order, products, paymentMethods, pricesTotal, purchaseFlow)
    );
    this.pushEventToDataLayer(GtmEvent.TRANSACTION_MANAGE_BOOKING);
    this.purchaseTransactionForGA4(order, purchaseFlow);
  }

  ecommerceEvent(
    event: GtmEvent,
    type: GtmEcommerceMeasurement.ADD | GtmEcommerceMeasurement.REMOVE | GtmEcommerceMeasurement.IMPRESSIONS,
    products: GtmEcommerceImpressionProduct[] | GtmEcommerceProduct[],
    purchaseFlow: GtmPurchaseFlow,
    currencyCode?: string
  ): void {
    const ecommerce: GtmEcommerceAddToCart | GtmEcommerceRemoveFromCart | GtmEcommerceImpression = {
      ...(type === GtmEcommerceMeasurement.IMPRESSIONS && { impressions: products }),
      ...(type === GtmEcommerceMeasurement.ADD && { add: { products }, currencyCode }),
      ...(type === GtmEcommerceMeasurement.REMOVE && { remove: { products } }),
    };
    const custom: GtmCustomDimensionEcommerce = { purchaseFlow };

    this.dataLayer.push({
      event,
      ecommerce,
      custom,
    });
  }

  enhancedEcommerceProductClickOrDetailView(
    event: GtmEvent,
    type: GtmEcommerceMeasurement.CLICK | GtmEcommerceMeasurement.DETAIL,
    product: GtmEcommerceClickProduct | GtmEcommerceDetailProduct,
    list: GtmEcommerceList,
    purchaseFlow: GtmPurchaseFlow,
    currencyCode?: string
  ): void {
    const ecommerce: GtmEcommerceClick | GtmEcommerceDetail = {
      ...(type === GtmEcommerceMeasurement.CLICK && { click: { actionField: { list }, products: [product] } }),
      ...(type === GtmEcommerceMeasurement.DETAIL && { detail: { actionField: { list }, products: [product] } }),
      ...(currencyCode && { currencyCode }),
    };
    this.dataLayer.push({
      event,
      ecommerce,
      custom: { purchaseFlow },
    });
  }

  pushEventToDataLayer(event: GtmEvent, eventData?: GtmEventData): void {
    this.dataLayer.push({ event, [event]: eventData });
  }

  // TODO: We don't have anymore offer in the cart so this should be updated to something else
  checkoutFlights(cart: FinnairCart, purchaseFlow: GtmPurchaseFlow): void {
    // Check for the existance of inbound data obj to figure out the trip type
    const tripType = getTripTypeFromBounds(cart.bounds);
    const totalAmount = cart.prices.unpaid.total.total.totalAmount;
    const points = cart.prices.unpaid.total.total.totalPoints?.amount;

    const total: Amount = toAmount(totalAmount);

    this.pushEventToDataLayer(GtmEvent.FLIGHT_CART_CHECKOUT, {
      tripType,
      offer: mapToOldGtmOffer(cart),
      total,
      points: points ?? NOT_SET,
      purchaseFlow,
    });
  }

  siteSearch(event: SiteSearchEvents, query: string, results: number, pageNumber: number, clickUrl?: string): void {
    const langObj = getGtmLangAndPointOfSale(this.languageService.langValue);

    if (clickUrl) {
      this.pushEventToDataLayer(
        GtmEvent.SITE_SEARCH_CLICKED,
        Object.assign(langObj, {
          event,
          query,
          results,
          pageNumber,
          clickUrl,
        })
      );
    } else {
      this.pushEventToDataLayer(
        GtmEvent.SITE_SEARCH,
        Object.assign(langObj, {
          event,
          query,
          results,
          pageNumber,
        })
      );
    }
  }

  preFlightSearch(params: PreFlightSearchParams): void {
    this.pushEventToDataLayer(GtmEvent.PRE_FLIGHT_SEARCH, {
      origin: params.flights[0].origin,
      destination: params.flights[0].destination,
      start: params.flights[0].departureDate?.toString(),
      end: params.flights.length > 1 ? params.flights[1].departureDate.toString() : undefined,
      tripType: getTripTypeForFlightSearchParams(params.flights),
      travelClass: params.travelClass,
      passengers: {
        adults: params.paxAmount.adults,
        children: params.paxAmount.children + params.paxAmount.c15s,
        infants: params.paxAmount.infants,
      },
      searchType: params.isAward ? SearchType.AWARD : SearchType.BOOKING_FLOW,
      totalPax: Object.values(params.paxAmount).reduce((total, val) => total + val, 0),
      dates: combineFlightDates(params.flights),
      all_locations: combineOriginDestination(params.flights),
      amountOfBounds: params.flights.length,
    } as GtmFlightSearchData);
  }

  flightSearch(params: OfferListFetchParams, locations: LocationsMap): void {
    const departureDate =
      (params.flights.length > 2 && params.flights.at(-1).departureDate.toString()) ||
      (params.flights.length > 1 && params.flights[1].departureDate.toString()) ||
      undefined;

    const flightSearchEvent = this.addUrlContextToLayerEvent<GtmFlightSearchData>(URLState.PREVIOUS, {
      origin: params.flights[0].origin,
      destination: params.flights[params.flights.length > 2 ? params.flights.length - 1 : 0].destination,
      start: params.flights[0].departureDate?.toString(),
      end: departureDate,
      tripType: getTripTypeForFlightSearchParams(params.flights),
      travelClass: mapCabinToTravelClass(params.cabin),
      passengers: {
        adults: params.paxAmount.adults,
        children: params.paxAmount.children + params.paxAmount.c15s,
        infants: params.paxAmount.infants,
      },
      searchType: params.isAward ? SearchType.AWARD : SearchType.BOOKING_FLOW,
      totalPax: Object.values(params.paxAmount).reduce((total, val) => total + val, 0),
      dates: combineFlightDates(params.flights),
      all_locations: combineOriginDestination(params.flights),
      amountOfBounds: params.flights.length,
      haulType: this.getHaulType(params.flights, locations),
    });

    this.pushEventToDataLayer(GtmEvent.FLIGHT_SEARCH, flightSearchEvent);
  }

  multicityTicketTypeOptions(cabin: Cabin, ticketTypeOptions: GTtmMulticityTicketTypeOptions): void {
    this.pushEventToDataLayer(GtmEvent.UI_EVENT, {
      action: ElementActions.CLICK,
      language: this.languageService.langValue,
      pagePath: this.router.url,
      context: GaContext.FLIGHT_SELECTION,
      id: 'multicity-ticket-type-options',
      state: 'searched-' + mapCabinToTravelClass(cabin),
      economy: ticketTypeOptions?.economy || '',
      ecopremium: ticketTypeOptions?.ecopremium || '',
      business: ticketTypeOptions?.business || '',
    } as GTtmMulticityTicketTypeOptions);
  }

  /**
   * Error Event
   * @param type AirOffersStatusToText
   * @param flights FlightSegment[]
   */
  errorEvent(type: string, flights?: FlightSegment[]): void {
    this.subscriptions.add(
      this.getGtmPath(this.router.url).subscribe((gtmPath: GtmPath) =>
        this.pushEventToDataLayer(GtmEvent.ERROR, {
          path: `${getGtmUrlWithoutLocale(gtmPath.basePath, this.languageService.langValue)}`,
          type,
          ...(isPresent(flights) && { all_locations: combineOriginDestination(flights) }),
          ...(isPresent(flights) && { dates: combineFlightDates(flights) }),
        })
      )
    );
  }

  private addUrlContextToLayerEvent = <T>(urlState: URLState, event: T): T & Partial<GTMURLContext> => {
    const context = this.urlContext?.[urlState] ?? {};
    return { ...event, ...context };
  };

  private nativeAppSessionEvent(system: MobileOsType) {
    this.pushEventToDataLayer(GtmEvent.NATIVEAPP_SESSION, {
      system,
      isInsideNativeApp: this.nativeBridgeService.isInsideNativeWebView,
    });
  }

  private purchaseTransactionForGA4(
    order: FinnairOrder,
    purchaseFlow: GtmPurchaseFlow,
    discountCode: string = undefined
  ): void {
    this.resetEcommerceGA4();

    const pricesTotal =
      purchaseFlow === GtmPurchaseFlow.MANAGE_BOOKING
        ? getLastPaidPrices(order)?.total?.total
        : order.prices.included?.total?.total;

    const price =
      purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE
        ? (pricesTotal?.balance?.amount ?? '0')
        : pricesTotal?.totalAmount.amount;

    const ecomNew: GA4PurchaseData = {
      transaction_id: `${order.otherInformation.analyticsToken}_${TzDate.now().toISOString()}`,
      affiliation: getAffiliation(purchaseFlow),
      value: parseFloat(price) || 0,
      tax: parseFloat(purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? '0' : pricesTotal?.totalTax?.amount) || 0,
      shipping:
        parseFloat(purchaseFlow === GtmPurchaseFlow.VOLUNTARY_CHANGE ? '0' : pricesTotal?.totalFees?.amount) || 0,
      currency: pricesTotal?.totalAmount?.currencyCode || UNSET,
      items: getGA4Items(order, purchaseFlow),
      coupon: discountCode,
    };

    const dates = order.bounds
      .map((b) => new TzDate(b.departure.dateTime).toLocalDate().toString())
      .join('_') as CombinedDateString;

    const allLocations = order.bounds.map((b) => `${b.departure.locationCode}-${b.arrival.locationCode}`).join('_');

    const totalPoints = pricesTotal?.totalPoints?.amount;
    const purchaseEvent: GA4PurchaseEvent = {
      event: GtmEvent.PURCHASE,
      dates,
      ...(totalPoints ? { points_used: 'true' } : {}),
      ...(totalPoints ? { points: parseFloat(totalPoints) } : {}),
      ecomNew,
      all_locations: allLocations,
    };

    this.dataLayer.push(purchaseEvent);
  }

  ecommercePurchaseEventGa4(eventData: GA4PurchaseEvent): void {
    this.resetEcommerceGA4();
    this.dataLayer.push(eventData);
  }

  ecommerceEventGA4(
    event:
      | GtmEvent.ADD_TO_CART
      | GtmEvent.REMOVE_FROM_CART
      | GtmEvent.VIEW_CART
      | GtmEvent.BEGIN_CHECKOUT
      | GtmEvent.VIEW_ITEM_LIST
      | GtmEvent.SELECT_ITEM,
    products: GA4Product[]
  ): void {
    this.resetEcommerceGA4();
    this.dataLayer.push({
      event,
      ecomNew: {
        items: products,
      },
    });
  }

  resetEcommerceGA4() {
    // clear ecom event, trello 7153
    this.dataLayer.push({
      event: GtmEvent.CLEAR_ECOM,
      ecomNew: null,
    });
  }

  clickOutboundEvent(data: GtmClickOutboundData): void {
    this.pushEventToDataLayer(GtmEvent.CLICK_OUTBOUND, data);
  }

  private getHaulType(flights: FlightSegment[], locations: LocationsMap): string {
    enum HaulType {
      SHORT = 'short',
      LONG = 'long',
    }

    const tripType = getTripTypeForFlightSearchParams(flights);

    if (tripType === GtmTripType.MULTICITY || tripType === GtmTripType.OPENJAW) {
      return flights
        .reduce(
          (haulType: string, flight: FlightSegment) =>
            isShortHaulFlight(locations[flight.origin], locations[flight.destination])
              ? `${haulType}_${HaulType.SHORT}`
              : `${haulType}_${HaulType.LONG}`,
          ''
        )
        .substring(1);
    }

    return isShortHaulFlight(locations[flights[0].origin], locations[flights[0].destination])
      ? HaulType.SHORT
      : HaulType.LONG;
  }
}
