<div
  #tabsContainer
  class="header header-desktop"
  [ngClass]="{
    'pr-medium-x': layoutType === TabLayoutType.IN_MODAL,
    'mr-small-b':
      theme === TabTheme.DEFAULT && (layoutType === TabLayoutType.DEFAULT || layoutType === TabLayoutType.IN_MODAL),
    'border-bottom': bottomBorder && theme === TabTheme.DEFAULT && layoutType !== TabLayoutType.HAS_BACKGROUND,
    'before-border relative': theme === TabTheme.CLASSIC && layoutType !== TabLayoutType.HAS_BACKGROUND,
  }"
>
  <div class="flex flex--center mobile-reverse">
    <fcom-carousel
      #carousel
      class="flex-1 overflow-auto"
      [align]="align"
      [hasGreyBackground]="hasGreyBackground"
      [nextButtonAriaLabel]="nextButtonAriaLabel"
      [prevButtonAriaLabel]="prevButtonAriaLabel"
      [theme]="layoutType === TabLayoutType.HAS_BACKGROUND ? CarouselTheme.HAS_BACKGROUND : CarouselTheme.DEFAULT"
    >
      <ul #tabsNavi class="unstyled flex flex--no-wrap" role="tablist">
        <li
          role="none"
          *ngFor="let tab of tabs; let i = index"
          [class.tab--default]="theme === TabTheme.DEFAULT"
          [class.tab--classic]="theme === TabTheme.CLASSIC"
          [class.tab--stretch]="layoutType === TabLayoutType.STRETCH"
          [class.tab--modal]="layoutType === TabLayoutType.IN_MODAL"
          [class.tab--background]="layoutType === TabLayoutType.HAS_BACKGROUND"
          [class.tab--active]="tab.active"
        >
          <button
            [attr.data-testid]="tab.tabTitleDataTestId"
            class="tab-button {{
              layoutType === TabLayoutType.STRETCH
                ? tabStyleOverrideClasses
                  ? tabStyleOverrideClasses
                  : 'ps-medium-t ps-xxsmall-x'
                : ''
            }}"
            [ngClass]="{
              'flex flex--middle': theme === TabTheme.DEFAULT && tab.icon && tab.icon.position === TabIconPosition.LEFT,
              nowrap: whiteSpaceNowrap === true,
            }"
            [disabled]="tab.disabled"
            (click)="selectTab(tab)"
            [attr.aria-selected]="tab.active"
            [attr.aria-controls]="tab.id"
            role="tab"
            [id]="tab.id + '-tab'"
          >
            <fcom-icon
              *ngIf="theme === TabTheme.DEFAULT && tab.icon.name"
              class="icon-medium"
              [class.ms-xxsmall-r]="tab.icon && tab.icon.position === TabIconPosition.LEFT"
              [name]="tab.icon.name"
            ></fcom-icon>
            <span
              class="tab-title"
              [ngClass]="{
                'flex medium-type': theme !== TabTheme.CLASSIC,
                'block font-body-1 bold-type': theme === TabTheme.CLASSIC,
              }"
              *ngIf="tab?.title"
            >
              {{ tab.title }}
              <ng-container
                *ngIf="tab.contentPopover"
                [ngTemplateOutlet]="popover"
                [ngTemplateOutletContext]="{ contentPopover: tab.contentPopover }"
              ></ng-container>
            </span>
            <span
              class="tab-subtitle block"
              [ngClass]="{
                'light-type': theme !== TabTheme.CLASSIC,
                'font-body-1 ms-xsmall-b': theme === TabTheme.CLASSIC,
              }"
              *ngIf="tab?.subtitle"
            >
              {{ tab.subtitle }}
            </span>
          </button>
        </li>

        <ng-template #popover let-contentPopover="contentPopover">
          <button
            data-testid="tabs-popover"
            type="button"
            fcomPopover
            [content]="contentPopover"
            [ariaCloseText]="'actions.close' | finLocalization"
            [attr.aria-label]="'actions.info' | finLocalization"
            class="icon ms-xxsmall-l"
          >
            <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.HELP_BLOCK"></fcom-icon>
          </button>
        </ng-template>
      </ul>
    </fcom-carousel>
    <div class="flex--nogrow mobile-right">
      <ng-content select="tabSidebar"></ng-content>
    </div>
  </div>
</div>
<div
  class="tab-content"
  [class.tab-content--in-modal]="layoutType === TabLayoutType.IN_MODAL"
  [class.tab-content--stretch]="layoutType === TabLayoutType.STRETCH"
  [class.tab-content--default]="theme === TabTheme.DEFAULT && layoutType === TabLayoutType.DEFAULT"
  [class.tab-content--classic]="theme === TabTheme.CLASSIC"
  [class.tab-content--has-background]="layoutType === TabLayoutType.HAS_BACKGROUND"
>
  <ng-content></ng-content>
</div>
