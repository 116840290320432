import { Component, Input, OnInit } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsImageData } from '@fcom/core-api';
import { AspectRatios } from '@fcom/ui-components';
import { MediaQueryService } from '@fcom/common';
import { BookingLocationCodes } from '@fcom/common/interfaces/booking';
import { ContentType, TripType } from '@fcom/common/interfaces';
import { LocationPair } from '@fcom/common/store';
import { SentryLogger } from '@fcom/core';

type AllowedAspectRatios = keyof typeof AspectRatios;

/**
 * Full Width Booking Widget
 *
 * @example
 * <cms-full-width-booking-widget
 *   [backgroundImage]="CmsImageData"
 *   [originCode]="'BER'"
 *   [destinationCode]="'HEL'"
 *   [enableAward]="true"
 *   [enableAurinkomatkat]="true"
 *   [enablePromoCode]="true"
 * ></cms-full-width-booking-widget>
 */
@Component({
  selector: 'cms-full-width-booking-widget',
  templateUrl: './cms-full-width-booking-widget.component.html',
  styleUrls: ['./cms-full-width-booking-widget.component.scss'],
})
export class CmsFullWidthBookingWidgetComponent implements OnInit {
  aspectRatio$: Observable<AllowedAspectRatios>;

  @Input()
  backgroundImage: CmsImageData;

  @Input()
  locations: BookingLocationCodes[] = [];

  @Input()
  enableAward = false;

  @Input()
  enableAurinkomatkat = false;

  @Input()
  enablePromoCode = false;

  @Input()
  enableAutoFocus = true;

  @Input()
  enableOpenJaw = false;

  @Input()
  initialTripType: TripType;

  @Input()
  contentType: ContentType;

  constructor(
    private mediaQueryService: MediaQueryService,
    private sentryLogger: SentryLogger
  ) {}

  defaultLocationPairs: LocationPair[];

  ngOnInit(): void {
    this.aspectRatio$ = combineLatest([
      this.mediaQueryService.isBreakpoint$('mobile'),
      this.mediaQueryService.isBreakpoint$('tablet'),
    ]).pipe(
      map(([isMobile, isTablet]): AllowedAspectRatios => {
        if (isMobile) {
          return '1x1';
        }

        if (isTablet) {
          return '4x3';
        }

        return '16x9';
      })
    );

    this.defaultLocationPairs = this.locations.map(
      (ond: BookingLocationCodes) =>
        ({
          origin: ond?.originLocationCode ? { locationCode: ond.originLocationCode } : undefined,
          destination: ond?.destinationLocationCode ? { locationCode: ond.destinationLocationCode } : undefined,
        }) as LocationPair
    );

    if (this.initialTripType && !Object.values(TripType).includes(this.initialTripType)) {
      this.sentryLogger.error(
        `Unexpected initialTripType: ${this.initialTripType} when rendering the booking widget in a content page`
      );
    }
  }
}
