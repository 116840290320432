import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable, Subject, Subscription, combineLatest, filter, map, of, startWith } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { isNotEmpty, LocalDate, unsubscribe } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';

import { ScrollHandleContainerComponent } from '../scroll-handle-container/scroll-handle-container.component';
import { Direction, HistogramBar } from '../../interfaces';
import { MediaQueryService } from '../../services';
import { generateBarsForYear } from '../../utils/histogram.utils';

@Component({
  selector: 'fin-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistogramComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly CMS_STYLE_OVERRIDE = {
    'margin-top': '0px',
  };

  @Input()
  barData$: Observable<HistogramBar[]> = EMPTY;

  @Input()
  selected$ = of(0);

  @Input()
  isActive$ = of(false);

  @Input()
  isLoading$ = of(false);

  @Output()
  barSelected: EventEmitter<HistogramBar> = new EventEmitter();

  @Output()
  barsScrolled: EventEmitter<Direction> = new EventEmitter();

  range$: Observable<HistogramBar[]>;
  minAmount$: Observable<number>;

  private adjustHistogramBarHeight$: Subject<void> = new Subject();
  private readonly MIN_COLUMN_HEIGHT = 44;
  private readonly HISTOGRAM_TOP_SPACING = 20;
  private maxAmount$: Observable<number>;

  @ViewChild('histogramContainer', { static: true }) histogramContainer: ElementRef;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ScrollHandleContainerComponent;
  @ViewChildren('bar', { read: ElementRef }) bars: QueryList<ElementRef>;

  subscription: Subscription = new Subscription();

  constructor(
    private mediaQueryService: MediaQueryService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.minAmount$ = this.barData$.pipe(
      map(
        (barData: HistogramBar[]) =>
          barData &&
          Math.min(
            ...barData
              .filter((bar: HistogramBar) => +bar.amount && +bar.amount > 0)
              .map((bar: HistogramBar) => +bar.amount)
          )
      ),
      finShare()
    );

    this.maxAmount$ = this.barData$.pipe(
      map(
        (barData: HistogramBar[]) =>
          barData &&
          Math.max(
            ...barData
              .filter((bar: HistogramBar) => +bar.amount && +bar.amount > 0)
              .map((bar: HistogramBar) => +bar.amount)
          )
      ),
      finShare()
    );

    const loadingBars$ = this.languageService.translate('date').pipe(
      map((dateTranslations) =>
        generateBarsForYear(LocalDate.now(), dateTranslations).map((bar) => ({
          ...bar,
          height: '20%',
          isCheapest: false,
          amount: '',
          noFlight: false,
          currencyCode: '',
        }))
      )
    );

    this.range$ = this.isLoading$.pipe(
      switchMap((isLoading) =>
        isLoading
          ? loadingBars$
          : combineLatest([
              this.barData$.pipe(filter((barData) => isNotEmpty(barData))),
              this.minAmount$,
              this.maxAmount$,
              this.isActive$,
              this.adjustHistogramBarHeight$.pipe(startWith(undefined)),
            ]).pipe(
              filter(([, , , isActive]) => isActive),
              map(([barData, minAmount, maxAmount]: [HistogramBar[], number, number, boolean, void]) =>
                barData.map((bar: HistogramBar) => ({
                  ...bar,
                  height: `${this.calculateHeightBar(bar, minAmount, maxAmount)}px`,
                  isCheapest: +bar.amount === minAmount && !bar.noFlight,
                }))
              ),
              finShare()
            )
      )
    );

    this.subscription.add(
      this.mediaQueryService.getResizeEvent$().subscribe(() => {
        this.adjustHistogramBarHeight$.next();
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer) {
      this.scrollContainer.updateScrollHandles();
    }

    this.subscription.add(
      this.selected$.subscribe((value: number) => {
        if (this.scrollContainer) {
          this.scrollContainer.scrollToElement(this.bars.get(value));
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  /**
   * Displays bar center based on container
   * @param index, bar index to display centered
   */
  scrollToBarByIndex(selectedBar: HistogramBar): void {
    this.scrollContainer.scrollToElement(this.bars.get(selectedBar.index));
    this.barSelected.emit(selectedBar);
  }

  private calculateHeightBar(bar: HistogramBar, minAmount: number, maxAmount: number): number {
    const max_column_height = this.histogramContainer?.nativeElement?.offsetHeight - this.HISTOGRAM_TOP_SPACING;
    if (minAmount === maxAmount) {
      return this.MIN_COLUMN_HEIGHT;
    }

    if (+bar.amount === maxAmount) {
      return max_column_height;
    }

    const percentOfAmount = ((+bar.amount - minAmount) / (maxAmount - minAmount)) * 100;
    const extraHeightInPx = ((max_column_height - this.MIN_COLUMN_HEIGHT) * percentOfAmount) / 100;

    return this.MIN_COLUMN_HEIGHT + extraHeightInPx;
  }
}
