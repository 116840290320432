import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CmsCollection, CmsContent, CmsImageData, CmsTemplate, CmsViewType } from '@fcom/core-api';
import { isPresent, safeMap, sanitizeWhiteListedPath } from '@fcom/core/utils';
import { ConfigService, isEmptyObjectOrHasEmptyValues } from '@fcom/core';
import { finShare } from '@fcom/rx';
import { BookingService } from '@fcom/booking/services';
import { globalBookingFlights, LocationPair } from '@fcom/common/store';
import { BookingAppState } from '@fcom/common/interfaces/booking/booking-state.interface';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';
import { AspectRatios } from '@fcom/ui-components';

import { CmsTemplateService } from '../../services';
import { findByType } from '../../utils';

/**
 * Booking entry component for Front page. If you need just a BookingWidget then use it directly.
 * This component contains BookingWidget, Disruptions bar and hero offer.
 */
@Component({
  selector: 'fin-booking-entry',
  styleUrls: ['./booking-entry.component.scss'],
  templateUrl: './booking-entry.component.html',
})
export class BookingEntryComponent implements OnInit {
  disruptions$: Observable<CmsCollection>;
  campaignImg$: Observable<CmsImageData>;
  destinationImg$: Observable<CmsImageData>;
  backgroundImg$: Observable<CmsImageData>;
  sanitizedPath$: Observable<string>;
  aspectRatio$: Observable<keyof typeof AspectRatios>;
  defaultLocations: LocationPair[];

  constructor(
    private bookingService: BookingService,
    private store$: Store<BookingAppState>,
    private cmsTemplateService: CmsTemplateService,
    private configService: ConfigService,
    private mediaQueryService: MediaQueryService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sanitizedPath$ = this.route.url.pipe(
      map(() => this.route.pathFromRoot.map((r) => r.snapshot.url.join('/')).filter(Boolean)),
      map((fragments) => sanitizeWhiteListedPath(`/${fragments.join('/')}`, this.configService.cfg)),
      distinctUntilChanged()
    );
  }

  ngOnInit(): void {
    this.updateDataFromQueryParams();

    this.aspectRatio$ = this.mediaQueryService
      .isBreakpoint$('mobile')
      .pipe(finShare())
      .pipe(
        map((isMobile) => (isMobile ? '21x9' : '16x9') as keyof typeof AspectRatios),
        finShare()
      );

    const contents$: Observable<CmsContent[]> = this.sanitizedPath$.pipe(
      switchMap((path) => this.cmsTemplateService.load(path)),
      map((template: CmsTemplate) => safeMap(template, (t: CmsTemplate) => t.main)),
      filter(isPresent),
      finShare()
    );

    this.disruptions$ = contents$.pipe(findByType(CmsViewType.FRONT_PAGE_DISRUPTIONS, false));

    this.campaignImg$ = contents$.pipe(
      findByType(CmsViewType.FRONT_PAGE_OFFER, false),
      map((campaign: CmsContent) => campaign?.picture),
      finShare()
    );

    const globalBookingFlights$ = this.store$.pipe(globalBookingFlights(), finShare());

    const initialDestinationImg$ = globalBookingFlights$.pipe(
      take(1),
      map((flights) => flights[0].destination?.picture)
    );

    const destinationChanges$ = globalBookingFlights$.pipe(
      distinctUntilChanged((prev, next) =>
        next.every((flights, i) => flights.destination?.locationCode === prev[i]?.destination?.locationCode)
      ),
      pairwise(),
      map(([prev, next]) => {
        const changedDestination = next.find(
          (globalBookingFlight, i) =>
            globalBookingFlight.destination?.locationCode !== prev[i]?.destination?.locationCode
        );

        return changedDestination?.destination?.picture;
      })
    );

    this.destinationImg$ = initialDestinationImg$.pipe(
      switchMap((initial) => destinationChanges$.pipe(startWith(initial)))
    );

    this.backgroundImg$ = combineLatest([this.campaignImg$, this.destinationImg$]).pipe(
      map(([campaignImg, destinationImg]: [CmsImageData, CmsImageData]) => destinationImg ?? campaignImg)
    );
  }

  private updateDataFromQueryParams(): void {
    const queryParams: Params = this.route.snapshot.queryParams;

    if (isEmptyObjectOrHasEmptyValues(queryParams)) {
      return;
    }

    if (isPresent(queryParams.tripType)) {
      this.bookingService.updateTripTypeBasedOnQueryParams(queryParams);
    }

    if (isPresent(queryParams.origin) || isPresent(queryParams.destination)) {
      this.defaultLocations = [
        {
          ...(isPresent(queryParams.origin) && { origin: { locationCode: queryParams.origin } }),
          ...(isPresent(queryParams.destination) && { destination: { locationCode: queryParams.destination } }),
        },
      ] as LocationPair[];
    }

    // After updating we want to clear the queryParams so we do an "in-place" navigation and replace the url with empty
    this.router.navigate([], {
      queryParams: {},
      replaceUrl: true,
    });
  }
}
