import { Injectable, OnDestroy } from '@angular/core';
import { Params, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, pairwise, ReplaySubject, Subscription, filter } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';

import { globalBookingLocations, globalBookingRouteType } from '@fcom/common/store/selectors/global-booking.selectors';
import { GlobalBookingTravelClass, isNotEmpty } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { getBookingStepLink } from '@fcom/common/utils/booking-common.utils';
import {
  LocationPair,
  GlobalBookingActions,
  globalBookingPaxAmount,
  globalBookingSelections,
  GlobalBookingState,
  globalBookingTravelClasses,
  globalBookingTripType,
  globalBookingFlights,
} from '@fcom/common/store';
import { FlightSegment, OfferListFetchParams, Step, TripType } from '@fcom/common/interfaces';
import { GlobalBookingFlight } from '@fcom/common/store/store.interface';
import { PaxAmount } from '@fcom/dapi';
import { LocationsService } from '@fcom/locations';
import { finShare, snapshot } from '@fcom/rx';
import { equals, unique, unsubscribe } from '@fcom/core/utils';
import { Location, LocationRouteType } from '@fcom/core-api/interfaces';
import { AVAILABLE_TRIP_TYPES } from '@fcom/booking-widget/constants';
import { BOOKING_STEPS, BOOKING_STEPS_ARRAY } from '@fcom/common/config/booking-config';
import { BookingQueryParams } from '@fcom/common/interfaces/booking/booking-step.interface';
import { BookingAndAppState, TravelClasses } from '@fcom/common/interfaces/booking';
import { createParamsForStep } from '@fcom/common/utils/create-params-for-step';
import { WidgetTab } from '@fcom/booking-widget/interfaces';
import { BookingWidgetActions } from '@fcom/booking-widget/store/actions';

import { BookingActions, CartActions } from '../store/actions';
import { bookingStep, lastRequestParams, offerLastRequestParams, selectFareFamiliesBrands } from '../store/selectors';

export interface StepChangeSignature {
  onSuccessPost?: (step: Step) => any;

  /**
   * If this is set to true, will not call router.navigate
   */
  skipRouteChange?: boolean;
}

export interface SetStepSignature extends StepChangeSignature {
  step: Step;
  locale?: string;
}

interface TranslationKeyFareFamilyBransDictiounaryItem {
  translationKey: string;
  brandNames: string[];
}

const noop = () => {};

@Injectable()
export class BookingService implements OnDestroy {
  private readonly globalBookingFlights$: Observable<GlobalBookingFlight[]>;
  private readonly step$: Observable<Step>;
  private readonly previousStep$: ReplaySubject<Step> = new ReplaySubject(2);
  private readonly paxAmount$: Observable<PaxAmount>;
  private readonly tripType$: Observable<TripType>;
  private readonly travelClasses$: Observable<TravelClasses>;
  private readonly routeType$: Observable<LocationRouteType>;

  private readonly shortHaulFlightWeights = {
    'fragments.lightTicketWarning': 20,
    'fragments.superLightTicketWarning': 30,
    'fragments.businessLightTicketWarning': 10,
  };

  private readonly longHaulFlightWeights = {
    'fragments.lightTicketWarning': 20,
    'fragments.superLightTicketWarning': 10,
    'fragments.businessLightTicketWarning': 30,
  };

  private readonly superLightFareFamiliesDictionary: TranslationKeyFareFamilyBransDictiounaryItem[] = [
    {
      translationKey: 'fragments.lightTicketWarning',
      brandNames: [
        'Economy Light',
        'Economy Campaign Light',
        'Economy Light Corporate',
        'Premium Economy Light',
        'Premium Economy Light Corporate',
        'Premium Economy Campaign Light',
        'Premium Economy Campaign Light Corporate',
      ],
    },
    {
      translationKey: 'fragments.superLightTicketWarning',
      brandNames: ['Economy Superlight', 'Economy Superlight Campaign', 'Economy Superlight Corporate'],
    },
    {
      translationKey: 'fragments.businessLightTicketWarning',
      brandNames: ['Business Campaign Light', 'Business Light Corporate', 'Business Light'],
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private store$: Store<BookingAndAppState>,
    private languageService: LanguageService,
    private locationsService: LocationsService,
    private router: Router
  ) {
    this.step$ = this.store$.pipe(bookingStep());
    this.step$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        pairwise(),
        map(([previousStep]) => previousStep)
      )
      // eslint-disable-next-line rxjs/no-ignored-subscription
      .subscribe((step) => this.previousStep$.next(step));
    this.paxAmount$ = this.store$.pipe(globalBookingPaxAmount());
    this.tripType$ = this.store$.pipe(globalBookingTripType());
    this.travelClasses$ = this.store$.pipe(globalBookingTravelClasses());
    this.globalBookingFlights$ = this.store$.pipe(globalBookingFlights());
    this.routeType$ = this.store$.pipe(globalBookingRouteType());

    this.fetchLocationInformationBasedOnSelections();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  get step(): Observable<Step> {
    return this.step$;
  }

  get currentStep(): Step {
    return snapshot(this.store$.pipe(bookingStep()));
  }

  get previousStep(): Observable<Step> {
    return this.previousStep$.asObservable();
  }

  get globalBookingSelections(): GlobalBookingState {
    return snapshot(this.store$.pipe(globalBookingSelections()));
  }

  get locations(): Observable<GlobalBookingFlight[]> {
    // TODO: clear (in reducer) and fetch locations if missing, e.g., when changing language
    // see https://github.com/FinnairOyj/finnair-com-ux/issues/2208
    // TODO 2023-10-27: is the above still valid after moving locations from booking to common?
    return this.globalBookingFlights$;
  }

  get paxAmount(): Observable<PaxAmount> {
    return this.paxAmount$;
  }

  get travelClasses(): Observable<TravelClasses> {
    return this.travelClasses$.pipe(finShare());
  }

  get tripType(): Observable<TripType> {
    return this.tripType$.pipe(finShare());
  }

  get offerListParams(): OfferListFetchParams {
    return snapshot(this.store$.pipe(lastRequestParams())) ?? snapshot(this.store$.pipe(offerLastRequestParams()));
  }

  get routeType(): Observable<LocationRouteType> {
    return this.routeType$.pipe(finShare());
  }

  setRouteType(routeType: LocationRouteType): void {
    this.store$.dispatch(GlobalBookingActions.setRouteType({ routeType }));
  }

  setFlights(flights: GlobalBookingState['flights']): void {
    this.store$.dispatch(
      GlobalBookingActions.setFlights({
        flights,
      })
    );
  }

  setFlightsFromLocationsCodes(flightSearch: FlightSegment[]): void {
    this.subscriptions.add(
      this.getLocationsFromFlights(flightSearch).subscribe((locations) => {
        this.setFlights(
          flightSearch.map((flight) => ({
            origin: locations[flight.origin],
            destination: locations[flight.destination],
            departureDate: flight.departureDate,
          }))
        );
      })
    );
  }

  getLocationsFromFlights(flightSearch: FlightSegment[]): Observable<{ [locationCode: string]: Location }> {
    const uniqueListOfLocations = flightSearch
      .reduce((arr, flight) => {
        if (!arr.includes(flight.origin)) {
          arr.push(flight.origin);
        }

        if (!arr.includes(flight.destination)) {
          arr.push(flight.destination);
        }

        return arr;
      }, [])
      .filter(Boolean);

    return this.locationsService.triggerLocationFetchAll(uniqueListOfLocations).pipe(take(1));
  }

  setStep({ step, onSuccessPost = noop, skipRouteChange = false, locale }: SetStepSignature): void {
    this.goToStep({ step, skipRouteChange, onSuccessPost, locale });
  }

  prevStep(): void {
    const nextStep = this.currentStepIndex - 1;

    if (nextStep >= 0 && this.currentStepIndex !== this.getStepIndex(BOOKING_STEPS.PURCHASE_SUCCESS)) {
      this.setStep({
        step: BOOKING_STEPS_ARRAY[nextStep],
      });
    }
  }

  nextStep(): void {
    const nextStep = this.currentStepIndex + 1;

    if (nextStep < BOOKING_STEPS_ARRAY.length) {
      this.setStep({
        step: BOOKING_STEPS_ARRAY[nextStep],
      });
    }
  }

  isFirstStep(): Observable<boolean> {
    return this.step.pipe(map((s) => s.id === BOOKING_STEPS.ENTRY.id));
  }

  setPaxAmount(paxAmount: PaxAmount): void {
    if (!equals(paxAmount, snapshot(this.store$.pipe(globalBookingPaxAmount())))) {
      this.store$.dispatch(GlobalBookingActions.setPaxAmount({ paxAmount }));
    }
  }

  setActiveTab(tab: WidgetTab): void {
    this.store$.dispatch(BookingWidgetActions.setActiveTab({ activeTab: tab }));
  }

  increasePaxAmountField(field: string, value: number): void {
    this.store$.dispatch(GlobalBookingActions.increasePaxAmountField({ field, increment: value }));
  }

  decreasePaxAmountField(field: string, value: number): void {
    this.store$.dispatch(GlobalBookingActions.decreasePaxAmountField({ field, decrement: value }));
  }

  setTripType(tripType: TripType): void {
    if (tripType !== snapshot(this.store$.pipe(globalBookingTripType()))) {
      this.store$.dispatch(GlobalBookingActions.setTripType({ tripType }));
    }
  }

  selectTravelClass(travelClass: GlobalBookingTravelClass): void {
    this.store$.dispatch(GlobalBookingActions.setTravelClass({ travelClass }));
  }

  updateTripTypeBasedOnQueryParams(queryParams: Params): void {
    const tripType = queryParams[BookingQueryParams?.tripType];

    if (tripType && AVAILABLE_TRIP_TYPES.includes(tripType)) {
      this.setTripType(tripType);
    }
  }

  getContentUrlTranslationKeySuperLightModalWindow(isShortHaulFlight: boolean): string {
    const selectedFareFamiliesBrands = snapshot(this.store$.pipe(selectFareFamiliesBrands()));

    return this.superLightFareFamiliesDictionary
      .filter((dictionary) => {
        if (selectedFareFamiliesBrands.filter((brand) => dictionary.brandNames.includes(brand)).length > 0) {
          return dictionary;
        }
      })
      .reduce((prev, current) => {
        const weights = isShortHaulFlight ? this.shortHaulFlightWeights : this.longHaulFlightWeights;
        return weights[prev.translationKey] > weights[current.translationKey] ? prev : current;
      }, {} as TranslationKeyFareFamilyBransDictiounaryItem)?.translationKey;
  }

  private get currentStepIndex(): number {
    return this.getStepIndex(this.currentStep);
  }

  private goToStep({ step, onSuccessPost = noop, skipRouteChange = false, locale }: SetStepSignature): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Clear cart if returning to flight selection or earlier from a later phase
      if (
        step.phase <= BOOKING_STEPS.FLIGHT_SELECTION.phase &&
        this.currentStep &&
        this.currentStep.phase > step.phase
      ) {
        this.store$.dispatch(CartActions.reset());
      }

      if (!skipRouteChange) {
        this.router
          .navigateByUrl(
            getBookingStepLink(
              locale || this.languageService.langValue,
              step,
              createParamsForStep(step, this.globalBookingSelections, this.offerListParams)
            )
          )
          .then((b) => {
            if (b) {
              this.store$.dispatch(BookingActions.setStep({ step }));
              resolve();
            } else {
              reject();
            }
          })
          .catch(reject);
      } else {
        this.store$.dispatch(BookingActions.setStep({ step }));
        resolve();
      }
    })
      .then(() => onSuccessPost(step))
      .catch((_error) => {});
  }

  private getStepIndex(step: Step): number {
    return BOOKING_STEPS_ARRAY.findIndex((s) => s.id === step.id);
  }

  private fetchLocationInformationBasedOnSelections() {
    this.subscriptions.add(
      this.store$
        .pipe(
          globalBookingLocations(),
          filter((locations: LocationPair[]) => !!locations?.length),
          distinctUntilChanged((prev, next) =>
            next.every(
              (loc, i) =>
                loc?.origin?.locationCode === prev?.[i]?.origin?.locationCode &&
                loc?.destination?.locationCode === prev?.[i]?.destination?.locationCode
            )
          ),
          map((locations) =>
            locations
              .flatMap((location) => [location.origin?.locationCode, location.destination?.locationCode])
              .filter(unique)
              .filter(Boolean)
          ),
          filter(isNotEmpty)
        )
        .subscribe((locationCodes: string[]) => {
          locationCodes.forEach((code) => {
            this.locationsService.triggerLocationFetch(code);
          });
        })
    );
  }
}
