import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { EMPTY, NEVER, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { RootPaths, SentryLogger, WindowRef } from '@fcom/core';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { mapError, mapErrorForSentry, isPresent } from '@fcom/core/utils';
import { OrderService } from '@fcom/dapi/api/services';
import { DapiHttpResponse } from '@fcom/dapi/api/dapi-http-response';
import { IdAndHash } from '@fcom/dapi/interfaces';
import { BookingAndAppState } from '@fcom/common/interfaces/booking';
import { cartIdAndHash } from '@fcom/booking/store/selectors/cart.selector';
import { finShare } from '@fcom/rx';

import { mapDeviceForPayment } from '../utils/payment.utils';
import { PaymentActions } from '../store/actions';
import { orderFetchInformation } from '../store/selectors/order.selector';
import { QueueService } from './queue.service';
import { PaymentFlow, PaymentServiceName, PaymentStatus } from '../interfaces';

@Injectable()
export class PaymentService {
  static ROOT_PATHS = {
    [PaymentFlow.BOOKING]: RootPaths.BOOKING_ROOT,
    [PaymentFlow.MANAGE_BOOKING]: RootPaths.MANAGE_BOOKING_ROOT,
    [PaymentFlow.VOLUNTARY_CHANGE]: RootPaths.VOLUNTARY_CHANGE_ROOT,
    [PaymentFlow.CHECK_IN_FUNNEL]: RootPaths.MANAGE_BOOKING_ROOT + '/check-in/step',
  };

  constructor(
    private configService: ConfigService,
    private store$: Store<BookingAndAppState>,
    private sentryLogger: SentryLogger,
    private windowRef: WindowRef,
    private orderService: OrderService,
    private queueService: QueueService,
    private languageService: LanguageService
  ) {}

  queuePaymentInit(serviceName: PaymentServiceName, paymentFlow: PaymentFlow): void {
    this.queueService.cancelServiceAvailability$.next();

    const cancelStream$ = this.queueService.toCancelEvent(this.queueService.cancelPayment);
    this.queueService.sendToQueue(
      'paymentInit',
      this.fromOrderCart((idAndHash: IdAndHash) =>
        this.triggerPaymentInit(
          idAndHash.id,
          idAndHash.hash,
          this.languageService.localeValue,
          this.languageService.langValue,
          cancelStream$,
          serviceName,
          paymentFlow,
          idAndHash.orderChangeId
        )
      ),
      cancelStream$
    );
  }

  /**
   * Triggers payment init. Fetches payment tokenizer url and paymentid
   *
   * @param cartOrOrderId the id of the cart or order
   * @param hash the hash signature of the cart or order
   * @param locale the locale we want the tokenizer form, example: fi_FI
   * @param lang the language to use in urls
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   * @param service differentiate which flow was used to trigger the payment
   * @param paymentFlow in which workflow is the payment happening
   * @param orderChangeId the order change id gotten from voluntary change flow
   */
  public triggerPaymentInit(
    cartOrOrderId: string,
    hash: string,
    locale: string,
    lang: string,
    cancelStream$: Observable<any> = NEVER,
    service: PaymentServiceName,
    paymentFlow: PaymentFlow,
    orderChangeId?: string
  ): Observable<null> {
    const cartOrOrderIdParam = cartOrOrderId.length === 6 ? 'orderId' : 'cartId';
    const paymentInitData$ = this.orderService
      .initializeCheckoutV2$Response(this.configService.cfg.orderUrl, {
        hash,
        [cartOrOrderIdParam]: cartOrOrderId,
        ...(orderChangeId && { orderChangeId }),
        locale,
        device: mapDeviceForPayment(this.windowRef.nativeWindow.screen.width),
        service,
        ...this.getUrls(paymentFlow, lang),
      })
      .pipe(
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        catchError((errorResponse: HttpErrorResponse) => {
          this.sentryLogger.error('Error initializing payment', {
            error: mapErrorForSentry(mapError(errorResponse)),
          });
          this.store$.dispatch(PaymentActions.setPaymentStatus({ status: PaymentStatus.TECHNICAL_ERROR }));
          return EMPTY;
        }),
        finShare(),
        takeUntil(cancelStream$)
      );

    paymentInitData$.pipe(take(1)).subscribe({
      next: (response) => {
        this.store$.dispatch(PaymentActions.setPaymentRedirectionUrl({ url: response.headers.get('Location') }));
        // This has to be set to something different than Initialize because of the success/fail redirection, and the root.reducer
        this.store$.dispatch(PaymentActions.setPaymentStatus({ status: PaymentStatus.PENDING_VERIFICATION }));
      },
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      error: (error: Error) => {
        this.sentryLogger.error('Error in payment init interaction', {
          error: mapErrorForSentry(error),
        });
        this.store$.dispatch(PaymentActions.setPaymentStatus({ status: PaymentStatus.TECHNICAL_ERROR }));
      },
    });

    return paymentInitData$.pipe(map((response: DapiHttpResponse<null>) => response.body));
  }

  private getUrls(paymentFlow: PaymentFlow, lang: string): { successUrl: string; failureUrl: string } {
    const urlPrefix = `${document.location.origin}/${lang}`;
    const flowRootPath = PaymentService.ROOT_PATHS[paymentFlow];
    if (paymentFlow === PaymentFlow.CHECK_IN_FUNNEL) {
      return {
        successUrl: `${urlPrefix}/${flowRootPath}/rules/payment/success`,
        failureUrl: `${urlPrefix}/${flowRootPath}/know-your-trip/payment/error`,
      };
    }
    return {
      successUrl: `${urlPrefix}/${flowRootPath}/confirmation`,
      failureUrl: `${urlPrefix}/${flowRootPath}/payment/error`,
    };
  }

  private fromOrderCart = (fn: (idAndHash: IdAndHash) => Observable<any>) => {
    const orderIdAndHash$ = this.store$.pipe(orderFetchInformation(), finShare());

    const cartInfo$ = this.store$.pipe(cartIdAndHash(), take(1));

    return orderIdAndHash$.pipe(
      switchMap((idAndHash) => {
        return isPresent(idAndHash) ? of(idAndHash) : cartInfo$;
      }),
      switchMap((a) => fn(a))
    );
  };
}
