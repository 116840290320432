import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsTeaser } from '@fcom/core-api';
import { isNotEmpty, safeMap } from '@fcom/core/utils';
import { QuickLink } from '@fcom/ui-components';
import { GtmService } from '@fcom/common/gtm';
import { ElementTypes } from '@fcom/common';

@Component({
  selector: 'cms-quick-links',
  templateUrl: './cms-quick-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsQuickLinksComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection>;

  links$: Observable<QuickLink[]>;

  constructor(private gtmService: GtmService) {}

  ngOnInit(): void {
    this.links$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items)),
      map((items: CmsTeaser[]) =>
        items.map((item) => ({
          title: item.teaserTitle,
          iconName: item.localSettings?.iconName,
          url: item.target?.url,
        }))
      ),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }

  clickLink(link: QuickLink): void {
    this.gtmService.trackElement(`quick-links: ${link.title}`, 'front-page', ElementTypes.LINK, link.url, 'click');
  }
}
