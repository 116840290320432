import { NO_VARIANTS, ServiceCatalog, ServiceCatalogService, ServiceSelections, TravelerService } from '@fcom/dapi';
import {
  Category,
  FinnairPassengerItem,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceCategoryPriceItem,
  FinnairTotalPricesSplit,
} from '@fcom/dapi/api/models';
import { asCartId, getIconForServiceCategory } from '@fcom/dapi/utils';
import { entrySet, isEmptyObjectOrHasEmptyValues, isPresent, mapValues } from '@fcom/core/utils';
import { ServiceCatalogServiceWithSelection } from '@fcom/booking/modules/pax-details/interfaces/pax-ancillaries.interface';

import { BookingSummaryAncillaryKey, serviceMapping } from '../../modules/ancillaries/utils';
import { BookingSummaryService } from '../../interfaces';

/**
 * @param service
 * @returns
 */
export const loadBoundSelection = (service: FinnairPassengerServiceItem): TravelerService[] => {
  return service.services
    .map((service) => asTravelerService(service))
    .reduce((totalTravelerServices, travelerService) => {
      const existingService = totalTravelerServices.find(
        (s) => s.category === travelerService.category && s.variant === travelerService.variant
      );
      if (existingService) {
        existingService.quantity += travelerService.quantity;
      } else {
        totalTravelerServices.push(travelerService);
      }

      return totalTravelerServices;
    }, [] as TravelerService[]);
};

const isServiceCatalogService = (
  service: FinnairPassengerServiceSelectionItem | ServiceCatalogService
): service is ServiceCatalogService => isPresent((service as ServiceCatalogService).isService);

export const asTravelerService = (
  service: FinnairPassengerServiceSelectionItem | ServiceCatalogService,
  quantity = 1
): TravelerService => {
  if (isServiceCatalogService(service)) {
    return {
      category: service.subCategory,
      variant: service.variant,
      quantity,
      displayName: service.translations?.title,
      pricePerItem: service.totalPrice,
      includedInTicketType: false,
      includedInTierBenefit: false,
    };
  }

  return {
    category: service.subCategory,
    variant: service.variant,
    displayName: service.displayName,
    quantity: service.quantity,
    pricePerItem: service.unitPrice,
    includedInTicketType: service.includedInTicketType === true,
    includedInTierBenefit: service.includedInTierBenefit === true,
  };
};

const mapPassengersToAncillaries = (
  service: FinnairServiceCategoryPriceItem,
  passengers: Array<FinnairPassengerItem>,
  bookingSummaryAncillaryKey: BookingSummaryAncillaryKey
) => {
  return entrySet(service.totalPerPax).map((passenger) => {
    const pax = passengers.find((p) => p.id === passenger.key);
    const associatedInfant = passengers.find((p) => p.accompanyingTravelerId === pax.id);
    return {
      firstName: pax.firstName,
      lastName: pax.lastName,
      associatedInfantName: associatedInfant ? `${associatedInfant.firstName} ${associatedInfant.lastName}` : undefined,
      key: bookingSummaryAncillaryKey,
      quantity: passenger.value.quantity,
      price: passenger.value.price.totalAmount,
    };
  });
};

export const mapServicesToBookingSummary = (
  services: FinnairTotalPricesSplit,
  passengers: Array<FinnairPassengerItem>
): BookingSummaryService[] => {
  return (
    services?.totalPerCategory.map((service) => {
      const mapping = serviceMapping.find((s) => s.category === service.category) ?? {
        category: service.category,
        icon: getIconForServiceCategory(service.category),
        bookingSummaryKey: `purchase.payment.${service.category}Count` as BookingSummaryAncillaryKey,
      };
      return {
        icon: mapping.icon,
        code: service.category !== Category.BAGGAGE ? asCartId(service.category) : 'bags',
        isGroupService: service.category === Category.COVER,
        passengers: mapPassengersToAncillaries(service, passengers, mapping.bookingSummaryKey),
      };
    }) ?? []
  );
};

const mapSelectionStatusToService = (
  service: ServiceCatalogService,
  selection: TravelerService | Record<string, never>
): ServiceCatalogServiceWithSelection => {
  if (!isPresent(selection) || isEmptyObjectOrHasEmptyValues(selection)) {
    const isNoVariant = NO_VARIANTS.includes(service.variant);
    return { ...service, selected: isNoVariant, selectedQuantity: isNoVariant ? 1 : 0 };
  }

  return {
    ...service,
    selected: service.variant === selection.variant,
    selectedQuantity: service.variant === selection.variant ? selection.quantity : 0,
  };
};

export const mapSelectionStatusToServices = (
  services: ServiceCatalogService[],
  selection: TravelerService | TravelerService[] | Record<string, never>
): ServiceCatalogServiceWithSelection[] =>
  services?.map((service) => {
    const userSelection = Array.isArray(selection) ? selection?.find((s) => s.variant === service.variant) : selection;
    return mapSelectionStatusToService(service, userSelection);
  });

const mapServicePriceToSelection = (
  services: ServiceCatalogService[] = [],
  selection: TravelerService
): TravelerService => {
  if (!isPresent(selection)) {
    return selection;
  }

  const matchingService = services.find((s: ServiceCatalogService) => s.variant === selection.variant);

  return {
    ...selection,
    pricePerItem: matchingService?.totalPrice ?? selection.pricePerItem,
  };
};

/**
 * Updates all selections prices to match given serviceCatalog service prices
 * This can be used for example when we set the serviceCatalog and want to update the current selection state as well
 * in case of a mismatch e.g. prices are different in new catalog
 * @param selections - selections to match
 * @param serviceCatalog - service catalog to use for mapping
 * @returns new service selections mapped with given serviceCatalog data
 */
export const updateSelectionPricesFromServiceCatalog = (
  selections: ServiceSelections,
  serviceCatalog: ServiceCatalog
): ServiceSelections => {
  return mapValues(selections, (selectionsForCategory, categoryName: Category) => {
    const servicesForCategory = serviceCatalog?.categories?.find((c) => c.category === categoryName)?.services;
    return mapValues(selectionsForCategory, (selectionsForFragment, fragmentId: string) => {
      return mapValues(selectionsForFragment, (selectionsForTraveler, travelerId: string) => {
        const servicesForTraveler = servicesForCategory?.[fragmentId]?.[travelerId];

        if (Array.isArray(selectionsForTraveler)) {
          return selectionsForTraveler.map((selection) => mapServicePriceToSelection(servicesForTraveler, selection));
        }

        return mapServicePriceToSelection(servicesForTraveler, selectionsForTraveler);
      });
    });
  });
};
