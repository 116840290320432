import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { PopoverOptions, ButtonSize, PopoverPosition } from '@fcom/ui-components';
import { AppState, ConfigService, StorageService } from '@fcom/core';
import { LoginStatus, LoginStep } from '@fcom/core-api/login';
import { loginStatus } from '@fcom/core/selectors';
import { PersonalizationTrackingService } from '@fcom/loyalty-personalization/services/personalization-tracking.service';
import { finShare } from '@fcom/rx';
import { LoginActions } from '@fcom/core/actions';

import { GtmService } from '../../gtm';
import { ElementActions, ElementTypes, GaContext } from '../../interfaces';
import { WelcomeBackMessageService, WELCOME_BACK_MESSAGE_CLOSED_KEY } from '../../services';
import { JoinService, LoginService } from '../../login/services';

enum MessageType {
  FPLUS_MEMBER = 'fplus-member',
  ANONYMOUS = 'anonymous',
}

const TRACKING_ELEMENT_NAME = 'welcome-back-message';

@Component({
  selector: 'fin-welcome-back-message',
  templateUrl: './welcome-back-message.component.html',
})
export class WelcomeBackMessageComponent implements OnInit {
  readonly MessageType = MessageType;

  readonly ButtonSize = ButtonSize;

  popoverOptions: PopoverOptions = {
    popoverID: 'welcome-back-message',
    openByDefault: true,
    showCloseButton: true,
    closeOnScroll: false,
    showArrowCaret: false,
    showLeftBorder: false,
    popoverPosition: PopoverPosition.BOTTOM,
    roundedCorners: true,
    useFixedPosition: true,
  };

  messageType$: Observable<MessageType>;

  constructor(
    private store$: Store<AppState>,
    private storageService: StorageService,
    private loginService: LoginService,
    private gtmService: GtmService,
    public joinService: JoinService,
    private personalizationTrackingService: PersonalizationTrackingService,
    private configService: ConfigService,
    private welcomeBackMessageService: WelcomeBackMessageService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    if (this.storageService.SESSION.get(WELCOME_BACK_MESSAGE_CLOSED_KEY) || isPlatformServer(this.platform)) {
      this.messageType$ = EMPTY;
    } else {
      this.messageType$ = this.store$.pipe(
        loginStatus(),
        filter((status) => status === LoginStatus.NOT_LOGGED_IN),
        tap(() => this.trackGtm('view', ElementActions.VIEW)),
        switchMap(() =>
          this.personalizationTrackingService
            .isNotLoggedInFPlusMember()
            .pipe(
              map((isNotLoggedInFPlusMember) =>
                isNotLoggedInFPlusMember ? MessageType.FPLUS_MEMBER : MessageType.ANONYMOUS
              )
            )
        ),
        finShare()
      );
    }
  }

  onLoginButtonClick(): void {
    this.trackGtm('login', ElementActions.CLICK);
    if (this.configService.cfg.enableLoginDialog) {
      this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
    } else {
      this.loginService.redirectToLogin();
    }
  }

  onJoinButtonClick(): void {
    this.trackGtm('join', ElementActions.CLICK);
    if (this.configService.cfg.enableJoinDialog) {
      this.joinService.joinDialogOpen$.next(true);
    } else {
      this.loginService.redirectToJoin();
    }
  }

  onCloseClick(): void {
    this.trackGtm('close', ElementActions.CLOSE);
    this.welcomeBackMessageService.close();
  }

  private trackGtm(eventName: string, action: ElementActions): void {
    this.gtmService.trackElement(TRACKING_ELEMENT_NAME, GaContext.FINNAIR_PLUS, ElementTypes.BANNER, eventName, action);
  }
}
