import { Component, Input, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ConfigService } from '@fcom/core/services';
import {
  FinnairCabinClass,
  FinnairItineraryItemFlight,
  FinnairItineraryItemType,
  FinnairOrder,
} from '@fcom/dapi/api/models';
import { LanguageService } from '@fcom/ui-translate';
import { loginStatus } from '@fcom/core/selectors';
import { AspectRatioString } from '@fcom/common/interfaces';
import { LoginStatus } from '@fcom/core-api/login';
import { isPresent } from '@fcom/core/utils';
import { BookingAppState } from '@fcom/common/interfaces/booking';

@Component({
  selector: 'fin-promotion-banners',
  templateUrl: './promotion-banners.component.html',
  styleUrls: ['./promotion-banners.component.scss'],
})
export class PromotionBannersComponent implements OnInit {
  readonly LoginStatus = LoginStatus;

  @Input()
  orderData$?: Observable<FinnairOrder> = of(null);

  aspectRatio$: Observable<AspectRatioString>;

  loggedIn$: Observable<LoginStatus>;

  chooseUrl$: Observable<string>;
  finnairShopDonateUrl: string;

  cabinClassMap = {
    [FinnairCabinClass.ECONOMY]: 'economy',
    [FinnairCabinClass.ECOPREMIUM]: 'premiumEconomy',
    [FinnairCabinClass.BUSINESS]: 'business',
    [FinnairCabinClass.FIRST]: 'first',
  };

  constructor(
    private configService: ConfigService,
    private languageService: LanguageService,
    private store$: Store<BookingAppState>
  ) {}

  ngOnInit(): void {
    this.loggedIn$ = this.store$.pipe(loginStatus());

    let lang = this.languageService.langKeyValue?.toLowerCase();

    // Only EN FI SV languages are supported by Chooose.
    if (!['en', 'fi', 'sv'].includes(lang)) {
      lang = 'en';
    }

    this.finnairShopDonateUrl = this.configService.cfg.finnairShopDonateUrl.replace('{lang}', lang);

    this.chooseUrl$ = this.orderData$.pipe(
      map((orderData) => {
        if (!isPresent(orderData)) {
          return this.configService.cfg.choooseUrl.replace('{lang}', lang);
        }

        const items = orderData.bounds
          .flatMap((bound) => bound.itinerary)
          .filter((itinerary) => itinerary.type === FinnairItineraryItemType.FLIGHT)
          .reduce((acc: string, itinerary: FinnairItineraryItemFlight, index: number) => {
            const itemParam = `&items[${index}]`;
            return `${acc}
            ${itemParam}[type]=flights.fromTo
            ${itemParam}[from]=${itinerary.departure.locationCode}
            ${itemParam}[to]=${itinerary.arrival.locationCode}
            ${itemParam}[travellers]=${orderData.passengers.length}
            ${itemParam}[travelClass]=${this.cabinClassMap[itinerary.cabinClass]}
            ${itemParam}[roundTrip]=false`;
          }, '')
          .replace(/\s/g, '');
        const htmlElementId = '2d8771d7-f9e7-4ce2-a5d7-caf3276d9b79';

        return `${this.configService.cfg.choooseUrl}?page=cart${items}&orderId=${orderData.id}#scrollTo=${htmlElementId}`.replace(
          '{lang}',
          lang
        );
      })
    );
  }
}
