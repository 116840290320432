import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { map, Observable, Subscription, take } from 'rxjs';

import { TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { TagRoundingStyle, TagTheme } from '@fcom/ui-components';
import { ConfigService, unsubscribe } from '@fcom/core/index';
import { MediaQueryService } from '@fcom/common/services';

import { shouldDisplayBenefit } from '../../../utils/benefit.utils';
import { ExtendedFareFamily, FareFamilyBadgeType } from '../../../interfaces';

@Component({
  selector: 'fin-fare-family-list-option',
  styleUrls: ['./fare-family-list-option.component.scss'],
  templateUrl: './fare-family-list-option.component.html',
})
export class FlightSelectionFareFamilyListOptionComponent implements OnInit, OnDestroy {
  readonly FareFamilyBadgeType = FareFamilyBadgeType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagRoundingStyle = TagRoundingStyle;
  readonly TagTheme = TagTheme;

  @Input({ required: true }) fareFamily$: Observable<ExtendedFareFamily>;
  @Input({ required: true }) currencyCode: string;
  @Input({ required: true }) aria: {
    index: number;
    count: number;
  };

  @Output() selectFareFamily = new EventEmitter<boolean>();

  subscriptions = new Subscription();
  fareFamilyBenefit$: Observable<TranslatedBenefitRow[]>;
  isNotAward$: Observable<boolean>;

  constructor(
    public configService: ConfigService,
    public mediaQueryService: MediaQueryService
  ) {}

  ngOnInit(): void {
    this.isNotAward$ = this.fareFamily$.pipe(
      map((fareFamily) => {
        const isAward = Boolean(fareFamily.points);
        return !isAward;
      })
    );

    this.fareFamilyBenefit$ = this.fareFamily$.pipe(
      map((fareFamily) => {
        return fareFamily.benefits.translatedBenefitRows.filter((benefit) => shouldDisplayBenefit(benefit));
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  checkboxChange(event: Event): void {
    this.selectFareFamily.emit((event.target as HTMLInputElement).checked);

    this.subscriptions.add(
      this.mediaQueryService
        .isBreakpoint$('tablet_down')
        .pipe(take(1))
        .subscribe((isTabletDown) => {
          if (!isTabletDown) {
            return;
          }

          (event.target as HTMLInputElement).closest('.fare-family').scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'center',
          });
        })
    );
  }
}
