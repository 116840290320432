<ng-container *ngIf="tripType$ | async as tripType">
  <ng-container *ngIf="priceContext$ | async as priceContext">
    <section
      *ngIf="blockOffer$ | async as offer"
      class="relative block-offer shadow-small fill grow flex flex--column no-underline nordic-blue-25-bg rounded-large overflow-hidden"
      (click)="emitClick(priceContext.price?.travelClass, tripType); navigateToUrl(offer.link)"
      [class.inner-hover]="innerLinkHover"
      data-testid="offer-tile-destination-link"
      tabindex="0"
    >
      <div class="offer-area white-bg grow">
        @if (enableCalendar && calendarButtonInHeader) {
          <section
            class="destination-image relative"
            *ngIf="aspectRatio$ | async"
            (click)="emitCalendarClick($event, priceContext.price?.travelClass, tripType)"
          >
            <fin-content-image
              [aspectRatio$]="aspectRatio$"
              [imageData$]="offer.imageData | finToObservable"
              [maxImageWidth]="MaxImageWidth"
              [fetchPriority]="fetchImagePriority"
            >
            </fin-content-image>
            <fcom-button
              class="absolute calendar-button"
              [text]="'destinationSearch.calendarButton' | finLocalization"
              [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
              (clickBtn)="emitCalendarClick($event, priceContext.price?.travelClass, tripType)"
              data-testid="block-offer-header-calendar-button"
            ></fcom-button>
          </section>
        } @else {
          <section class="destination-image relative" *ngIf="aspectRatio$ | async">
            <fin-content-image
              [aspectRatio$]="aspectRatio$"
              [imageData$]="offer.imageData | finToObservable"
              [maxImageWidth]="MaxImageWidth"
              [fetchPriority]="fetchImagePriority"
            >
            </fin-content-image>
          </section>
        }

        <div
          *ngIf="badgeLabel$ | async as badgeLabel"
          data-testid="offer-tile-badge-label"
          class="absolute offer-badge rounded-top-left-medium rounded-bottom-left-medium"
        >
          <div
            class="offer-badge-content font-body-2 medium-type white-text ps-xxsmall-y ps-xsmall-x relative"
            [class.black-bg]="offer.enableBlackTeaser"
            [class.heather-700-bg]="!offer.enableBlackTeaser"
          >
            <div class="flex flex--row flex--top">
              <div class="flex-1">{{ 'destinationSearch.' + badgeLabel | finLocalization }}</div>
              <div class="offer-badge-icon flex--nogrow" *ngIf="offer.badgeIcon">
                <fcom-icon class="white-text icon-small" [name]="offer.badgeIcon"></fcom-icon>
              </div>
            </div>
          </div>
        </div>

        <section class="fill ps-medium destination-text-content">
          <a
            *ngIf="offer.title"
            class="no-decoration"
            [finLink]="offer.link"
            (beforeNavigation)="emitClick(priceContext.price?.travelClass, tripType)"
            data-testid="offer-tile-price-link"
          >
            <h3 class="font-heading-5 nordic-blue-900-text mr-clear-y">{{ offer.title }}</h3>
            <ng-container [ngTemplateOutlet]="price" [ngTemplateOutletContext]="priceContext"></ng-container>
          </a>
          @if (enableCalendar && calendarButtonInHeader === false) {
            <div class="ps-xsmall-y grow fill">
              <fcom-button
                [text]="'destinationSearch.calendarButton' | finLocalization"
                [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
                [mode]="ButtonMode.FILL"
                [size]="ButtonSize.LARGE"
                (clickBtn)="emitCalendarClick($event, priceContext.price?.travelClass, tripType)"
                data-testid="block-offer-calendar-button"
              ></fcom-button>
            </div>
          }
        </section>
      </div>
      <ng-container *ngIf="offer?.amDestination && flightHotelEnabled">
        <a
          (click)="openAmContinueModalClick(offer?.amDestination.bookUrl, offer?.title); $event.stopPropagation()"
          (mouseenter)="innerLinkHover = true"
          (mouseleave)="innerLinkHover = false"
          class="block flight-hotel no-underline ps-small"
          data-testid="offer-tile-flight-hotel-price-link"
        >
          <span class="flex flex--space-between flex--wrap flex--center">
            <span class="nordic-blue-900-text font-body-1 medium-type underline flex-1 ps-xsmall-r">
              {{ 'destinationSearch.flightsAndHotel' | finLocalization }}</span
            >
            <span class="nordic-blue-900-text font-body-1">
              <span>
                {{
                  'destinationSearch.flightAndHotelDuration' | finLocalization: { days: offer?.amDestination.duration }
                }}</span
              >
              <span class="ps-xsmall-l medium-type" data-testid="offer-tile-price-value">{{
                offer?.amDestination.price | finPrice: { roundPrice: true } | finEmpty
              }}</span>
            </span>
          </span>
        </a>
      </ng-container>

      <ng-template #price let-price="price">
        <ng-container *ngIf="price && price.price; else noPrice">
          <div
            class="nordic-blue-900-text mr-clear-t font-body-1 ps-xxsmall-y"
            data-testid="offer-tile-starting-from-label"
          >
            @if ((showRoundTripPrice$ | async) === true) {
              {{ 'productCard.startingFromTitle.return' | finLocalization }}
            } @else {
              {{ 'productCard.startingFromTitle.' + CmsTripTypeMap[tripType] | finLocalization }}
            }
          </div>
          <span
            class="nordic-blue-900-text font-heading-2"
            [class.heather-700-text]="isHighlightPrice$ | async"
            data-testid="offer-tile-price-value"
            >{{ price.price | finPrice: { roundPrice: true } | finEmpty }}</span
          >
        </ng-container>
      </ng-template>

      <ng-template #noPrice>
        <div class="flex flex--middle mr-clear-t ps-small-y">
          <span class="nordic-blue-900-text font-body-1 ms-xxsmall-r" data-testid="offer-tile-no-price-label">{{
            'productCard.noPriceText' | finLocalization
          }}</span>
          <fcom-icon class="icon-small nordic-blue-900-text" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </div>
      </ng-template>
    </section>
  </ng-container>
</ng-container>
