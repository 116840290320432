<div class="booking-entry relative">
  <cms-disruptions [disruptions]="disruptions$ | async"></cms-disruptions>

  <div class="relative bg-container">
    <fin-content-image
      class="background-image"
      [imageData$]="backgroundImg$"
      [aspectRatio$]="aspectRatio$"
      [fetchPriority]="'high'"
    ></fin-content-image>
    <fin-booking-widget
      [compactMode]="true"
      [defaultLocations$]="defaultLocations | finToObservable"
    ></fin-booking-widget>
  </div>
</div>
