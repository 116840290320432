import { ViewportScroller } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, of, EMPTY, Subscription, delay } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils';
import { CmsContentType, CmsFormType, CmsTemplate, CmsViewType, CmsContent, CmsCollection } from '@fcom/core-api';
import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';
import { finShare } from '@fcom/rx';
import { MultivariateTestId, TestVariant } from '@fcom/common';
import { ConfigService } from '@fcom/core';

import { findByType } from '../../../utils';

@Component({
  selector: 'fin-cms-json-content',
  templateUrl: './cms-json-content.component.html',
  styleUrls: ['./cms-json-content.component.scss', './premium-economy-brand-overrides.scss'],
})
export class CmsJsonContentComponent implements OnDestroy, OnInit {
  @Input()
  template$: Observable<CmsTemplate> = EMPTY;
  @Input()
  pageViewType$: Observable<string>;
  @Input()
  showSideTitle$: Observable<boolean> = of(true);

  disruptions$: Observable<CmsCollection>;
  title$: Observable<string>;
  isQuickLinksTestVariant$: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;

  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private multivariateTestService: MultivariateTestService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.isQuickLinksTestVariant$ = this.configService.cfg.enableQuickLinks
      ? this.multivariateTestService.getTestVariant(MultivariateTestId.QUICK_LINKS).pipe(
          map((variant) => variant.variant === TestVariant.B),
          finShare()
        )
      : of(false);

    this.disruptions$ = this.template$.pipe(
      map((template) =>
        template.main.reduce((acc, curr) => {
          if (curr.contentType === CmsContentType.CMPersonalized) {
            return [...acc, ...curr.items];
          }
          return [...acc, curr];
        }, [])
      ),
      findByType(CmsViewType.DISRUPTIONS)
    );
    this.title$ = this.template$.pipe(
      pluck('header', 0),
      map((item) => (item as CmsContent)?.teaserTitle || item?.title)
    );
    this.subscription.add(
      this.route.fragment.pipe(filter(Boolean), delay(0)).subscribe((fragment) => {
        this.viewportScroller.scrollToAnchor(fragment);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}
