import { DateFormat, LocalDate } from '@fcom/core/utils';

import { HistogramBarBase } from '../interfaces';

export const generateBarsForYear = (date: LocalDate, dateTranslations: Record<string, unknown>): HistogramBarBase[] => {
  return Array.from({ length: 13 }, (_, i) => {
    const current = date.firstDayOfMonth.plusMonths(i);
    const monthName = new DateFormat(dateTranslations).format(current, DateFormat.MONTH_SHORT);
    return {
      index: i,
      text: monthName,
      ...(date.lt(current) && current.localMonth === 1 && { spacer: { value: String(current.localYear) } }),
    };
  });
};
