@if (usePopover) {
  <button
    type="button"
    [class]="
      'common-selector fill grow ps-small link-hover align-left border-hover-2 nordic-blue-600-border-hover rounded-large white-bg ' +
      (position ? 'position-' + position : '')
    "
    [class.filled]="filled"
    [ngClass]="{
      'nordic-blue-50-bg highlight-border': highLight$ | async,
    }"
    [attr.aria-label]="ariaLabel"
    [attr.aria-disabled]="disabled"
    [disabled]="disabled"
    fcomPopover
    [content]="popoverContent"
    [options]="popoverOptions"
    (popoverClosed)="handlePopoverClose()"
    data-testid="booking-widget-selector-popover"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
} @else {
  <button
    type="button"
    [class]="
      'common-selector fill grow ps-small link-hover align-left border-hover-2 nordic-blue-600-border-hover rounded-large white-bg ' +
      (position ? 'position-' + position : '')
    "
    [ngClass]="{
      'nordic-blue-50-bg highlight-border': highLight$ | async,
    }"
    [class.filled]="filled"
    [attr.aria-label]="ariaLabel"
    [attr.aria-disabled]="disabled"
    [disabled]="disabled"
    data-testid="booking-widget-selector-modal"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}

<ng-template #content>
  <ng-container *ngIf="!double">
    <div class="flex flex--column flex--left-center">
      <ng-container *ngTemplateOutlet="selectorTemplate; context: { label, placeholder, filled }"></ng-container>
      <div class="medium-type font-body-1 nordic-blue-900-text">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="double">
    <div class="flex flex--row double">
      <div class="flex-1 flex flex--column flex--basis-half">
        <ng-container *ngTemplateOutlet="selectorTemplate; context: double.first"></ng-container>
        <div class="medium-type font-body-1 nordic-blue-900-text">
          <ng-content select="[first]"></ng-content>
        </div>
      </div>
      <ng-content select="[separator]"></ng-content>
      <div class="flex-1 flex flex--column flex--basis-half second-half">
        <ng-container *ngTemplateOutlet="selectorTemplate; context: double.second"></ng-container>
        <div class="medium-type font-body-1 nordic-blue-900-text">
          <ng-content select="[second]"></ng-content>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #selectorTemplate let-label="label" let-placeholder="placeholder" let-filled="filled">
  <span class="regular-type font-body-1 blue-600-text flex-1" data-testid="common-selector-label">{{ label }}</span>
  <span *ngIf="!filled" class="regular-type font-body-1 grey-600-text flex-1" data-testid="common-selector-placeholder">
    {{ placeholder }}
  </span>
</ng-template>
