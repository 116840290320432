import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { UiTranslateModule } from '@fcom/ui-translate';
import {
  ButtonModule,
  FcomFormsModule,
  HeadersModule,
  IconsModule,
  LoaderModule,
  ModalModule,
  NotificationsModule,
  QuickLinksModule,
} from '@fcom/ui-components';
import { LightSectionNaviComponent } from '@fcom/common/components/header/light-section-navi/light-section-navi.component';
import { ComponentsModule } from '@fcom/common/components';
import { SectionNaviComponent } from '@fcom/common/components/header/section-navi/section-navi.component';
import { ImagesModule } from '@fcom/common/components/images/images.module';
import { DirectiveModule } from '@fcom/common/directives';
import { GtmModule } from '@fcom/common/gtm';
import { PipesModule } from '@fcom/common/pipes';
import { ServiceFormsModule } from '@fcom/service-forms';
import { CmsDataService } from '@fcom/core/services';
import {
  OndMarketingDataService,
  OndMarketingLandingCoreModule,
  OndMarketingOffersService,
} from '@fcom/ond-marketing-landing-core';
import { FinFormsModule } from '@fcom/common/forms';
import { DestinationSearchModule } from '@fcom/destination-search';
import { MediaQueryService, RecommendationService } from '@fcom/common';
import { BookingService } from '@fcom/booking/services';
import { BookingWidgetModule } from '@fcom/booking-widget/booking-widget.module';

import { CmsCtaBigLinkComponent } from './components/cms-cta-big-link.component';
import { CtaBigLinkComponent } from './cta-big-link/cta-big-link.component';
import { CmsConditionalAccordionComponent } from './conditional-accordion/cms-conditional-accordion.component';
import { CmsCtaLinkComponent } from './components/cms-cta-link.component';
import { CheapestPricePipe, DecodeURIPipe, OffsetToLocalTimePipe } from '../pipes';
import { Cms3DSeatMapService, CmsTemplateService, ExternalTemplateService, NewsletterSignupService } from '../services';
import { BlockOfferComponent } from './block-offer/block-offer.component';
import { CmsBackLinkComponent } from './components/cms-back-link.component';
import { CmsCampaignComponent } from './components/cms-campaign.component';
import { CmsCategoryComponent } from './components/cms-category.component';
import { CmsCtaWithImageAndTextComponent } from './components/cms-cta-with-image-and-text.component';
import { CmsDescriptiveLinkListComponent } from './components/cms-descriptive-link-list.component';
import { CmsDestinationHeroComponent } from './components/cms-destination-hero.component';
import { CmsDiscoverNowComponent } from './components/cms-discover-now.component';
import { CmsDisruptionsComponent } from './components/cms-disruptions.component';
import { CmsExternalLinkComponent } from './components/cms-external-link.component';
import { CmsFooterComponent } from './components/cms-footer.component';
import { CmsImportantLinksComponent } from './components/cms-important-links.component';
import { CmsUniqueSellingPointComponent } from './components/cms-unique-selling-point.component';
import { CmsLinkBlockComponent } from './components/cms-link-block.component';
import { CmsLinkListWithIconsComponent } from './components/cms-link-list-with-icons.component';
import { CmsListWithIconsComponent } from './components/cms-list-with-icons.component';
import { CmsOffersGridComponent } from './components/cms-offers-grid.component';
import { CmsPageComponent } from './components/cms-page.component';
import { CmsRichTextComponent } from './components/cms-rich-text.component';
import { CmsOndMarketingOffersComponent } from './components/cms-ond-marketing-offers.component';
import { CmsOndMarketingOffersListComponent } from './components/cms-ond-marketing-offers-list.component';
import { CmsOndMarketingCrossLinkingComponent } from './components/cms-ond-marketing-cross-linking.component';
import { CmsTeaserComponent } from './components/cms-teaser.component';
import { CmsTextOnImageComponent } from './components/cms-text-on-image.component';
import { CmsTravelGuideComponent } from './components/cms-travel-guide.component';
import { CmsTextWithSideImageComponent } from './components/cms-text-with-side-image.component';
import { ContinueReadingComponent } from './continue-reading/continue-reading.component';
import { CtaWithImageAndTextComponent } from './cta-with-image-and-text/cta-with-image-and-text.component';
import { DestinationHeroComponent } from './destination/destination-hero/destination-hero.component';
import { DestinationCategoryHeroComponent } from './destination/destination-category-hero/destination-category-hero.component';
import { DynamicRouteComponent } from './dynamic';
import { CmsEditorialCategoryPageComponent } from './dynamic/cms-editorial-category-page.component';
import { EditorialPageHeadComponent } from './editorial/editorial-page-head/editorial-page-head.component';
import { EditorialCategoryComponent } from './editorial/editorial-category/editorial-category.component';
import { CmsEditorialMainPageComponent } from './dynamic/cms-editorial-main-page.component';
import { CmsBluewingsMainPageComponent } from './dynamic/cms-bluewings-main-page.component';
import { CmsChildPageComponent } from './dynamic/cms-child-page.component';
import { CmsExternalPageRendererComponent } from './dynamic/external-page-renderer';
import { CmsDestinationComponent } from './dynamic/cms-destination.component';
import { CmsJsonContentComponent } from './dynamic/cms-json-content/cms-json-content.component';
import { RenderLazyComponent } from './dynamic/cms-json-content/render-lazy-component.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { FooterOfferComponent } from './footer-offer/footer-offer.component';
import { LinkBlockComponent } from './link-block/link-block.component';
import { OfferDetailsComponent } from './offers/offer-details.component';
import { OfferGridComponent } from './offers/offer-grid.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { TextOnImageComponent } from './text-on-image/text-on-image.component';
import { TextOnImageNoLinkComponent } from './text-on-image-no-link/text-on-image-no-link.component';
import { TravelGuideComponent } from './travel-guides';
import { TravelGuidePhrasesComponent } from './travel-guides/travel-guide-phrases.component';
import { VideoComponent } from './video/video.component';
import { CmsDisclaimerComponent } from './components/cms-disclaimer.component';
import { CmsShowMoreComponent } from './continue-reading/cms-show-more.component';
import { CmsAccordionComponent } from './accordion/cms-accordion.component';
import { CmsContentSwitcherDropdownComponent } from './content-switcher-dropdown/cms-content-switcher-dropdown.component';
import { CmsBadgeComponent } from './components/cms-badge/cms-badge.component';
import { CmsContentSwitcherTabsComponent } from './components/content-switcher-tabs/cms-content-switcher-tabs.component';
import { TextLinkComponent } from './text-link/text-link.component';
import { CmsTextLinkComponent } from './components/cms-text-link.component';
import { IconWithTextComponent } from './icon-with-text/icon-with-text.component';
import { CmsIconWithTextComponent } from './components/cms-icon-with-text.component';
import { BookingEntryComponent } from './booking-entry';
import { ContentComponentsModule } from './content-components.module';
import { TeaserListComponent } from './teaser/teaser-list/teaser-list.component';
import { TeaserComponent } from './teaser/teaser/teaser.component';
import { TeaserCardComponent } from './teaser/teaser-card/teaser-card.component';
import { AuthorComponent } from './article/author/author.component';
import { DateComponent } from './article/date/date.component';
import { DetailTextComponent } from './article/detail-text/detail-text.component';
import { RelatedTeaserComponent } from './article/related-teaser/related-teaser.component';
import { TagComponent } from './article/tags/tags.component';
import { ContentServicesModule } from '../services/content-services.module';
import { SocialComponent } from './article/social/social.component';
import { ArticleBluewingsComponent } from './dynamic/article-bluewings.component';
import { ArticleChildComponent } from './dynamic/article-child.component';
import { CmsBookNowComponent } from './components/cms-book-now.component';
import { InformationalTeaserComponent } from './teaser/informational-teaser/informational-teaser.component';
import { CmsListWithHeadingComponent } from './components/cms-list-with-heading.component';
import { TravelRestrictionsModule } from './placeholders/travel-restrictions/travel-restrictions.module';
import { CmsFullWidthBookingWidgetComponent } from './components/cms-full-width-booking-widget/cms-full-width-booking-widget.component';
import { HorizontalTitleComponent } from './horizontal-title/horizontal-title.component';
import { CmsArticleCollectionComponent } from './article-collection/cms-article-collection.component';
import { CmsHeadingTagComponent } from './cms-heading-tag/cms-heading-tag.component';
import { TrendingDestinationsComponent } from './trending-destinations-grid/trending-destinations-grid.component';
import { RenderLazyWizardComponent } from './dynamic/render-lazy-wizard.component';
import { NewsletterSignupFormComponent } from './placeholders/newsletter-signup-form/newsletter-signup-form.component';
import { DynamicHtmlComponent } from './dynamic/dynamic-html.component';
import { CmsArticlesWithIconsComponent } from './components/cms-articles-with-icons.component';
import { CmsGlobalDisruptionBannerComponent } from './components/cms-global-disruption-banner.component';
import { QualtricsEmbeddedPageFeedbackComponent } from './qualtrics-embedded-page-feedback/qualtrics-embedded-page-feedback.component';
import { TextLinkLeftComponent } from './teaser/text-link-left/text-link-left.component';
import { CmsCmrelatedComponent } from './components/cms-cmrelated.component';
import { CmsArrowLinkListComponent } from './components/cms-arrow-link-list.component';
import { NewsFeedItemComponent } from './news-feed-item/news-feed-item.component';
import { CmsQuickLinksComponent } from './components/cms-quick-links.component';

const PRIVATE_PARTS = [
  BlockOfferComponent,
  DestinationHeroComponent,
  DestinationCategoryHeroComponent,
  CmsFullWidthBookingWidgetComponent,
  ExternalLinkComponent,
  FooterOfferComponent,
  OfferDetailsComponent,
  OfferGridComponent,
  TravelGuideComponent,
  TravelGuidePhrasesComponent,
  ContinueReadingComponent,
  RichTextComponent,
  VideoComponent,
  TextOnImageNoLinkComponent,
  TextOnImageComponent,
  CtaWithImageAndTextComponent,
  LinkBlockComponent,
  TextLinkComponent,
  CtaBigLinkComponent,
  IconWithTextComponent,
  TeaserComponent,
  TeaserCardComponent,
  AuthorComponent,
  DateComponent,
  DetailTextComponent,
  RelatedTeaserComponent,
  TagComponent,
  SocialComponent,
  HorizontalTitleComponent,
  CmsHeadingTagComponent,
  RenderLazyComponent,
  RenderLazyWizardComponent,
  TextLinkLeftComponent,
];

@NgModule({
  imports: [
    ButtonModule,
    HeadersModule,
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    GtmModule,
    ImagesModule,
    PipesModule,
    FinFormsModule,
    FcomFormsModule,
    IconsModule,
    OndMarketingLandingCoreModule,
    ServiceFormsModule,
    UiTranslateModule,
    ContentComponentsModule,
    ContentServicesModule.forRoot(),
    DestinationSearchModule,
    NotificationsModule,
    TravelRestrictionsModule,
    LoaderModule,
    ModalModule,
    BookingWidgetModule,
    QuickLinksModule,
  ],
  declarations: [
    CmsAccordionComponent,
    CmsConditionalAccordionComponent,
    CmsBadgeComponent,
    CmsCategoryComponent,
    CmsDestinationHeroComponent,
    CmsFullWidthBookingWidgetComponent,
    CmsExternalLinkComponent,
    CmsJsonContentComponent,
    CmsDestinationComponent,
    CmsEditorialMainPageComponent,
    CmsBluewingsMainPageComponent,
    CmsChildPageComponent,
    CmsEditorialCategoryPageComponent,
    EditorialCategoryComponent,
    EditorialPageHeadComponent,
    CmsExternalPageRendererComponent,
    CmsOffersGridComponent,
    CmsListWithHeadingComponent,
    CmsPageComponent,
    CmsFooterComponent,
    CmsRichTextComponent,
    CmsCtaBigLinkComponent,
    CmsShowMoreComponent,
    CmsTeaserComponent,
    CmsBackLinkComponent,
    CmsDisclaimerComponent,
    CmsTravelGuideComponent,
    CmsOndMarketingOffersComponent,
    CmsOndMarketingOffersListComponent,
    CmsOndMarketingCrossLinkingComponent,
    DynamicRouteComponent,
    CmsTextOnImageComponent,
    CmsTextLinkComponent,
    CmsImportantLinksComponent,
    CmsUniqueSellingPointComponent,
    CmsDisruptionsComponent,
    CmsDiscoverNowComponent,
    CmsCampaignComponent,
    CmsCtaWithImageAndTextComponent,
    CmsLinkBlockComponent,
    CmsLinkListWithIconsComponent,
    CmsListWithIconsComponent,
    CmsDescriptiveLinkListComponent,
    CmsContentSwitcherDropdownComponent,
    CmsContentSwitcherTabsComponent,
    TeaserListComponent,
    ArticleBluewingsComponent,
    ArticleChildComponent,
    CmsBookNowComponent,
    InformationalTeaserComponent,
    QualtricsEmbeddedPageFeedbackComponent,
    ...PRIVATE_PARTS,
    DecodeURIPipe,
    CheapestPricePipe,
    OffsetToLocalTimePipe,
    SectionNaviComponent,
    LightSectionNaviComponent,
    CmsIconWithTextComponent,
    CmsCtaLinkComponent,
    CmsTextWithSideImageComponent,
    BookingEntryComponent,
    CmsArticleCollectionComponent,
    TrendingDestinationsComponent,
    NewsletterSignupFormComponent,
    DynamicHtmlComponent,
    CmsArticlesWithIconsComponent,
    CmsGlobalDisruptionBannerComponent,
    CmsCmrelatedComponent,
    CmsArrowLinkListComponent,
    NewsFeedItemComponent,
    CmsQuickLinksComponent,
  ],
  exports: [
    CmsBadgeComponent,
    CmsCategoryComponent,
    CmsDestinationHeroComponent,
    CmsFullWidthBookingWidgetComponent,
    CmsExternalLinkComponent,
    CmsJsonContentComponent,
    CmsDestinationComponent,
    CmsEditorialMainPageComponent,
    CmsBluewingsMainPageComponent,
    CmsChildPageComponent,
    CmsEditorialCategoryPageComponent,
    EditorialCategoryComponent,
    EditorialPageHeadComponent,
    CmsExternalPageRendererComponent,
    CmsOffersGridComponent,
    CmsListWithHeadingComponent,
    CmsPageComponent,
    CmsFooterComponent,
    CmsRichTextComponent,
    CmsCtaBigLinkComponent,
    CmsShowMoreComponent,
    CmsTeaserComponent,
    CmsBackLinkComponent,
    CmsTravelGuideComponent,
    CmsOndMarketingOffersComponent,
    CmsOndMarketingOffersListComponent,
    CmsOndMarketingCrossLinkingComponent,
    DynamicRouteComponent,
    CmsTextOnImageComponent,
    CmsTextLinkComponent,
    CmsCtaWithImageAndTextComponent,
    CmsLinkBlockComponent,
    CmsLinkListWithIconsComponent,
    CmsListWithIconsComponent,
    CmsDescriptiveLinkListComponent,
    CmsContentSwitcherDropdownComponent,
    CmsContentSwitcherTabsComponent,
    CmsImportantLinksComponent,
    CmsUniqueSellingPointComponent,
    CmsDisruptionsComponent,
    CmsDiscoverNowComponent,
    CmsCampaignComponent,
    SectionNaviComponent,
    LightSectionNaviComponent,
    CmsIconWithTextComponent,
    CmsCtaLinkComponent,
    CmsTextWithSideImageComponent,
    BookingEntryComponent,
    TeaserListComponent,
    ArticleBluewingsComponent,
    ArticleChildComponent,
    CmsBookNowComponent,
    InformationalTeaserComponent,
    QualtricsEmbeddedPageFeedbackComponent,
    CmsArticleCollectionComponent,
    TrendingDestinationsComponent,
    NewsletterSignupFormComponent,
    DynamicHtmlComponent,
    CmsArticlesWithIconsComponent,
    CmsGlobalDisruptionBannerComponent,
    CmsCmrelatedComponent,
    CmsArrowLinkListComponent,
    NewsFeedItemComponent,
    CmsQuickLinksComponent,
  ],
})
export class CmsModule {
  static forRoot(): ModuleWithProviders<CmsModule> {
    return {
      ngModule: CmsModule,
      providers: [
        BookingService,
        CmsDataService,
        CmsTemplateService,
        OndMarketingOffersService,
        OndMarketingDataService,
        ExternalTemplateService,
        MediaQueryService,
        RecommendationService,
        Cms3DSeatMapService,
        NewsletterSignupService,
      ],
    };
  }
}
