import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  TemplateRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { withLatestFrom, filter, map } from 'rxjs/operators';

import { DateFormat, LocalDate, TzDate, Pattern, unsubscribe, isPresent } from '@fcom/core/utils';
import { HistogramBar } from '@fcom/common';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';

import { DatepickerTitleAreaOptions } from '../../interfaces';
import { DateSelection, Day, Month } from '../../../utils/date.interface';
import { ParseUserInput } from '../../../utils/date.format.utils';
import { NotificationLayout, NotificationTheme } from '../../notifications';
import { DateRange } from '../interfaces';
import { ButtonTheme } from '../../buttons';
import { IconPosition } from '../../icons';

const DEFAULT_TITLE_AREA_CONFIGURATION: DatepickerTitleAreaOptions = {
  titleAreaStyle: 'ps-large',
  title: undefined,
  titleStyle: 'font-body-1',
};

const DAYS_AMOUNT = 360; // Default calendar size

@Component({
  selector: 'fcom-datepicker',
  styleUrls: ['./datepicker.component.scss'],
  templateUrl: './datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('titleElement') titleElement: ElementRef<HTMLElement>;

  @Input() doneBtnHidden = false;

  /**
   * Object of translated and localized date labels
   * (e.g. month names, date formats)
   */
  @Input() dateLabels: any;

  /**
   * Object of translated UI labels
   * (e.g. error messages, instructions)
   */
  @Input() uiLabels: any;

  @Input() set titleAreaConfiguration(configuration: DatepickerTitleAreaOptions) {
    this.titleAreaConfig = { ...DEFAULT_TITLE_AREA_CONFIGURATION, ...configuration };
  }

  /**
   * H2 title to be shown in top left of modal
   */
  @Input() title: string = undefined;
  @Input() subTitle?: TemplateRef<ElementRef>;

  /**
   * Initially preselected dates
   * Array of (one or two) YYYY-MM-DD strings
   */
  @Input() selectedDates: [LocalDate] | DateRange;

  /**
   * Defined range of calendar (first and last selectable date)
   * Array of two YYYY-MM-DD strings
   */
  @Input() selectableDatesRange: DateRange;

  /**
   * Ranges where dates should be disabled
   * An array which includes array of two LocalDates
   */
  @Input() disabledDateRanges?: DateRange[];

  /**
   * If datepicker should select range or single date
   */
  @Input() isDateRange = false;

  /**
   * Focus header on init
   */
  @Input() focusHeaderOnInit = false;

  @Input() topNotification: string = undefined;

  /**
   * Show month chip buttons on top of calendar
   */
  @Input() showTags = true;

  /**
   * Show histogram on top of the calendar
   */
  @Input() headerTemplate?: TemplateRef<HistogramBar[]>;

  /**
   * Custom day template to be rendered within the calendar
   */
  @Input() dayTemplate?: TemplateRef<{ dayValue: Day; dayString: number }>;

  /**
   * Set the month index where the calendar should scroll to
   */
  @Input() scrollToMonthIndex$: Observable<number> = EMPTY;

  @Input() showAddReturn = false;

  /**
   * Event emitter for selected date or date range
   * Array of (one or two) YYYY-MM-DD strings
   */
  @Output() selectedDatesChange: EventEmitter<[string] | [string, string]> = new EventEmitter<
    [string] | [string, string]
  >();

  /**
   * Event emitter for close event
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  /**
   * Event emitter for monthChange
   */
  @Output() monthChange: EventEmitter<Month> = new EventEmitter();

  @Output() addReturn: EventEmitter<void> = new EventEmitter();

  readonly ButtonTheme = ButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  titleAreaConfig = DEFAULT_TITLE_AREA_CONFIGURATION;
  startDate = '';
  endDate = '';
  startDateError: string = undefined;
  endDateError: string = undefined;
  dateSelectedLabel: string = undefined;
  NotificationTheme = NotificationTheme;
  NotificationLayout = NotificationLayout;

  minDate: LocalDate;
  maxDate: LocalDate;
  selectedDateSelection: DateSelection;
  private currentDate: LocalDate = LocalDate.now();

  private clearDates$ = new Subject<void>();
  private subscription: Subscription = new Subscription();

  constructor(
    private mediaQueryService: MediaQueryService,
    private hostElement: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('selectableDatesRange') || changes.hasOwnProperty('disabledDateRanges')) {
      this.minDate = this.selectableDatesRange[0] ? this.selectableDatesRange[0] : this.currentDate;

      this.maxDate = this.selectableDatesRange[1]
        ? this.selectableDatesRange[1]
        : this.currentDate.plusDays(DAYS_AMOUNT).firstDayOfMonth.plusMonths(1).minusDays(1);
    }

    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('selectedDates')) {
      this.selectedDateSelection = this.dateArrayToDateSelection(this.selectedDates);
    }

    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('isDateRange')) {
      this.dateSelectedLabel = undefined;
    }
  }

  ngAfterViewInit(): void {
    if (this.focusHeaderOnInit) {
      this.titleElement.nativeElement.focus();
    }

    this.subscription.add(
      this.clearDates$
        .pipe(
          withLatestFrom(this.mediaQueryService.isBreakpoint$('laptop_up')),
          map(([_, isLaptopUp]) => isLaptopUp),
          filter(Boolean)
        )
        .subscribe(() => {
          this.scrollToTop();
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  onDatesSelected(dates: DateSelection): void {
    this.dateSelectedLabel = undefined;
    if (dates.startDate) {
      this.startDate = new DateFormat(this.dateLabels).format(
        dates.startDate,
        new Pattern('dateFormatUserInput', true)
      );
    } else {
      this.startDate = undefined;
    }

    if (dates.endDate) {
      this.endDate = new DateFormat(this.dateLabels).format(dates.endDate, new Pattern('dateFormatUserInput', true));
    } else {
      this.endDate = undefined;
    }

    if (this.isDateRange) {
      this.selectedDatesChange.emit([
        dates.startDate ? dates.startDate.toString() : undefined,
        dates.endDate ? dates.endDate.toString() : undefined,
      ]);
    } else {
      this.selectedDatesChange.emit([dates.startDate ? dates.startDate.toString() : undefined]);
    }

    if (this.isDateRange && this.startDate && this.endDate) {
      this.dateSelectedLabel = this.uiLabels.selectedDates;
    }
    if (!this.isDateRange && this.startDate) {
      this.dateSelectedLabel = this.uiLabels.selectedDate;
    }
    this.clearErrors();
  }

  setDate(event: Event): void {
    const element = event.target as HTMLInputElement;
    const isEndDate = element.name === 'endDate';
    let errorMessage;

    const userInputISOString = new ParseUserInput(element.value, this.dateLabels.dateFormatUserInput).getISOString();
    if (!userInputISOString && element.value !== '') {
      errorMessage = this.uiLabels.error.invalidDate;
    } else if (userInputISOString) {
      try {
        const date = LocalDate.fromTzDate(new TzDate(userInputISOString));
        if (
          !date.isBetween(this.minDate, this.maxDate) ||
          (this.disabledDateRanges && this.disabledDateRanges.some((range) => date.isBetween(range[0], range[1])))
        ) {
          errorMessage = this.uiLabels.error.outOfRange;
        } else {
          this.verifyDateSelection(date, isEndDate);
          errorMessage = undefined;
        }
      } catch (e) {
        switch (e.message) {
          case 'startAfterEnd':
            errorMessage = this.uiLabels.error.startAfterEnd;
            break;
          case 'endBeforeStart':
            errorMessage = this.uiLabels.error.endBeforeStart;
            break;
          case 'outOfRange':
            errorMessage = this.uiLabels.error.outOfRange;
            break;
          default:
            errorMessage = this.uiLabels.error.invalidDate;
            break;
        }
      }
    } else if (element.value === '') {
      this.verifyDateSelection(undefined, isEndDate);
      errorMessage = undefined;
    }

    if (isEndDate) {
      this.endDateError = errorMessage;
    } else {
      this.startDateError = errorMessage;
    }
  }

  clearErrors(): void {
    this.endDateError = undefined;
    this.startDateError = undefined;
  }

  clearDates(): void {
    // we act to clear dates only if any of start date or end date are previously set
    if (isPresent(this.selectedDateSelection?.startDate) || isPresent(this.selectedDateSelection?.endDate)) {
      this.clearDates$.next();
      this.selectedDateSelection = this.dateArrayToDateSelection([]);
      this.dateSelectedLabel = undefined;
    }
  }

  closeDatepicker(): void {
    this.close.emit();
  }

  private verifyDateSelection(date: LocalDate | undefined, isEndDate: boolean): void {
    const currentSelection: DateSelection = this.selectedDateSelection;
    const currentCorrespondingDateSelection = isEndDate ? currentSelection.endDate : currentSelection.startDate;
    const isAlreadySelected = currentCorrespondingDateSelection && date?.equals(currentCorrespondingDateSelection);
    const isEndBeforeStart = isEndDate && currentSelection.startDate && date?.lt(this.minDate);
    const isOutOfRange = date?.lt(this.minDate) || date?.gt(this.maxDate);

    if (isAlreadySelected) {
      return;
    } else if (isEndBeforeStart) {
      throw new Error('endBeforeStart');
    } else if (isOutOfRange) {
      throw new Error('outOfRange');
    } else {
      const newSelections = isEndDate ? { endDate: date } : { startDate: date };
      this.selectedDateSelection = { ...currentSelection, ...newSelections };
    }
  }

  private scrollToTop() {
    requestAnimationFrame(() => {
      this.hostElement.nativeElement.scrollIntoView();
    });
  }

  private dateArrayToDateSelection(dateArray: LocalDate[]): DateSelection {
    const dateSelection: DateSelection = { startDate: undefined, endDate: undefined };
    if (dateArray[0] && this.isWithinSelectableRange(dateArray[0])) {
      dateSelection.startDate = dateArray[0];
      if (dateArray[1] && this.isWithinSelectableRange(dateArray[1])) {
        dateSelection.endDate = dateArray[1];
      }
    }
    return dateSelection;
  }

  private isWithinSelectableRange(date: LocalDate): boolean {
    return this.minDate.lte(date) && this.maxDate.gte(date);
  }
}
