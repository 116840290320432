import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable, ReplaySubject } from 'rxjs';
import { startWith, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ConfigService } from '@fcom/core/services';
import { isPresent } from '@fcom/core/utils';
import { urlWithoutQueryString } from '@fcom/core/utils/app-url-utils';

const PROTOCOL_AND_HOST_REGEX = /^(https?:)?\/\/([^/]*)/;

export interface CmsPageComponentMetaData {
  tagName: string;
  attributes: { [key: string]: string };
}

/**
 * Cross-platform get documentElement from the document object
 * @param doc
 */
const getDocumentElement = (doc: Document): any => {
  if (doc.documentElement) {
    return doc.documentElement;
  }
  return Array.from(doc.children).filter((e: any) => (e.nodeName || e.name || '').toLowerCase() === 'html')[0];
};

// Not really, but for now
const ISO_639_1_LANG_REGEX = /^(\w{2}-)?(\w{2})$/;
const getLangCode = (langValue: string) => {
  const lang = ISO_639_1_LANG_REGEX.exec(langValue);
  return lang?.[2];
};

export interface Links {
  [lang: string]: string;
}

export interface UrlToLinks {
  url: string;
  links: Links;
}

@Injectable()
export class PageMetaService {
  private linksReplaySubject$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private linksForUrlReplaySubject$: ReplaySubject<UrlToLinks> = new ReplaySubject<UrlToLinks>(1);
  private pageTitle$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private pageDescription$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private pageDefaultOriginLocationCode$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private allowableLocales: string[];
  private baseUrl: string;

  prevPageUrl$: ReplaySubject<string> = new ReplaySubject<string | undefined>(1);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private metaService: Meta,
    private configService: ConfigService,
    private router: Router
  ) {
    this.allowableLocales = Object.keys(this.configService.cfg.allowedLocales).map((key) =>
      this.configService.cfg.allowedLocales[key].replace('_', '-')
    );
    this.baseUrl = this.configService.cfg.baseUrl;
  }

  /**
   * Set the content of the <title> tag in HTML.
   *
   * @param title the title to set
   */
  setTitle(title: string): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'og:title', content: title });
    this.metaService.updateTag({ class: 'elastic', name: 'elastic_title', content: title });
    this.pageTitle$.next(title);
  }

  setDescription(description: string): void {
    this.pageDescription$.next(description);
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'og:description', content: description });
  }

  setHref(href: string, hreflang: string): void {
    this.metaService.updateTag({ cmspage: '', rel: 'alternate', hreflang: hreflang, href: href });
  }

  removeExistingMetaTags(): void {
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('name="og:description"');
    this.metaService.removeTag('name="og:title"');
  }

  setDefaultOriginLocationCode(defaultOriginLocationCode: string): void {
    this.pageDefaultOriginLocationCode$.next(defaultOriginLocationCode);
  }

  setMetaTags(tags: CmsPageComponentMetaData[], renderer: Renderer2): void {
    this.removePreviousCmsPageTagsFromHeader(renderer);
    this.removeExistingMetaTags();

    tags.forEach((e: CmsPageComponentMetaData) => {
      const element = renderer.createElement(e.tagName);
      const attributes = e.attributes;
      if (attributes.property === 'og:title') {
        this.metaService.updateTag({ class: 'elastic', name: 'elastic_title', content: attributes.content });
      }

      if (attributes.property === 'og:url') {
        attributes.content = this.replaceHostnameAndProtocol(attributes.content);
      }

      renderer.setAttribute(element, 'cmspage', '');
      Object.keys(attributes).forEach((key) => {
        const value = key === 'href' ? this.replaceHostnameAndProtocol(attributes[key]) : attributes[key];
        renderer.setAttribute(element, key, value);
      });

      renderer.appendChild(this.document.head, element);
    });
  }

  get title$(): Observable<string> {
    return this.pageTitle$.asObservable().pipe(delay(0));
  }

  get description(): Observable<string> {
    return this.pageDescription$.asObservable();
  }

  get links(): Observable<Links> {
    return this.linksReplaySubject$.asObservable().pipe(startWith({}));
  }

  get defaultOriginLocationCode$(): Observable<string> {
    return this.pageDefaultOriginLocationCode$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Returns Links for give page url
   * @param url current page url
   * @returns Links for the current page url
   */
  linksForUrl(url: string): Observable<Links> {
    return this.linksForUrlReplaySubject$.asObservable().pipe(
      filter((item) => item.url === url),
      map((item) => item.links)
    );
  }

  /**
   * Create tags from all objects under metaData list
   *
   * Removes host name and protocol from the target url
   *
   * @param elements
   * @param renderer
   */
  moveMetaAndLinksToHead(elements: CmsPageComponentMetaData[], renderer: Renderer2): void {
    const elems: CmsPageComponentMetaData[] = this.removeNotAllowedHreflangs(elements);
    this.linksReplaySubject$.next(this.getLinksFromMetadata(elems));
    this.linksForUrlReplaySubject$.next({
      url: urlWithoutQueryString(this.router.url),
      links: this.getLinksFromMetadata(elems),
    });

    this.setMetaTags(elems, renderer);
  }

  movePreloadImageLinksToHead(renderer: Renderer2, href: string, width?: number): void {
    const existingLink = this.document.head.querySelector(`link[href="${href}"]`);
    if (existingLink) {
      return;
    }
    const link: HTMLLinkElement = renderer.createElement('link');
    renderer.setAttribute(link, 'href', href);
    renderer.setAttribute(link, 'rel', 'preload');
    renderer.setAttribute(link, 'as', 'image');
    if (width) {
      renderer.setAttribute(link, 'media', `(min-width: ${width}px)`);
    }
    renderer.appendChild(this.document.head, link);
  }

  removePreloadImageLinksFromHead(renderer: Renderer2, href: string): void {
    const link = this.document.head.querySelector(`link[href="${href}"]`);
    if (link) {
      renderer.removeChild(this.document.head, link);
    }
  }

  setDocumentLanguage(lang: string, renderer: Renderer2): void {
    const doc = getDocumentElement(this.document);
    renderer.setProperty(doc, 'lang', getLangCode(lang));
    renderer.setAttribute(doc, 'xml:lang', getLangCode(lang));
  }

  private removePreviousCmsPageTagsFromHeader(renderer: Renderer2) {
    // Parse5 elements on server contains only attribs object and it does not have NamedNodeMap like real DOM
    const onlyCmsPageNodes = (n: HTMLElement) => n.attributes && isPresent(n.attributes.getNamedItem('cmspage'));
    Array.from(this.document.head.children)
      .filter(onlyCmsPageNodes)
      .forEach((tag) => {
        renderer.removeChild(this.document.head, tag);
      });
  }

  private removeNotAllowedHreflangs(meta: CmsPageComponentMetaData[]): CmsPageComponentMetaData[] {
    return meta.filter((tag) => {
      const hreflang = tag.attributes['hreflang'];
      const allowedLanguage = hreflang && this.verifyHreflang(hreflang);
      return !hreflang || allowedLanguage;
    });
  }

  private verifyHreflang(hreflang: string): boolean {
    return this.allowableLocales.indexOf(hreflang) > -1 || hreflang === 'x-default';
  }

  private langFromHrefLang(hreflang: string): string {
    return hreflang === 'x-default' ? 'en' : hreflang.split('-').reverse().join('-').toLowerCase();
  }

  private getLinksFromMetadata(meta: CmsPageComponentMetaData[]): Links {
    return meta
      .filter((tag) => isPresent(tag.attributes['hreflang']))
      .reduce((acc, tag) => {
        const lang: string = this.langFromHrefLang(tag.attributes['hreflang']);
        if (lang) {
          acc[lang] = this.replaceHostnameAndProtocol(tag.attributes['href']);
        }

        return acc;
      }, {});
  }

  private replaceHostnameAndProtocol(str: string): string {
    let returnString = str;
    // Remove the CMS protocol and host if needed
    if (PROTOCOL_AND_HOST_REGEX.test(str)) {
      returnString = str.replace(PROTOCOL_AND_HOST_REGEX, '');
    }
    return this.baseUrl + returnString;
  }
}
