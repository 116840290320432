<fin-editorial-page-head [title]="(activeNaviSection$ | async)?.teaserTitle"></fin-editorial-page-head>

<section class="editorial-main mr-medium-y grid-max-width padding-left-gutter padding-right-gutter center">
  <div class="grid-col-12 grid-max-width no-row-gap white-bg relative rounded-large">
    <!-- Section navigation -->
    <fin-light-section-navi class="col-1-12 md-col-1-3 sm-col-2-11"></fin-light-section-navi>

    <!-- FAQ content -->
    <section class="col-1-12 md-col-4-12 sm-col-2-11 mr-medium-t mr-large-b">
      <fin-cms-json-content
        @fadeIn
        class="grid-col-12 no-gutters no-row-gap"
        *ngIf="hasContent; else spinner"
        [template$]="template$"
      ></fin-cms-json-content>
      <ng-template #spinner>
        <fin-loader [type]="LoaderType.INLINE"></fin-loader>
      </ng-template>
    </section>
  </div>
</section>
