<div
  [formGroup]="parentForm"
  [class.ms-xlarge-b]="displayMargin"
  (focusout)="closeDropDownOnFocusout($event)"
  [class.enabled]="!parentForm.get(controlName)?.disabled"
>
  <div class="relative ps-xxsmall-b">
    <label
      *ngIf="!disableLabel"
      [attr.id]="id + '-label'"
      class="font-body-1 regular-type flex ps-xxsmall-b"
      [for]="idOrControlName"
      [ngClass]="{
        'nordic-blue-400-text': !control.invalid || !control.touched,
        'error-900-text': control.invalid && control.touched,
      }"
    >
      <div class="flex-1">
        {{ label || (translationPrefix + '.label' | finLocalization) }}
        <span *ngIf="showAsterix" class="flex-1 flex--nogrow" data-testid="asterisk"> *</span>
      </div>
      <ng-template #contentTooltip>
        <h2 class="font-body-1 bold-type nordic-blue-900-text">{{ tooltipHeading }}</h2>
        <p class="pr-xxsmall-t nordic-blue-400-text">{{ tooltipContent }}</p>
      </ng-template>
      <button
        type="button"
        *ngIf="tooltipContent"
        fcomPopover
        [options]="tooltipOptions"
        [content]="contentTooltip"
        [ariaCloseText]="'actions.close' | finLocalization"
        [attr.aria-label]="'actions.info' | finLocalization"
        class="icon nordic-blue-900-text ms-small-r flex-1 flex--nogrow align-right"
        [class.error-900-text]="control.invalid && control.touched"
      >
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.HELP_BLOCK"></fcom-icon>
      </button>
    </label>

    <div class="relative" #dropDown>
      <div #buttonContainer>
        <button
          *ngIf="searchable === false"
          class="input flex flex--middle flex--space-between border rounded-large ps-small-l fill"
          [ngClass]="{ 'is-open': (dropDownOpen$ | async), 'link-hover': !parentForm.get(controlName)?.disabled }"
          (click)="onInputEnter()"
          (keydown)="handleKeyDown($event)"
          role="combobox"
          [id]="id + '-button'"
          aria-haspopup="listbox"
          tabindex="0"
          [class.btn-disabled]="parentForm.get(controlName)?.disabled === true"
          [attr.aria-activedescendant]="id + '-option-' + (activeIndex$ | async)"
          [attr.aria-controls]="id + '-options'"
          [attr.aria-expanded]="dropDownOpen$ | async"
          [attr.aria-labelledby]="id + '-label'"
          [attr.disabled]="parentForm.get(controlName)?.disabled ? 'disabled' : undefined"
          [attr.aria-disabled]="parentForm.get(controlName)?.disabled === true"
          #selectButton
        >
          <span
            data-testid="disabled-label"
            class="font-body-1 medium-type grey-600-text"
            *ngIf="parentForm.get(controlName)?.disabled === true"
            >{{ selectedOption ? selectedOption.name : '-' }}</span
          >
          <ng-container *ngIf="parentForm.get(controlName)?.disabled === false">
            <span data-testid="select-label" class="font-body-1 medium-type grey-600-text" *ngIf="!selectedOption">{{
              selectLabel || (selectLabelTranslationKey | finLocalization)
            }}</span>
            <span class="font-body-1 medium-type nordic-blue-900-text" *ngIf="selectedOption">{{
              selectedOption.name
                ? selectedOption.name
                : (translationPrefix + '.' + selectedOption.value | finLocalization)
            }}</span>
          </ng-container>
          <fcom-icon
            class="icon-medium ms-small-r"
            [ngClass]="{ 'grey-600-text': parentForm.get(controlName)?.disabled === true }"
            [name]="(dropDownOpen$ | async) ? SvgLibraryIcon.CHEVRON_UP : SvgLibraryIcon.CHEVRON_DOWN"
          ></fcom-icon>
        </button>

        <div
          class="text-input relative"
          *ngIf="searchable"
          [ngClass]="{ 'link-hover': !parentForm.get(controlName)?.disabled }"
        >
          <fcom-text-input
            [id]="id + '-text-input'"
            [parentForm]="searchForm"
            [translationPrefix]="translationPrefix"
            [controlName]="'searchInput'"
            [required]="false"
            [placeHolderText]="searchLabel || (translationPrefix + '.searchLabel' | finLocalization)"
            [hideLabel]="true"
            [displayMargin]="false"
            [disableAutoComplete]="true"
            [ariaOptions]="{ activedescendant: id + '-option-' + (activeIndex$ | async), controls: id + '-options' }"
            [cursorType]="CursorType.POINTER"
            [hideLabel]="true"
            aria-haspopup="listbox"
            role="combobox"
            (click)="openDropDown()"
            (inputEvent)="handleSearch()"
            (keydown)="handleKeyDown($event)"
            (keydown.enter)="onInputEnter()"
            #searchInput
          ></fcom-text-input>
          <fcom-icon
            *ngIf="(dropDownOpen$ | async) === false"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
            class="absolute ms-small-t icon-medium ms-small-r link-hover"
            (click)="openDropDown()"
            data-testid="text-input-chevron"
          ></fcom-icon>
          <fcom-icon-button
            *ngIf="dropDownOpen$ | async"
            class="absolute ms-xxsmall-t ms-xxsmall-r"
            [size]="IconButtonSize.MEDIUM"
            [theme]="IconButtonTheme.DARK_PRIMARY_NORDIC_BLUE"
            [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.CLOSE_DELETE_BLOCK }"
            [aria]="{
              label: clearSearchLabel,
            }"
            data-testid="clear-search"
            (click)="clearSearch($event)"
          ></fcom-icon-button>
        </div>
      </div>
      <ul
        [ngClass]="{ 'is-vishidden': (dropDownOpen$ | async) !== true }"
        [ngStyle]="dropDownStyle"
        class="dropdown white-bg absolute rounded-large shadow fill ms-xxsmall-t z1"
        tabindex="-1"
        role="listbox"
        [attr.id]="id + '-options'"
        [attr.aria-labelledby]="id + '-label'"
        #optionsList
      >
        <li
          *ngFor="let option of filteredOptions || options; let i = index"
          class="flex link-hover"
          [class.nordic-blue-50-bg]="i === (this.activeIndex$ | async)"
          [class.nordic-blue-50-border]="i === (this.activeIndex$ | async)"
          role="option"
          [attr.id]="id + '-option-' + i"
          [attr.aria-selected]="i === (this.activeIndex$ | async)"
          (keydown)="handleKeyDown($event)"
        >
          <span class="flex flex--column fill" *ngIf="optionTemplate" (click)="selectOption(option)">
            <ng-container
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ name: option.name, value: option.value }"
            >
            </ng-container>
          </span>
          <span *ngIf="!optionTemplate" class="flex flex--column ps-small fill" (click)="selectOption(option)">
            {{ option.name ? option.name : (translationPrefix + '.' + option.value | finLocalization) }}
          </span>
        </li>
      </ul>
    </div>
    <span class="divider"></span>
  </div>
  <div *ngIf="infoText" class="font-body-2 nordic-blue-400-text">
    {{ infoText }}
  </div>
  <div *ngIf="control.invalid && control.touched" class="error-label font-body-2" aria-live="polite">
    <span *ngIf="control.errors?.required" class="error-900-text">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
  </div>
</div>
