import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';

import { MediaQueryService } from '@fcom/common/services';

import { QuickLink } from './interfaces';

@Component({
  selector: 'fcom-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrl: './quick-links.component.scss',
})
export class QuickLinksComponent implements OnInit {
  isMobile$: Observable<boolean>;

  @Input()
  links$: Observable<QuickLink[]>;

  @Input()
  showBorder = true;

  @Input()
  showCompact = false;

  @Output() clickLink = new EventEmitter<QuickLink>();

  constructor(private mediaQueryService: MediaQueryService) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
  }
}
