import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { ConfigService } from '@fcom/core/services';
import { activeTab, BookingWidgetAppState } from '@fcom/booking-widget/store';
import { WidgetTab } from '@fcom/booking-widget/interfaces';
import { GtmTripType } from '@fcom/common/interfaces';

import { BookingAppState } from '../interfaces/booking';
import { globalBookingLocations } from '../store';
import { getTripTypeForFlightSearchParams } from '../utils/gtm.utils';

const DD_TRACE_HEADER_TAG_KEY = 'x-dd-flow-type';

export enum FlowType {
  FLIGHT = 'flight',
  AWARD = 'award',
  MULTICITY = 'multicity',
  OPENJAW = 'openjaw',
  UNKNOWN = 'unknown',
}

@Injectable({
  providedIn: 'root',
})
export class DatadogHeadersInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    private store$: Store<BookingWidgetAppState>,
    private bookingStore$: Store<BookingAppState>
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith(this.configService.cfg.offersUrl) && !req.url.startsWith(this.configService.cfg.cartUrl)) {
      return next.handle(req);
    }

    return this.getFlowType().pipe(
      switchMap((flowType) => {
        const request: HttpRequest<unknown> = req.clone({
          headers: req.headers.set(DD_TRACE_HEADER_TAG_KEY, flowType),
        });
        return next.handle(request);
      })
    );
  }

  private getFlowType(): Observable<FlowType> {
    return combineLatest([this.store$.pipe(activeTab()), this.bookingStore$.pipe(globalBookingLocations())]).pipe(
      take(1),
      map(([widgetTab, flights]) => {
        if (widgetTab === WidgetTab.AWARD) {
          return FlowType.AWARD;
        }

        if (!flights.length) {
          return FlowType.UNKNOWN;
        }

        const tripType = getTripTypeForFlightSearchParams(
          flights.map((flight) => {
            return {
              origin: flight?.origin?.locationCode,
              destination: flight?.destination?.locationCode,
              departureDate: null,
            };
          })
        );

        if (tripType === GtmTripType.OPENJAW) {
          return FlowType.OPENJAW;
        }

        if (tripType === GtmTripType.MULTICITY) {
          return FlowType.MULTICITY;
        }

        return FlowType.FLIGHT;
      })
    );
  }
}
