<div
  class="cf layout__nav nav-modal animation-wrapper white-bg flex flex--column"
  [attr.aria-hidden]="(menuInDom$ | async) === false"
  data-elastic-exclude
  data-testid="navigation-drawer"
>
  <div class="nav-header nordic-blue-900-bg fill white-text z1 relative">
    <div class="center padding-left-gutter padding-right-gutter no-row-gap grid-max-width">
      <fin-header-boxes class="col-f" [homeLink$]="homeLink$">
        <div left class="flex flex--center">
          <fin-navigation-trigger class="flex--nogrow inline-block relative"></fin-navigation-trigger>
        </div>
        <a center [finLink]="homeLink$" class="dn-laptop-down inherit-color">
          <span class="is-vishidden">{{ 'actions.home' | finLocalization }}</span>
          <fcom-icon
            class="finnair-emblem"
            [category]="IconLibrary.CUSTOM"
            [name]="CustomIcon.FINNAIR_EMBLEM"
          ></fcom-icon>
        </a>
      </fin-header-boxes>
    </div>
  </div>

  <div class="navigation-wrapper ps-large-t relative flex-1 grid-max-width grid-col-12">
    <nav
      role="navigation"
      class="nav-menu fill flex-1 ps-xlarge-t absolute"
      [attr.aria-label]="'navigation.mainNavigation' | finLocalization"
      [class.display-details]="expandedNaviCat$ | async"
    >
      <div class="search-wrapper flex flex--middle">
        <fin-site-search-header-widget-starter
          class="inline-block ps-small fill align-right dn-laptop-up grow"
          [enableNewLanguageSelector]="enableNewLanguageSelector$ | async"
          [searchButtonIconOnly]="true"
          [context]="'mobile'"
        ></fin-site-search-header-widget-starter>
        <div *ngIf="enableNewLanguageSelector$ | async" class="ps-xsmall-r">
          <fcom-button
            [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
            [text]="(countries$ | finMap: 'code' | async) + ' - ' + (currentLanguage$ | async | uppercase)"
            [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.LANGUAGE, position: IconPosition.LEFT }"
            (clickBtn)="openLanguageSelector()"
            data-testid="open-language-selector-button"
          >
          </fcom-button>
        </div>
      </div>

      <ul data-testid="navi-list" class="nordic-blue-900-text unstyled" role="list">
        <li
          class="ms-small-x ms-small-b"
          role="listitem"
          *ngFor="let item of (menuItems$ | async)?.main; trackBy: trackItems"
        >
          <ul
            *ngIf="((item.items && item.items.length > 0) || item.link) && !item.hideInMenu"
            role="list"
            class="no-list-style font-body-2 section-items"
          >
            <div
              class="nordic-blue-900-text menu-heading font-body-1 bold-type ps-xsmall-b ps-large-t"
              [class.is-hidden]="expandedNaviCat$ | async"
            >
              <a
                *ngIf="item.link"
                class="navi-category-title-link nordic-blue-900-text"
                (click)="closeMenu()"
                [class.current-item]="item.active"
                [attr.aria-current]="item.active ? 'page' : null"
                [finLink]="item.link"
                [external]="item.external"
                [finElemTracker]="'level-1: ' + item.teaserTitle"
                [finElemContext]="'top-navigation'"
                [finElemType]="ElementTypes.LINK"
                [attr.tabindex]="(expandedNaviCat$ | async) ? '-1' : '0'"
                [attr.target]="item.openInNewTab ? '_blank' : ''"
                ><span class="valign-middle">{{ item.teaserTitle }}</span>
                <span *ngIf="item.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
                ><fcom-icon *ngIf="item.external" class="icon-small" [name]="SvgLibraryIcon.EXTERNAL_TAB"></fcom-icon
              ></a>
              <span *ngIf="!item.link" class="navi-category-title">{{ item.teaserTitle }}</span>
            </div>
            <ng-container *ngFor="let child of item.items; trackBy: trackItems">
              <li
                *ngIf="!child.hideInMenu"
                role="listitem"
                class="border-bottom menu-item"
                [class.is-hidden]="(expandedNaviCat$ | async) && (expandedNaviCat$ | async) !== child"
              >
                <div
                  class="navi-section"
                  *ngIf="child.items && child.items.length > 0 && child.teaserTitle && child.hasVisibleChildren"
                >
                  <button
                    class="navi-section-expand inherit-color no-decoration focus-inset fill align-left flex flex--center ms-xsmall-y"
                    (click)="setCategoryAsExpanded($event, child)"
                    [attr.tabindex]="(expandedNaviCat$ | async) ? '-1' : '0'"
                  >
                    <div class="flex-1">
                      <div class="flex flex--center nordic-blue-900-text transform-none">
                        <span class="flex-1 flex flex--middle"
                          >{{ child.teaserTitle }}
                          <fcom-icon
                            *ngIf="child.loginRequired"
                            class="icon-small ms-xxsmall-l"
                            [name]="SvgLibraryIcon.LOCK_OPEN"
                          ></fcom-icon
                        ></span>
                      </div>
                    </div>
                    <div>
                      <fcom-icon
                        class="flex-1 flex--nogrow rotate-right nordic-blue-900-text font-body-2 inline-block icon-medium"
                        [name]="SvgLibraryIcon.CHEVRON_DOWN"
                      ></fcom-icon>
                    </div>
                  </button>
                  <div
                    class="slide-container white-bg ps-medium-t z1"
                    [class.navi-section-display]="(expandedNaviCat$ | async) === child"
                  >
                    <div class="section-canvas ps-medium-x">
                      <a class="navi-section-close nordic-blue-900-text bold" href="#" (click)="closeNaviMenu()">
                        <fcom-icon
                          class="rotate-left inline-block nordic-blue-900-text font-body-2 icon-medium"
                          [name]="SvgLibraryIcon.CHEVRON_DOWN"
                        ></fcom-icon>
                        <span class="ps-xsmall-l">{{ 'actions.back' | finLocalization }}</span>
                      </a>
                      <div class="ps-medium-y font-body-1 bold">
                        <a
                          class="navi-section-main-link nordic-blue-900-text relative"
                          (click)="closeMenu()"
                          [class.current-item]="child.active"
                          [attr.aria-current]="child.active ? 'page' : null"
                          [finLink]="child.link"
                          [external]="child.external"
                          [finElemTracker]="'level-2: ' + child.link"
                          [finElemContext]="'top-navigation'"
                          [finElemType]="ElementTypes.LINK"
                          [attr.target]="child.openInNewTab ? '_blank' : ''"
                        >
                          <span class="valign-middle">{{ child.teaserTitle }}</span>
                          <span *ngIf="child.active" class="is-vishidden">{{
                            'navigation.currentPage' | finLocalization
                          }}</span
                          ><fcom-icon
                            *ngIf="child.external"
                            class="icon-small"
                            [name]="SvgLibraryIcon.EXTERNAL_TAB"
                          ></fcom-icon>
                          <fcom-icon
                            *ngIf="child.loginRequired"
                            class="icon-small"
                            [name]="SvgLibraryIcon.LOCK_OPEN"
                          ></fcom-icon>
                        </a>
                      </div>
                      <ul role="list" class="flex--nogrow no-list-style">
                        <ng-container *ngFor="let subchild of child.items; trackBy: trackItems">
                          <li *ngIf="subchild.link && !subchild.hideInMenu" role="listitem" class="border-bottom">
                            <a
                              class="navi-section-item-link nordic-blue-900-text block ms-xsmall-y relative"
                              [class.current-item]="subchild.active"
                              [attr.aria-current]="subchild.active ? 'page' : null"
                              (click)="closeMenu()"
                              [finLink]="subchild.link"
                              [finElemTracker]="'level-3: ' + subchild.link"
                              [finElemContext]="'top-navigation'"
                              [finElemType]="ElementTypes.LINK"
                              [external]="subchild.external"
                              [attr.target]="subchild.openInNewTab ? '_blank' : ''"
                            >
                              <span class="valign-middle">{{ subchild.teaserTitle }}</span>
                              <span *ngIf="subchild.active" class="is-vishidden">{{
                                'navigation.currentPage' | finLocalization
                              }}</span
                              ><fcom-icon
                                *ngIf="subchild.external"
                                class="icon-small"
                                [name]="SvgLibraryIcon.EXTERNAL_TAB"
                              ></fcom-icon>
                              <fcom-icon
                                *ngIf="subchild.loginRequired"
                                class="icon-small"
                                [name]="SvgLibraryIcon.LOCK_OPEN"
                              ></fcom-icon>
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>

                <a
                  class="navi-section-flat-link nordic-blue-900-text block ms-xsmall-y relative"
                  (click)="closeMenu()"
                  *ngIf="
                    child.link &&
                    (!child.items || child.items.length < 1 || (child.items && !child.hasVisibleChildren)) &&
                    child.teaserTitle &&
                    !child.hideInMenu
                  "
                  [class.current-item]="child.active"
                  [attr.aria-current]="child.active ? 'page' : null"
                  [finLink]="child.link"
                  [finElemTracker]="'level-1: ' + child.teaserTitle"
                  [finElemContext]="'top-navigation'"
                  [finElemType]="ElementTypes.LINK"
                  [external]="child.external"
                  [attr.tabindex]="(expandedNaviCat$ | async) ? '-1' : '0'"
                  [attr.target]="child.openInNewTab ? '_blank' : ''"
                  ><span class="valign-middle">{{ child.teaserTitle }}</span>
                  <span *ngIf="child.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
                  ><fcom-icon *ngIf="child.external" class="icon-small" [name]="SvgLibraryIcon.EXTERNAL_TAB"></fcom-icon
                ></a>
              </li>
            </ng-container>
          </ul>
        </li>

        <div
          class="nav-footer nordic-blue-50-bg flex--nogrow relative mr-large-t"
          [class.is-hidden]="expandedNaviCat$ | async"
        >
          <div class="nav-footer-wrapper center grid-max-width ps-medium-x">
            <div class="nav-footer-content ps-medium-y flex flex--wrap font-body-2 flex--space-between">
              <div
                *ngFor="let item of (menuItems$ | async)?.enterprise"
                class="flex--basis0 nowrap ps-xsmall-y nav-footer-cell enterprise-link"
              >
                <span>
                  <a
                    class="no-underline nordic-blue-900-text inline-block fill grow"
                    [finLink]="item.link"
                    [external]="item.external"
                    [attr.target]="item.openInNewTab ? '_blank' : ''"
                    [finElemTracker]="'top-header: ' + item.teaserTitle"
                    [finElemContext]="'top-navigation'"
                    [finElemType]="ElementTypes.LINK"
                    >{{ item.teaserTitle }}</a
                  ></span
                >
              </div>
              <div class="language-link-block ps-xsmall-y flex--basis0 nowrap nav-footer-cell">
                <a
                  class="no-underline nordic-blue-900-text"
                  [finLink]="_selectLanguageRoot"
                  [finElemTracker]="'top-header: language'"
                  [finElemContext]="'top-navigation'"
                  [finElemType]="ElementTypes.LINK"
                >
                  <span
                    class="flag-{{ countries$ | finMap: 'code' | async | lowercase }} valign-top ms-xsmall-r"
                  ></span>
                  <span [innerHTML]="countries$ | finMap: 'name' | async | finSafe: 'html'"></span>
                  <span>- {{ currentLanguage$ | async }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </nav>
    <ng-container *ngIf="enableNewLanguageSelector$ | async">
      <div
        class="language-selector animation-wrapper absolute z3 white-bg"
        [class.open]="languageSelectorOpen"
        data-testid="language-selector"
      >
        <div class="ps-small-x ps-small-t">
          <fin-country-and-language-selector
            (applySelection)="selectLanguageAndRedirect($event)"
            (closeClick)="closeLanguageSelector()"
          ></fin-country-and-language-selector>
        </div>
      </div>
    </ng-container>
  </div>
</div>
