import { PreloadingStrategy, Route } from '@angular/router';
import { OnDestroy, Injectable } from '@angular/core';

import { filter, mergeMap, take } from 'rxjs/operators';
import { of, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppPreloadingStrategyService implements PreloadingStrategy, OnDestroy {
  private preloadRequests = new Subject<string>();

  preload(route: Route, loadModuleFn: Function): Observable<any> {
    if (route.data?.preload) {
      return this.preloadRequests.pipe(
        filter((path) => path === route.path),
        take(1),
        mergeMap(() => loadModuleFn())
      );
    }
    return of(null);
  }

  triggerPreload(routePath: string): void {
    this.preloadRequests.next(routePath);
  }

  ngOnDestroy(): void {
    this.preloadRequests.complete();
  }
}
